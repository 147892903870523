import jwt_decode from 'jwt-decode'

export const decodeToken = (token) => {
  try {
    const decoded = jwt_decode(token)
    return decoded
  } catch (e) {
    console.log('e ', e)
    return undefined
  }
}

export const isVerified = (token) => {
  let decoded = decodeToken(token)
  return decoded?.verified === 1
}
