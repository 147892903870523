import { capitalizeText } from '../../../helpers'

export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },
  {
    text: 'Name',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Type',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Language',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Status',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Actions',
    alignment: 'right',
    size: 'small',
  },
]

export const filters = [
  {
    text: 'Any field',
    value: 'any',
  },
  {
    text: 'First name',
    value: 'firstname',
  },
  {
    text: 'Surname',
    value: 'surname',
  },
  {
    text: 'Email address',
    value: 'email',
  },
]

export const useSurveys = (data) => {
  const transformedData = data.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.name,
        alignment: 'left',
        size: 'large',
      },
      {
        text: `${item.surveyType.intervalTime} ${capitalizeText(
          item.surveyType.interviewUnit
        )}`,
        alignment: 'left',
        size: 'large',
      },
      {
        text: `${item.language.name}`,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.status,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    surveys: transformedData,
    surveysRaw: data,
    headers,
    filters,
  }
}
