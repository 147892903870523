import { Route, Redirect } from 'react-router-dom'
import { LS_KEYS, ROUTES } from '../constants'
import { getItem } from '../services/session'

const PublicRoute = ({ children, ...rest }) => {
  const token = getItem(LS_KEYS.AUTH_TOKEN)
  return <Route {...rest}>{token ? <Redirect to={ROUTES.HOME} /> : children}</Route>
}

export { PublicRoute }
