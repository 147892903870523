export const ROUTES = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  PATIENTS: '/patients',
  CREATE_PATIENTS: '/patients/create',
  IMPORT_PATIENTS: '/patients/import',
  EDIT_PATIENT: '/patients/edit/:id',
  INTERVIEWERS: '/interviewers',
  CREATE_INTERVIEWERS: '/interviewers/create',
  EDIT_INTERVIEWERS: '/interviewers/edit/:id',
  ATTACH_HOSPITALS: '/attach/hospitals/:id',
  HOSPITALS: '/hospitals',
  CREATE_HOSPITAL: '/hospitals/create',
  EDIT_HOSPITAL: '/hospitals/edit/:id',
  CHANGE_PASSWORD: '/change/password',
  FORGOT_PASSWORD: '/forgot/password',
  RESET_PASSWORD: '/reset-password/:id/:code',
  SETTINGS: '/settings',
  SURVEYS: '/surveys',
  CREATE_SURVEYS: '/surveys/create',
  EXPORT_SURVEYS: '/surveys/export',
  EDIT_SURVEYS: '/surveys/edit/:id',
  PATIENT_SURVEYS: '/patient/surveys/:id',
  REPORTS: '/reports',
}
