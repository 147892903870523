export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },
  {
    text: 'Consent',
    alignment: 'left',
    size: 'extra-large',
  },
  {
    text: 'Count',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Percentage',
    alignment: 'left',
    size: 'large',
  },
]

export const useList = (data) => {
  const transformedData = data.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.consent,
        alignment: 'left',
        size: 'extra-large',
      },
      {
        text: item.count.toString(),
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.percentage,
        alignment: 'left',
        size: 'large',
      },
    ]
  })
  return {
    list: transformedData,
    headers,
  }
}
