import { useState } from 'react'
import moment from 'moment'
import axios from 'axios'


import { LS_KEYS } from '../../../constants'
import { getItem } from '../../../services/session'
import { FORM_DATA, YES_AND_NO_LIST } from './hook'

import {
  Banner,
  Button,
  Heading,
  Layout,
  TextField,
  Dropdown,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const fileDownload = require('js-file-download')
const DATE_FORMAT = 'YYYY-MM-DD'
const BASE_URL = process.env.REACT_APP_BASE_URL

const Export = () => {
  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    let newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${form[name].label} is required` : '',
          error: isRequired,
        },
        required: form[name].required,
      },
    }

    setForm(newForm)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async function fetchWithRetry(filename, retryCount = 500) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/interviews/fetch-export/${filename}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 404 && retryCount > 0) {
        await sleep(3000); // wait for 3 seconds
        return fetchWithRetry(filename, retryCount - 1);
      }
      throw error;
    }
  }

  const onSubmit = async () => {
    setBanner(undefined)
    let canProceed = true
    Object.entries(form)
      .reverse()
      .forEach((entry) => {
        const name = entry[0]
        const value = entry[1].value
        const required = entry[1].required
        if (!value && required) {
          onChange(name, value)
          canProceed = false
        }
      })

    if (canProceed) {
      const dateNow = new Date()
      const fileName = `interviews_${moment(dateNow).format('YYYY_MM_DD')}.csv`
      const body = {
        startDate: moment(form['from'].value).format(DATE_FORMAT),
        endDate: moment(form['to'].value).format(DATE_FORMAT),
        completed: Number(form['isCompleted'].value),
      }
      setIsLoading(true)
      await axios({
        method: 'GET',
        url: `${BASE_URL}/interviews/export?startDate=${body.startDate}&endDate=${body.endDate}&completed=${body.completed}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      })
        .then(async (response) => {
          //

          if (response.data.success) {
            let filename = response.data.data.filename
            console.log('filename ' + filename);

            try {
              let response = await fetchWithRetry(filename, 500);
              fileDownload(response.data, fileName)
            } catch (error) {
              console.log('Failed to fetch data after retries:', error);
            }


            setBanner({
              text: 'File successfully downloaded',
              type: 'success',
            })
          }

        })
        .catch((err) => {
          setBanner({
            text: 'An error has occurred, please try again',
            type: 'error',
          })
        })
        .finally(() => {
          //setIsLoading(false)
        })
    }
  }

  const canSubmit = form['from'].value && form['to'].value

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <Heading text="Export survey" noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        {banner?.type !== 'success' && (
          <div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...form.from} onChange={onChange} />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...form.to} onChange={onChange} />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...form.isCompleted}
                list={YES_AND_NO_LIST.map((hos) => hos.value)}
                onSelect={onChange}
                value={form['isCompleted'].value}
                displayList={YES_AND_NO_LIST}
                name="isCompleted"
                withBorder
              />
            </div>
            <br />
            <Button
              text="Export"
              onClick={onSubmit}
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export { Export }
