export const FORM_DATA = {
  from: {
    label: 'From',
    name: 'from',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  to: {
    label: 'To',
    name: 'to',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  isCompleted: {
    label: 'Type of survey',
    name: 'isCompleted',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const useModals = (data) => {
  const items = data.map((item) => {
    return {
      text: item.name,
      value: item.id,
    }
  })
  return {
    items,
  }
}

export const STATUSES = [
  {
    text: 'Active',
    value: 'ACTIVE',
  },
  {
    text: 'Inactive',
    value: 'INACTIVE',
  },
]

export const YES_AND_NO_LIST = [
  {
    text: 'Only completed',
    value: '1',
  },
  {
    text: 'Completed and partial',
    value: '0',
  },
]
