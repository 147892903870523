import React from 'react'
import PropTypes from 'prop-types'

import { Button, Typography } from '../../../../components'

import styles from './Section.module.scss'
import cx from 'classnames'

const Section = ({ children, title, onButtonClick, buttonText, noBorder }) => {
  const sectionProps = cx(styles.section, {
    [styles['section--no-border']]: noBorder,
  })
  return (
    <div className={sectionProps}>
      <div className={styles['section--title-wrapper']}>
        <Typography className={styles['section--title']}>{title}</Typography>
      </div>
      <div className={styles['section--content--wrapper']}>
        <div className={styles['section--content--header']}>
          <Button
            text={buttonText}
            size="small"
            onClick={onButtonClick}
            className={styles['section--content--header--button']}
            width={180}
          />
        </div>
        <div className={styles['section--content--body']}>{children}</div>
      </div>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  noBorder: PropTypes.bool,
}

Section.defaultProps = {}

export { Section }
