import React from 'react'
import { useGetPatientEnrollmentQuery } from '../../../services/redux/features/dashboard/facade'
import { intToString } from '../../../helpers'
import { useList } from './hook'

import {
  Typography,
  Table,
  TableHeader,
  TableRow,
  Skeletons,
} from '../../../components'

import styles from './../Home.module.scss'

// const SURVEY_TYPES = [
//   {
//     text: 'TYPE 1',
//     value: '1',
//   },
//   {
//     text: 'TYPE 2',
//     value: '2',
//   },
// ]

// const FILTERS = [
//   {
//     text: 'YEAR',
//     value: 'yearly',
//   },
//   {
//     text: 'MONTH',
//     value: 'monthly',
//   },
//   {
//     text: 'WEEK',
//     value: 'weekly',
//   },
//   {
//     text: 'DAY',
//     value: 'daily',
//   },
//   {
//     text: 'CUSTOM DATES',
//     value: 'custom',
//   },
// ]

const PatientEnrollment = () => {
  // const [timeRange, setTimeRange] = useState()
  // const [selectedRange, setSelectedRange] = useState()

  const { isSuccess, data, isLoading } = useGetPatientEnrollmentQuery()

  const { list, headers } = useList(data?.data?.patientEnrollment || [])

  // const handleTimeRanage = (time) => {
  //   setSelectedRange(time)
  //   if (time !== 'custom') {
  //     let startDateTime
  //     const currentDateTime = new Date()
  //     const endDateTime = new Date(currentDateTime.setHours(23, 59, 59))

  //     if (time === 'daily') {
  //       startDateTime = new Date(currentDateTime.setHours(0, 0, 0))
  //     } else if (time === 'weekly') {
  //       const currentDay = new Date(currentDateTime.getTime())
  //       const oneWeekAgo = currentDay.setDate(currentDateTime.getDate() - 7)
  //       const oneWeekAgoDateTime = new Date(oneWeekAgo)
  //       startDateTime = new Date(oneWeekAgoDateTime.setHours(0, 0, 0))
  //     } else if (time === 'monthly') {
  //       const currentDay = new Date(currentDateTime.getTime())
  //       const oneMonthAgo = currentDay.setDate(currentDateTime.getDate() - 30)
  //       const oneMonthAgoDateTime = new Date(oneMonthAgo)
  //       startDateTime = new Date(oneMonthAgoDateTime.setHours(0, 0, 0))
  //     } else if (time === 'yearly') {
  //       const currentDay = new Date(currentDateTime.getTime())
  //       const oneYearAgo = currentDay.setDate(currentDateTime.getDate() - 365)
  //       const oneYearAgoDateTime = new Date(oneYearAgo)
  //       startDateTime = new Date(oneYearAgoDateTime.setHours(0, 0, 0))
  //     }

  //     setTimeRange({
  //       from: startDateTime,
  //       to: endDateTime,
  //     })
  //   }
  // }

  return (
    <div className={styles['table-wrapper']}>
      <Typography
        children="Patient Enrollment"
        className={styles['table-wrapper--title']}
      />
      <div className={styles['table-wrapper--row']}>
        <div className={styles['table-wrapper--card']}>
          <Typography
            children="Number of Patients"
            className={styles['table-wrapper--card--title']}
            uppercase
          />
          {isLoading && (
            <Typography
              children="0"
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          )}
          {isSuccess && (
            <Typography
              children={intToString(data?.data?.totalPatients)}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          )}
        </div>
        <div className={styles['table-wrapper--table']}>
          <Table size="small">
            <TableHeader data={headers} />
            {isLoading && <Skeletons type="table" />}
            {isSuccess && list && !isLoading && (
              <>
                {list.map((hos, index) => (
                  <TableRow data={hos} key={index} />
                ))}
              </>
            )}
          </Table>
        </div>
      </div>
      {/* <div className={styles['table-wrapper--header']}>
        <Filters data={FILTERS} onClick={(item) => handleTimeRanage(item)} />
        <div className={styles['table-wrapper--header--dropdown-wrapper']}>
          <Dropdown
            list={SURVEY_TYPES.map((type) => type.value)}
            onSelect={() => {}}
            displayList={SURVEY_TYPES}
            name="surveyType"
            withBorder
            placeholder="Survey type"
          />
        </div>
      </div>
      {selectedRange === 'custom' && (
        <div className={styles['table-wrapper--header']}>
          <div className={styles['table-wrapper--header--inner']}>
            <div className={styles['table-wrapper--header--input-wrapper']}>
              <TextField type="date" label="From" />
            </div>
            <div className={styles['table-wrapper--header--input-wrapper']}>
              <TextField type="date" label="To" />
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

export { PatientEnrollment }
