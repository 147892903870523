export const attachRouterParams = (route, params) => {
  let newRoute = ''

  if (!params) {
    return route.replace('/:id?', '')
  }

  if (Object.entries(params).length === 1) {
    newRoute = route.replace(`:${Object.keys(params)[0]}`, Object.values(params)[0])
  } else if (Object.entries(params) > 1) {
    Object.entries(params).forEach((param) => {
      newRoute = route.replace(`:${param.key}`, params.value)
    })
  }
  return newRoute
}
