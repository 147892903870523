import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Caption } from '../caption'

import styles from './Panel.module.scss'

const Panel = ({ children, noPadding, withMargin, withLightGrayBackground, title }) => {
  const panelProps = cx(styles.panel, {
    [styles['panel--with-margin']]: withMargin,
    [styles['panel--with-light-gray-background']]: withLightGrayBackground,
  })
  const panelBodyProps = cx(styles['panel--body'], {
    [styles['panel--body--no-padding']]: noPadding,
  })
  return (
    <div className={panelProps}>
      {title && (
        <div className={styles['panel--title']}>
          <Caption text={title} size="medium" noMargin />
        </div>
      )}
      <div className={panelBodyProps}>{children}</div>
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  withMargin: PropTypes.bool,
  withLightGrayBackground: PropTypes.bool,
  title: PropTypes.string,
}

Panel.defaultProps = {
  noPadding: false,
}

export { Panel }
