import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const surveysApi = createApi({
  reducerPath: 'SURVEYS_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getSurveysTypes: builder.query({
      query: () => ({
        url: '/surveys/type',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['SurveysType'],
    }),
    createSurveysType: builder.mutation({
      query: (body) => ({
        url: '/surveys/type',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['SurveysType'],
    }),
    editSurveysType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/surveys/type/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['SurveysType'],
    }),
    deleteSurveysType: builder.mutation({
      query: (id) => ({
        url: `/surveys/type/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['SurveysType'],
    }),
    getSurveys: builder.query({
      query: (page = 1) => ({
        url: `/surveys?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Surveys'],
    }),
    getSurvey: builder.query({
      query: (id) => ({
        url: `/surveys/${id}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    createSurvey: builder.mutation({
      query: (body) => ({
        url: '/surveys',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
    editSurvey: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/surveys/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
    deleteSurvey: builder.mutation({
      query: (id) => ({
        url: `/surveys/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
    exportSurvey: builder.mutation({
      query: ({ startDate, endDate, completed }) => ({
        url: `/interviews/export?startDate=${startDate}&endDate=${endDate}&completed=${completed}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['SurveysType'],
    }),
    resetSurvey: builder.mutation({
      query: (id) => ({
        url: `/interviews/reset-single-survey/${id}`,
        method: 'POST',
        body: {},
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
  }),
})

export const {
  useGetSurveysTypesQuery,
  useCreateSurveysTypeMutation,
  useEditSurveysTypeMutation,
  useDeleteSurveysTypeMutation,
  useGetSurveysQuery,
  useGetSurveyQuery,
  useCreateSurveyMutation,
  useEditSurveyMutation,
  useDeleteSurveyMutation,
  useExportSurveyMutation,
  useResetSurveyMutation,
} = surveysApi
