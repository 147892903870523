import React from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'

import { Typography } from '../typography'
import styles from './Tag.module.scss'

const Tag = ({ text, onDelete }) => {
  return (
    <div className={styles.tag}>
      <Typography className={styles['tag--text']}>{text}</Typography>
      <div className={styles['tag--close-btn']} onClick={onDelete}>
        <X size={12} />
      </div>
    </div>
  )
}

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
}

export { Tag }
