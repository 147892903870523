import { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useGetPatientCallAttemptsConsentMutation } from '../../../services/redux/features/dashboard/facade'
import { intToString } from '../../../helpers'

import {
  Typography,
  TableRow,
  Skeletons,
  Dropdown,
  TextField,
  Table,
  TableHeader,
} from '../../../components'

import styles from './../Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const PatientCallAttempts = ({ languages }) => {
  const selectedLanguage = languages?.[0]?.value
  const [language, setLanguage] = useState(selectedLanguage)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)

  const [getPatientCallAttempts, result] =
    useGetPatientCallAttemptsConsentMutation()

  const { data, isLoading, isFetching, isSuccess } = result

  useEffect(() => {
    if (languages.length) {
      const today = new Date(Date.now())
      setEndDate(moment(today).format(DATE_FORMAT))

      const oneWeek = today.setDate(today.getDate() - 7)
      setStartDate(moment(new Date(oneWeek)).format(DATE_FORMAT))
    }
  }, [languages])

  const loadData = async () => {
    const languageId =
      (await languages.find(
        (lang) => lang.text.toLowerCase() === language.toLowerCase()
      )?.value) ?? language

    if (languageId && startDate && endDate) {
      getPatientCallAttempts({
        languageId,
        startDate,
        endDate,
      })
    }
  }

  useEffect(() => {
    if (startDate || endDate || language) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, language])

  const headers = [
    {
      text: '',
      alignment: 'left',
      size: 'extra-small',
    },
    {
      text: 'Date & Time',
      alignment: 'left',
      size: 'extra-large',
    },
    {
      text: 'Duration',
      alignment: 'left',
      size: 'large',
    },
    {
      text: 'Caller Type',
      alignment: 'left',
      size: 'large',
    },
    {
      text: 'Status',
      alignment: 'left',
      size: 'large',
    },
  ]

  const patientCallAttempts = data?.data?.callAttempts?.patientCallAttempts

  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-wrapper--row']}>
        <Typography
          children="Patient call attempts"
          className={styles['table-wrapper--title']}
        />
        <div className={styles['dashboard--row']}>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setStartDate(text)}
              placeholder="Start Date"
              type="date"
              required
              value={startDate}
              label="Start date"
            />
          </div>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setEndDate(text)}
              placeholder="End Date"
              type="date"
              required
              value={endDate}
              label="End date"
            />
          </div>
          <div className={styles['table-wrapper--header--dropdown-wrapper']}>
            <Dropdown
              list={languages.map((lang) => lang.value)}
              onSelect={(_, text) => {
                setLanguage(text)
              }}
              displayList={languages}
              name="language"
              withBorder
              placeholder="Language"
              value={language}
              label="Language"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          {patientCallAttempts?.length > 0 && (
            <div className={styles['table-wrapper--card']}>
              <Typography
                children="Number of contacted patients"
                className={styles['table-wrapper--card--title']}
                uppercase
              />
              <Typography
                children={intToString(patientCallAttempts?.length || [])}
                className={styles['table-wrapper--card--text']}
                uppercase
              />
            </div>
          )}
          <div className={styles['table-wrapper--table']}>
            <Table size="small">
              {isLoading && <Skeletons type="table" />}
              {patientCallAttempts.map((item, index) => {
                return (
                  <div key={index}>
                    <Typography
                      children={`Patient's name: ${item.patientName}`}
                      className={styles['table-wrapper--subtitle']}
                    />
                    {item?.interviews?.map((interviewItem, interviewIndex) => {
                      const transformedData = interviewItem?.callAttempts?.map(
                        (item) => {
                          return [
                            {
                              text: '',
                              alignment: 'left',
                              size: 'extra-small',
                            },
                            {
                              text: item.callDataTime,
                              alignment: 'left',
                              size: 'extra-large',
                            },
                            {
                              text: item.callDuration,
                              alignment: 'left',
                              size: 'large',
                            },
                            {
                              text: item.callerType,
                              alignment: 'left',
                              size: 'large',
                            },
                            {
                              text: item.status,
                              alignment: 'left',
                              size: 'large',
                            },
                          ]
                        }
                      )

                      return (
                        <div key={interviewIndex}>
                          <Typography
                            children={`${interviewItem.name} Call Attempts`}
                            className={styles['table-wrapper--subtitle--small']}
                          />
                          <br />
                          <Table size="small">
                            <TableHeader data={headers} />
                            {transformedData.map((item, innerIndex) => (
                              <TableRow data={item} key={innerIndex} />
                            ))}
                          </Table>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Table>
          </div>
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Number of contacted patients"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children="0"
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Skeletons type="table" />
            <Skeletons type="table" />
          </div>
        </div>
      )}
    </div>
  )
}

PatientCallAttempts.defaultProps = {
  languages: [],
}

PatientCallAttempts.propTypes = {
  languages: PropTypes.array,
}

export { PatientCallAttempts }
