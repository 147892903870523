import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { capitalizeText } from '../../../helpers'
import { ACTIONS } from '../../../constants'
import { useSurveys } from './hook'
import {
  useGetSurveysQuery,
  useDeleteSurveyMutation,
} from '../../../services/redux/features/survey/facade'
import { ROUTES } from '../../../constants'

import {
  Button,
  Heading,
  Layout,
  Pagination,
  Table,
  TableHeader,
  TableRow,
  Skeletons,
  Modal,
  Delete,
  Details,
  SuperUserCheck,
} from '../../../components'

import styles from '../../home/Home.module.scss'
import { attachRouterParams } from '../../../helpers'

const List = () => {
  const [banner, setBanner] = useState()
  const [selectedSurvey, setSelectedSurvey] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDetails, setShowDetails] = useState(false)
  const [surveyDetails, setSurveyDetails] = useState(undefined)

  const [deleteSurvey, result] = useDeleteSurveyMutation()
  const { isSuccess, data, isLoading, isFetching } =
    useGetSurveysQuery(selectedPage)

  const history = useHistory()

  useEffect(() => {
    if (result.error) {
      setBanner({
        text: JSON.stringify(result.error?.data.error),
        type: 'error',
      })
    }

    if (result.isSuccess) {
      setBanner({
        text: 'The survey has been successfully deleted',
        type: 'success',
      })
    }
  }, [result])

  const { surveys, surveysRaw, headers } = useSurveys(data?.data?.surveys || [])
  const ids = surveysRaw.map((survey) => survey.id) || []

  const onStartDeletion = async (item) => {
    setSelectedSurvey(item)
  }

  const onEditSurvey = async (id) => {
    history.push(attachRouterParams(ROUTES.EDIT_SURVEYS, { id }))
  }

  const onCancelDeletion = () => {
    setSelectedSurvey(undefined)
    setBanner(undefined)
  }

  const onDeleteUser = async () => {
    if (selectedSurvey) {
      await deleteSurvey(selectedSurvey.id)
    }
  }

  const onViewMore = async (item) => {
    let details = [
      {
        title: 'extended survey details',
        data: [
          {
            text: item?.name,
            title: 'Name',
          },
          {
            text: `${item.surveyType.intervalTime} ${capitalizeText(
              item.surveyType.interviewUnit
            )}`,
            title: 'Type',
          },
          {
            text: item.status,
            title: 'Status',
          },
          {
            text: item.is_default ? 'Yes' : 'No',
            title: 'This is the default survey',
          },
          {
            text: JSON.stringify(item.data, null, 4),
            title: 'Data',
            isHtmlCode: true,
          },
        ],
      },
    ]

    setSurveyDetails(details)
  }

  const loading = isLoading || isFetching

  return (
    <Layout>
      <Heading text="Survey management" />
      <div
        className={cx(
          styles['dashboard--row'],
          styles['dashboard--row--with-margin']
        )}
      >
        <div />
        <div
          className={cx(
            styles['dashboard--row'],
            styles['dashboard--row--align-right']
          )}
        >
          <div className={styles['dashboard--button-wrapper']}>
            <Button
              text="New survey"
              size="small"
              onClick={() => {
                history.push(ROUTES.CREATE_SURVEYS)
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['dashboard--row']}>
        <Table size="small">
          <TableHeader data={headers} />
          {loading && <Skeletons type="table" />}
          {isSuccess && surveys && !loading && (
            <>
              {surveys.map((data, index) => {
                const item = surveysRaw.find(
                  (survey) => survey.id === ids[index]
                )
                return (
                  <TableRow
                    data={data}
                    key={index}
                    onAction={(action) => {
                      if (action === ACTIONS.DELETE) {
                        onStartDeletion(item)
                      } else if (action === ACTIONS.EDIT) {
                        onEditSurvey(item.id)
                      } else {
                        setShowDetails(true)
                        onViewMore(item)
                      }
                    }}
                    actions={
                      item.is_default
                        ? [ACTIONS.VIEW, ACTIONS.EDIT]
                        : [ACTIONS.VIEW, ACTIONS.EDIT, ACTIONS.DELETE]
                    }
                  />
                )
              })}
            </>
          )}
        </Table>
      </div>
      <br />
      {isSuccess &&
        !loading &&
        data?.data?.pagination &&
        data?.data?.surveys.length > 0 && (
          <Pagination
            onClick={(page) => setSelectedPage(page)}
            currentPage={data?.data?.pagination?.currentPage}
            pages={data?.data?.pagination?.totalRecords}
          />
        )}
      {selectedSurvey && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeleteUser}
            title="Delete the survey"
            onCancel={onCancelDeletion}
            banner={banner}
            loading={result.isLoading}
          />
        </Modal>
      )}
      {showDetails && (
        <Modal onClose={() => setShowDetails(false)} closeOnClickOutside>
          <Details
            title="More details on the survey"
            onCancel={() => setShowDetails(false)}
            list={surveyDetails}
          />
        </Modal>
      )}
      <SuperUserCheck />
    </Layout>
  )
}

export { List }
