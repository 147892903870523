import React, { useEffect } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { canOnlySeePatients, userHasFullAcess } from '../helpers/auth'
import { LS_KEYS, ROUTES } from '../constants'
import { getItem, clearSession } from '../services/session'
import { decodeToken, getTimeNow } from '../helpers'

const PATIENTS_CAPTURE_ROUTES = [
  ROUTES.HOME,
  ROUTES.PATIENTS,
  ROUTES.CREATE_PATIENTS,
  ROUTES.EDIT_PATIENT,
  ROUTES.IMPORT_PATIENTS,
  ROUTES.PATIENT_SURVEYS,
]

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory()
  const token = getItem(LS_KEYS.AUTH_TOKEN)
  const user = getItem(LS_KEYS.AUTH_USER)

  useEffect(() => {
    if (token) {
      const { exp } = decodeToken(token)
      const now = getTimeNow()

      if (exp < now) {
        clearSession()
        history.push(ROUTES.SIGN_IN)
      }

      const { location } = history
      if (
        canOnlySeePatients() &&
        !PATIENTS_CAPTURE_ROUTES.includes(location.pathname)
      ) {
        history.push(ROUTES.HOME)
      }

      if (
        !(userHasFullAcess() || user?.isSuperUser) &&
        ROUTES.EXPORT_SURVEYS.includes(location.pathname)
      ) {
        history.push(ROUTES.HOME)
      }
    }
  }, [token, history, user])

  return (
    <Route {...rest}>
      {!token ? <Redirect to={ROUTES.SIGN_IN} /> : children}
    </Route>
  )
}

export { PrivateRoute }
