import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { scrollToTop } from '../../../helpers'
import {
  useEditPatientMutation,
  useGetPatientQuery,
  useGetAssistantsQuery,
} from '../../../services/redux/features/patient/facade'
import { useGetHospitalsQuery } from '../../../services/redux/features/hospital/facade'
import { useGetLanguagesQuery } from '../../../services/redux/features/language/facade'
import { ROUTES } from '../../../constants'
import {
  PATIENT_FORM_DATA,
  useHospitals,
  REGIONS,
  YES_AND_NO_LIST,
  useLanguages,
} from './../helper'
import {
  BackButton,
  Banner,
  Heading,
  Layout,
  Dropdown,
  Button,
  TextField,
  Typography,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const Update = ({ id }) => {
  const { t } = useTranslation('patients')
  const history = useHistory()
  const formRef = useRef()

  const [form, setForm] = useState(PATIENT_FORM_DATA)
  const [banner, setBanner] = useState()
  const [ctrIdPrefix, setCtrIdPrefix] = useState('')
  const [formHasErrors, setFormHasErrors] = useState(false)
  const [assistants, setAssistants] = useState([])

  const hospitalsRequest = useGetHospitalsQuery()
  const languagesRequest = useGetLanguagesQuery()
  const getAssistantsRequest = useGetAssistantsQuery()

  const { hospitals } = useHospitals(
    hospitalsRequest.data?.data?.hospitals || []
  )
  const { languages } = useLanguages(
    languagesRequest.data?.data?.languages || []
  )

  const patientRequest = useGetPatientQuery(id, {
    skip: false,
  })
  const [editPatient, result] = useEditPatientMutation()
  const { isLoading, error, isSuccess } = result

  const onChange = (name, value) => {
    let isRequired = !value && form[name].required
    let errorMessage = `${form[name].label} is required`

    if (name === 'hospital') {
      const hosp = hospitals.find((h) => h.value === value)
      if (hosp) {
        setCtrIdPrefix(hosp.code)
      }
    }

    if (name === 'ctrId') {
      isRequired = value.length !== 6
      errorMessage = value.length !== 6 && t('ctr-error-message')
    }

    if (name === 'contactNumber') {
      isRequired = form['participantHasMobileNumber'].value === '1' && !value
    }

    if (
      [
        'alternativeName',
        'alternativeContactNumber',
        'alternativeRelationship',
        'alternativePermission',
      ].includes(name)
    ) {
      isRequired =
        form['participantHasAlternateMobilePhoneNumber'].value === '1' && !value
    }

    if (
      [
        'alternativeName2',
        'alternativeContactNumber2',
        'alternativeRelationship2',
        'alternativePermission2',
      ].includes(name)
    ) {
      isRequired =
        form['participantHasAlternateMobilePhoneNumber2'].value === '1' &&
        !value
    }

    if (['otherContactNumber1', 'otherContactNumber2'].includes(name)) {
      isRequired =
        form['participantHasOtherMobilePhoneNumbers'].value === '1' && !value
    }

    if (name === 'howToIdentifyResearcher') {
      isRequired = form['consentToMention'].value === '1' && !value
    }

    const newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? errorMessage : '',
          error: isRequired,
        },
        required: form[name].required,
      },
    }

    setFormHasErrors(false)
    setForm(newForm)
  }

  useEffect(() => {
    if (getAssistantsRequest.data) {
      let arr = []
      getAssistantsRequest.data.data.forEach((item) => {
        arr.push({
          text: item,
          value: item,
        })
      })

      setAssistants(arr)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssistantsRequest])

  useEffect(() => {
    if (patientRequest.data) {
      const res = patientRequest.data.data.patients?.[0]
      setCtrIdPrefix(res.patientCtrId.substring(0, 3))
      if (res) {
        const newForm = {
          hospital: {
            ...PATIENT_FORM_DATA['hospital'],
            value: res.hospital.id.toString(),
          },
          ctrId: {
            ...PATIENT_FORM_DATA['ctrId'],
            value: res.patientCtrId.substring(3, res.patientCtrId.length),
          },
          filingConsentDate: {
            ...PATIENT_FORM_DATA['filingConsentDate'],
            value: res.patientConsentDate,
          },
          dateOfDischarge: {
            ...PATIENT_FORM_DATA['dateOfDischarge'],
            value: res.dateOfDischarge,
          },
          researchAssistantName: {
            ...PATIENT_FORM_DATA['researchAssistantName'],
            value: res.nameResearchAssistant ?? '',
          },
          participantName: {
            ...PATIENT_FORM_DATA['participantName'],
            value: res.patientName,
          },
          region: {
            ...PATIENT_FORM_DATA['region'],
            value: res.primaryAddress.region,
          },
          town: {
            ...PATIENT_FORM_DATA['town'],
            value: res.primaryAddress.town,
          },
          village: {
            ...PATIENT_FORM_DATA['village'],
            value: res.primaryAddress.village,
          },
          quarter: {
            ...PATIENT_FORM_DATA['quarter'],
            value: res.primaryAddress.quarter,
          },
          nearestLandmark: {
            ...PATIENT_FORM_DATA['nearestLandmark'],
            value: res.primaryAddress.nearestLandmark,
          },
          detailedDescription: {
            ...PATIENT_FORM_DATA['detailedDescription'],
            value: res.primaryAddress.detailedDescription,
          },
          participantHasMobileNumber: {
            ...PATIENT_FORM_DATA['participantHasMobileNumber'],
            value: Boolean(res.patientMobile)
              ? '1'
              : res.haveMobileNumber.toString(),
          },
          contactNumber: {
            ...PATIENT_FORM_DATA['contactNumber'],
            value: res.patientMobile,
          },
          participantHasAlternateMobilePhoneNumber: {
            ...PATIENT_FORM_DATA['participantHasAlternateMobilePhoneNumber'],
            value: res.alternateMobile1.toString(),
          },
          alternativeName: {
            ...PATIENT_FORM_DATA['alternativeName'],
            value: res.alternativeNumbers[0]?.name,
          },
          alternativeContactNumber: {
            ...PATIENT_FORM_DATA['alternativeContactNumber'],
            value: res.alternativeNumbers[0]?.cellphone,
          },
          alternativeRelationship: {
            ...PATIENT_FORM_DATA['alternativeRelationship'],
            value: res.alternativeNumbers[0]?.relationship,
          },
          alternativePermission: {
            ...PATIENT_FORM_DATA['alternativePermission'],
            value: res.alternativeNumbers[0]?.discussOnPatientBehalf,
          },
          participantHasAlternateMobilePhoneNumber2: {
            ...PATIENT_FORM_DATA['participantHasAlternateMobilePhoneNumber2'],
            value: res.alternateMobile2.toString(),
          },
          alternativeName2: {
            ...PATIENT_FORM_DATA['alternativeName2'],
            value: res.alternativeNumbers[1]?.name,
          },
          alternativeContactNumber2: {
            ...PATIENT_FORM_DATA['alternativeContactNumber2'],
            value: res.alternativeNumbers[1]?.cellphone,
          },
          alternativeRelationship2: {
            ...PATIENT_FORM_DATA['alternativeRelationship2'],
            value: res.alternativeNumbers[1]?.relationship,
          },
          alternativePermission2: {
            ...PATIENT_FORM_DATA['alternativePermission2'],
            value: res.alternativeNumbers[1]?.discussOnPatientBehalf,
          },
          participantHasOtherMobilePhoneNumbers: {
            ...PATIENT_FORM_DATA['participantHasOtherMobilePhoneNumbers'],
            value: res.haveOtherNumbers.toString(),
          },
          otherContactNumber1: {
            ...PATIENT_FORM_DATA['otherContactNumber1'],
            value: res.otherNumbers[0]?.cellphone,
          },
          otherContactNumber2: {
            ...PATIENT_FORM_DATA['otherContactNumber2'],
            value: res.otherNumbers[1]?.cellphone,
          },
          consentToMention: {
            ...PATIENT_FORM_DATA['consentToMention'],
            value: res.consentToMention.toString(),
          },
          howToIdentifyResearcher: {
            ...PATIENT_FORM_DATA['howToIdentifyResearcher'],
            value: res.identifyResearcher,
          },
          contactAlternativesOk: {
            ...PATIENT_FORM_DATA['contactAlternativesOk'],
            value: res.contactAlternativesOk?.toString(),
          },
          preferredLanguage: {
            ...PATIENT_FORM_DATA['preferredLanguage'],
            value: res.language?.id?.toString(),
          },
        }
        setForm(newForm)
      }
    }
  }, [patientRequest.data])

  useEffect(() => {
    if (error) {
      let text = ''
      const errors = error?.data.error
      if (Array.isArray(errors)) {
        text = errors?.[0]
      } else {
        text = JSON.stringify(errors)
      }
      setBanner({
        text,
        type: 'error',
      })

      formRef?.current.scrollIntoView()
    }

    if (isSuccess) {
      setBanner({
        text: t('edit-message'),
        type: 'success',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess])

  const onSubmit = () => {
    scrollToTop()
    setBanner(undefined)
    let canProceed = true
    if (canProceed) {
      const body = {
        hospitalId: Number(form['hospital'].value),
        patientCtrId: `${ctrIdPrefix}${form['ctrId'].value}`,
        patientConsentDate: moment(form['filingConsentDate'].value).format(
          DATE_FORMAT
        ),
        dateOfDischarge: moment(form['dateOfDischarge'].value).format(
          DATE_FORMAT
        ),
        nameResearchAssistant: form['researchAssistantName'].value,
        patientName: form['participantName'].value,
        haveMobileNumber: Boolean(
          form['participantHasMobileNumber'].value === '1'
        ),
        patientMobile: form['contactNumber'].value,
        primaryAddress: {
          region: form['region'].value,
          town: form['town'].value,
          village: form['village'].value,
          quarter: form['quarter'].value,
          nearestLandmark: form['nearestLandmark'].value,
          detailedDescription: form['detailedDescription'].value,
        },
        alternateMobile1: Boolean(form['alternativeContactNumber'].value),
        alternateMobile2: Boolean(form['alternativeContactNumber2'].value),
        alternateNumbers: [
          {
            name: form['alternativeName'].value,
            relationship: form['alternativeRelationship'].value,
            discussOnPatientBehalf: form['alternativePermission'].value,
            cellphone: form['alternativeContactNumber'].value,
          },
          {
            name: form['alternativeName2'].value,
            relationship: form['alternativeRelationship2'].value,
            discussOnPatientBehalf: form['alternativePermission2'].value,
            cellphone: form['alternativeContactNumber2'].value,
          },
        ],
        haveOtherNumbers: Boolean(
          form['otherContactNumber1'].value || form['otherContactNumber2'].value
        ),
        otherNumbers: [
          ...(Boolean(form['otherContactNumber1'].value)
            ? [
                {
                  cellphone: form['otherContactNumber1'].value,
                },
              ]
            : []),
          ...(Boolean(form['otherContactNumber2'].value)
            ? [
                {
                  cellphone: form['otherContactNumber2'].value,
                },
              ]
            : []),
        ],
        howToIdentifyResearcher: form['howToIdentifyResearcher'].value,
        consentToMention: form['consentToMention'].value === '1',
        contactAlternativesOk: form['contactAlternativesOk'].value === '1',
        preferredLanguage: Number(form['preferredLanguage'].value),
        id,
      }

      editPatient(body)
    }
    setFormHasErrors(!canProceed)
  }

  return (
    <Layout>
      <div className={styles['dashboard--row']} ref={formRef}>
        <BackButton
          text={t('see-patients')}
          onClick={() => {
            history.push(ROUTES.PATIENTS)
          }}
        />
        <Heading text={t('edit-patient-details')} noMargin />
      </div>
      {banner && (
        <div
          className={cx(
            styles['dashboard--form-wrapper'],
            styles['dashboard--form-wrapper--minimum-margin']
          )}
        >
          <Banner text={banner?.text} type={banner?.type} />
        </div>
      )}
      {banner?.type !== 'success' && (
        <div
          className={cx(
            styles['dashboard--form-wrapper'],
            styles['dashboard--form-wrapper--no-margin']
          )}
        >
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.hospital}
              list={hospitals.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['hospital'].value}
              displayList={hospitals}
              name="hospital"
              withBorder
              label={t('hospital')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.ctrId}
              onChange={onChange}
              readOnly={!ctrIdPrefix}
              prefix={ctrIdPrefix}
              label={t('patient-ctrid')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.filingConsentDate}
              onChange={onChange}
              label={t('filling-consent-date')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.dateOfDischarge}
              onChange={onChange}
              label={t('date-of-discharge')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.researchAssistantName}
              list={assistants.map((assistant) => assistant.value)}
              onSelect={onChange}
              value={form['researchAssistantName'].value}
              displayList={assistants}
              name="researchAssistantName"
              withBorder
              label={t('ressearch-assistant-name')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.participantName}
              onChange={onChange}
              label={t('participant-name')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.region}
              list={REGIONS.map((region) => region.value)}
              onSelect={onChange}
              value={form['region'].value}
              displayList={REGIONS}
              name="region"
              withBorder
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField {...form.town} onChange={onChange} label={t('town')} />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField {...form.village} onChange={onChange} />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.quarter}
              onChange={onChange}
              label={t('quarter')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.nearestLandmark}
              onChange={onChange}
              label={t('nearest-landmark')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <TextField
              {...form.detailedDescription}
              onChange={onChange}
              label={t('detailed-description')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.participantHasMobileNumber}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['participantHasMobileNumber'].value}
              displayList={YES_AND_NO_LIST}
              name="participantHasMobileNumber"
              withBorder
              label={t('participant-has-mobile-number')}
            />
          </div>
          {form['participantHasMobileNumber'].value === '1' && (
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField
                {...form.contactNumber}
                onChange={onChange}
                isPhoneField
                label={t('phone-number')}
              />
            </div>
          )}
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.participantHasAlternateMobilePhoneNumber}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['participantHasAlternateMobilePhoneNumber'].value}
              displayList={YES_AND_NO_LIST}
              name="participantHasAlternateMobilePhoneNumber"
              withBorder
              label={t('participant-has-alternate-mobile-number')}
            />
          </div>
          {form['participantHasAlternateMobilePhoneNumber'].value === '1' && (
            <>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeName}
                  onChange={onChange}
                  label={t('alternative-name')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeContactNumber}
                  onChange={onChange}
                  isPhoneField
                  label={t('alternative-contact-number')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeRelationship}
                  onChange={onChange}
                  label={t('alternative-relationship')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <Dropdown
                  {...form.alternativePermission}
                  list={YES_AND_NO_LIST.map((hos) => hos.value)}
                  onSelect={onChange}
                  value={form['alternativePermission'].value}
                  displayList={YES_AND_NO_LIST}
                  name="alternativePermission"
                  withBorder
                  label={t('alternative-permission')}
                />
              </div>
            </>
          )}
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.participantHasAlternateMobilePhoneNumber2}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['participantHasAlternateMobilePhoneNumber2'].value}
              displayList={YES_AND_NO_LIST}
              name="participantHasAlternateMobilePhoneNumber2"
              withBorder
              label={t('participant-has-alternate-mobile-number-2')}
            />
          </div>
          {form['participantHasAlternateMobilePhoneNumber2'].value === '1' && (
            <>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeName2}
                  onChange={onChange}
                  label={t('alternative-name-2')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeContactNumber2}
                  onChange={onChange}
                  isPhoneField
                  label={t('alternative-contact-number-2')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.alternativeRelationship2}
                  onChange={onChange}
                  label={t('alternative-relationship-2')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <Dropdown
                  {...form.alternativePermission2}
                  list={YES_AND_NO_LIST.map((hos) => hos.value)}
                  onSelect={onChange}
                  value={form['alternativePermission2'].value}
                  displayList={YES_AND_NO_LIST}
                  name="alternativePermission2"
                  withBorder
                  label={t('alternative-permission-2')}
                />
              </div>
            </>
          )}
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.participantHasOtherMobilePhoneNumbers}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['participantHasOtherMobilePhoneNumbers'].value}
              displayList={YES_AND_NO_LIST}
              name="participantHasOtherMobilePhoneNumbers"
              withBorder
              label={t('participant-has-other-mobile-numbers')}
            />
          </div>
          {form['participantHasOtherMobilePhoneNumbers'].value === '1' && (
            <>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.otherContactNumber1}
                  onChange={onChange}
                  isPhoneField
                  label={t('other-contact-number-1')}
                />
              </div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <TextField
                  {...form.otherContactNumber2}
                  onChange={onChange}
                  isPhoneField
                  label={t('other-contact-number-2')}
                />
              </div>
            </>
          )}
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.consentToMention}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['consentToMention'].value}
              displayList={YES_AND_NO_LIST}
              name="consentToMention"
              withBorder
              label={t('consent-to-mention')}
            />
          </div>
          {form['consentToMention'].value === '1' && (
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField
                {...form.howToIdentifyResearcher}
                onChange={onChange}
                label={t('how-to-identify-researcher')}
              />
            </div>
          )}
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.contactAlternativesOk}
              list={YES_AND_NO_LIST.map((hos) => hos.value)}
              onSelect={onChange}
              value={form['contactAlternativesOk'].value}
              displayList={YES_AND_NO_LIST}
              name="contactAlternativesOk"
              withBorder
              label={t('on-behalf-text')}
            />
          </div>
          <div className={styles['dashboard--textfield-wrapper']}>
            <Dropdown
              {...form.preferredLanguage}
              list={languages.map((language) => language.value)}
              onSelect={onChange}
              value={form['preferredLanguage'].value}
              displayList={languages}
              name="preferredLanguage"
              withBorder
              label={t('preferred-language')}
            />
          </div>
          {formHasErrors && (
            <Typography
              className={cx(
                styles['dashboard--text'],
                styles['dashboard--text--error']
              )}
            >
              {t('form-has-errors')}
            </Typography>
          )}
          <Button
            text={t('save-patient-details')}
            onClick={onSubmit}
            loading={isLoading}
          />
        </div>
      )}
    </Layout>
  )
}

const Edit = () => {
  const params = useParams()
  return <>{params?.id && <Update id={params.id} />}</>
}

Update.propTypes = {
  id: PropTypes.string.isRequired,
}

export { Edit }
