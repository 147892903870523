export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },

  {
    text: 'CTRID',
    alignment: 'left',
    size: 'small',
  },
  {
    text: 'Name',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Phone number',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Hospital',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Actions',
    alignment: 'right',
    size: 'small',
  },
]

export const filters = [
  {
    text: 'Any field',
    value: 'any',
  },
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Code',
    value: 'code',
  },
]

export const usePatients = (patients) => {
  const transformedData = patients.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.patientCtrId,
        alignment: 'left',
        size: 'small',
      },
      {
        text: item.patientName,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.patientMobile,
        alignment: 'left',
        size: 'large',
      },

      {
        text: `${item.hospital?.name} (${item.hospital?.code})`,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    patients: transformedData,
    headers,
    filters,
  }
}

export const usePatientsMoreDetails = (patient) => {
  const transformedData = [
    {
      text: patient.patientFirstname,
      title: 'First name',
    },
    {
      text: patient.patientSurname,
      title: 'Surname',
    },
    {
      text: patient.hospital.name,
      title: 'Hospital',
    },
    {
      text: patient.dateOfOperation,
      title: 'Date of operation',
    },
    {
      text: patient.dateOfDischarge,
      title: 'Date of discharge',
    },
  ]
  return {
    patientsMoreDetails: transformedData,
  }
}

export const EXPORT_FORM_DATA = {
  hospital: {
    label: 'Hospital',
    name: 'hospital',
    value: undefined,
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  from: {
    label: 'From (Date of discharge)',
    name: 'from',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  to: {
    label: 'To (Date of discharge)',
    name: 'to',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  consented: {
    label: 'Consentement',
    name: 'consented',
    value: undefined,
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const YES_AND_NO_LIST = [
  {
    text: 'Consented',
    value: 'Yes',
  },
  {
    text: 'Not Consented',
    value: 'No',
  },
]

export const FILTERS_LIST = [
  {
    text: 'Exporl all patients',
    value: 'No',
  },
  {
    text: 'Filter data before exporting',
    value: 'Yes',
  },
]
