import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import patientReducer from '../features/patient/slide'
import userReducer from '../features/user/slide'

import { patientsApi } from '../features/patient/facade'
import { usersApi } from '../features/user/facade'
import { hospitalsApi } from '../features/hospital/facade'
import { languagesApi } from '../features/language/facade'
import { surveysApi } from '../features/survey/facade'
import { dashboardApi } from '../features/dashboard/facade'

export const store = configureStore({
  reducer: {
    patient: patientReducer,
    user: userReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [hospitalsApi.reducerPath]: hospitalsApi.reducer,
    [languagesApi.reducerPath]: languagesApi.reducer,
    [surveysApi.reducerPath]: surveysApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      patientsApi.middleware,
      usersApi.middleware,
      hospitalsApi.middleware,
      languagesApi.middleware,
      surveysApi.middleware,
      dashboardApi.middleware
    ),
})

setupListeners(store.dispatch)
