import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Button, Heading, Typography, Caption } from '..'

import styles from './Details.module.scss'

const DetailsItem = ({ title, text, lastItem, isHtmlCode, fullWidth }) => {
  const detailsItemProps = cx(styles['details--item'], {
    [styles['details--item--no-margin']]: lastItem,
    [styles['details--item--full-width']]: fullWidth,
  })
  return (
    <>
      {Boolean(text) && (
        <div className={detailsItemProps}>
          <Typography
            children={title}
            className={styles['details--item--title']}
          />
          {!isHtmlCode && (
            <Typography
              children={text}
              className={styles['details--item--text']}
            />
          )}
          {isHtmlCode && <code>{text}</code>}
        </div>
      )}
    </>
  )
}

const Details = ({ title, onCancel, list, large, asRow }) => {
  const detailsBodyListItemProps = cx(styles['details__body__list__item'], {
    [styles['details__body__list__item__large']]: large,
    [styles['details__body__list__item__as-row']]: asRow,
  })

  const detailsBodyListProps = cx(styles['details__body__list'], {
    [styles['details__body__list__as-row']]: asRow,
  })
  return (
    <div className={styles['details']}>
      <div className={styles['details__body']}>
        <Heading text={title} size="small" />
        <br />
        <div className={detailsBodyListProps}>
          {list?.map((item, index) => (
            <div key={index} className={detailsBodyListItemProps}>
              <Caption text={item.title} />
              <br />
              {item.data?.map((val, i) => (
                <DetailsItem
                  key={i}
                  text={val.text}
                  title={val.title}
                  isHtmlCode={val.isHtmlCode}
                  lastItem={item.data.length - 1 === i}
                  fullWidth={asRow}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={styles['details__actions']}>
        <div className={styles['details__actions__item']}>
          <Button text="Close" onClick={onCancel} square type="secondary" />
        </div>
      </div>
    </div>
  )
}

Details.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  list: PropTypes.array,
  large: PropTypes.bool,
  asRow: PropTypes.bool,
}

DetailsItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  lastItem: PropTypes.bool,
  isHtmlCode: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

Details.defaultProps = {}
DetailsItem.defaultProps = {
  isHtmlCode: false,
}

export { Details }
