import PropTypes from 'prop-types'
import Pagination from 'react-js-pagination'

const Paging = ({ onClick, currentPage, pages }) => (
  <>
    {currentPage && pages && (
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={10}
        totalItemsCount={pages}
        pageRangeDisplayed={5}
        onChange={onClick}
      />
    )}
  </>
)

Paging.propTypes = {
  onClick: PropTypes.func,
  currentPage: PropTypes.number,
  pages: PropTypes.number,
}

Paging.defaultProps = {}

export { Paging }
