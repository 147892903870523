import React from 'react'
import { X } from 'react-feather'
import PropTypes from 'prop-types'
import EscapeOutside from 'react-escape-outside'
import cx from 'classnames'

import { Typography } from '../typography'

import styles from './Modal.module.scss'

const Modal = ({
  children,
  onClose,
  closeOnClickOutside,
  large,
  title,
  contentWithPadding,
}) => {
  const modalBodyProps = cx(styles['modal--body'], {
    [styles['modal--body--large']]: large,
  })

  const modalContentProps = cx(styles['modal--content'], {
    [styles['modal--content__with-padding']]: contentWithPadding,
  })
  return (
    <div className={styles['modal']}>
      <EscapeOutside
        onEscapeOutside={() => {
          if (closeOnClickOutside) {
            onClose()
          }
        }}
      >
        <div className={modalBodyProps}>
          {title && (
            <div className={styles['modal--title__wrapper']}>
              <Typography children={title} className={styles['modal--title']} />
              <X
                size={18}
                onClick={onClose}
                className={styles['modal--title__icon']}
              />
            </div>
          )}
          <div className={modalContentProps}>{children}</div>
        </div>
      </EscapeOutside>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeOnClickOutside: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  large: PropTypes.bool,
  title: PropTypes.string,
  contentWithPadding: PropTypes.bool,
}

Modal.defaultProps = {}

export { Modal }
