import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useHistory, useParams } from 'react-router-dom'

import { ROUTES } from '../../constants'
import { clearSession } from '../../services/session'
import { useResetPasswordMutation } from '../../services/redux/features/user/facade'
import { Banner, Button, Container, Panel, TextField, Typography } from '../../components'

import styles from '../signIn/SignIn.module.scss'

const FORM_DATA = {
  password: {
    name: 'password',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    type: 'password',
    placeholder: 'New password',
  },
  confirmPassword: {
    name: 'confirmPassword',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    type: 'password',
    placeholder: 'Confirm new password',
  },
}

const ResetPassword = () => {
  const history = useHistory()
  const { id, code } = useParams()
  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    const newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${name} field is required` : '',
          error: isRequired,
        },
      },
    }
    setForm(newForm)
  }

  const [resetPassword, result] = useResetPasswordMutation()
  const { isLoading, isError, isSuccess, error } = result

  useEffect(() => {
    if (isError || error) {
      setBanner({
        text: error.data.error,
        type: 'error',
      })
    }

    if (isSuccess) {
      setBanner({
        text: 'The password has been successfully reset',
        type: 'success',
      })
      setForm(FORM_DATA)
    }
  }, [isError, isSuccess, error])

  const onSubmit = async () => {
    setBanner(undefined)
    const body = {
      password: form['password'].value.trim(),
      id: Number(id),
      code,
    }
    await resetPassword(body)
  }

  const goToLogIn = async () => {
    clearSession()
    history.push(ROUTES.SIGN_IN)
  }

  const canSubmitForm =
    form['password'].value && form['password'].value === form['confirmPassword'].value

  return (
    <Container>
      <div className={styles['sign-in']}>
        <div className={cx(styles['sign-in__column'], styles['sign-in__column__large'])}>
          <img src="/dsine-logo.png" alt="logo" className={styles['sign-in--logo']} />
        </div>
        <div className={cx(styles['sign-in__column'], styles['sign-in__column__small'])}>
          <Typography children="Reset password" className={styles['sign-in--form-title']} />
          {banner && <Banner text={banner?.text} type={banner?.type} />}
          {banner?.type !== 'success' && (
            <Panel noPadding>
              <div className={styles['sign-in--textfield-wrapper']}>
                <TextField
                  noBorder
                  {...form.password}
                  onChange={onChange}
                  onPressEnter={onSubmit}
                />
              </div>
              <div className={styles['sign-in--textfield-wrapper']}>
                <TextField
                  noBorder
                  {...form.confirmPassword}
                  onChange={onChange}
                  onPressEnter={onSubmit}
                />
              </div>
              <Button
                text="Reset password"
                onClick={onSubmit}
                square
                disabled={!canSubmitForm}
                loading={isLoading}
              />
            </Panel>
          )}
          {banner?.type === 'success' && <Button text="Log in" onClick={goToLogIn} />}
        </div>
      </div>
    </Container>
  )
}

export { ResetPassword }
