import { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useGetCompletedSurveysMutation } from '../../../services/redux/features/dashboard/facade'
import { intToString } from '../../../helpers'

import {
  Typography,
  TableRow,
  Skeletons,
  Dropdown,
  TextField,
  Table,
  TableHeader,
} from '../../../components'

import styles from './../Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const CompletedSurveys = ({ languages }) => {
  const selectedLanguage = languages?.[0]?.value
  const [language, setLanguage] = useState(selectedLanguage)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)

  const [getData, result] = useGetCompletedSurveysMutation()

  const { data, isLoading, isFetching, isSuccess } = result

  useEffect(() => {
    if (languages.length) {
      const today = new Date(Date.now())
      setEndDate(moment(today).format(DATE_FORMAT))

      const oneWeek = today.setDate(today.getDate() - 7)
      setStartDate(moment(new Date(oneWeek)).format(DATE_FORMAT))
    }
  }, [languages])

  const loadData = async () => {
    const languageId =
      (await languages.find(
        (lang) => lang.text.toLowerCase() === language.toLowerCase()
      )?.value) ?? language
    if (languageId && startDate && endDate) {
      getData({
        languageId,
        startDate,
        endDate,
      })
    }
  }

  useEffect(() => {
    if (startDate || endDate || language) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, language])

  const headers = [
    {
      text: '',
      alignment: 'left',
      size: 'extra-small',
    },
    {
      text: 'Section',
      alignment: 'left',
      size: 'extra-large',
    },
    {
      text: 'Duration',
      alignment: 'left',
      size: 'large',
    },
  ]

  const surveyCompletion = data?.data?.surveyCompletion

  const topLevelData = surveyCompletion
    ? [
        {
          text: '',
          alignment: 'left',
          size: 'extra-small',
        },
        {
          text: `Total surveys: ${surveyCompletion.totalSurveys}`,
          alignment: 'left',
          size: 'large',
        },
        {
          text: `Completed surveys: ${surveyCompletion.completedSurverys}`,
          alignment: 'left',
          size: 'large',
        },
        {
          text: `Completed surveys in %: ${surveyCompletion.percentCompleteSurveys.replace(
            '%',
            ''
          )}`,
          alignment: 'left',
          size: 'large',
        },
      ]
    : []

  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-wrapper--row']}>
        <Typography
          children="Completed surveys"
          className={styles['table-wrapper--title']}
        />
        <div className={styles['dashboard--row']}>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setStartDate(text)}
              placeholder="Start Date"
              type="date"
              required
              value={startDate}
              label="Start date"
            />
          </div>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setEndDate(text)}
              placeholder="End Date"
              type="date"
              required
              value={endDate}
              label="End date"
            />
          </div>
          <div className={styles['table-wrapper--header--dropdown-wrapper']}>
            <Dropdown
              list={languages.map((lang) => lang.value)}
              onSelect={(_, text) => {
                setLanguage(text)
              }}
              displayList={languages}
              name="language"
              withBorder
              placeholder="Language"
              value={language}
              label="Language"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      {isSuccess && surveyCompletion?.totalSurveys > 0 && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Total Completed surveys"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={intToString(surveyCompletion?.completedSurverys)}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Table size="small">
              {isLoading && <Skeletons type="table" />}
              {isSuccess && (
                <>
                  <Typography
                    children="Summary"
                    className={styles['table-wrapper--subtitle']}
                  />
                  <TableRow data={topLevelData} />
                </>
              )}
            </Table>
            <br />
            <Table size="small">
              {isLoading && <Skeletons type="table" />}
              {isSuccess && (
                <>
                  {surveyCompletion.surveys?.map((surveyItem, surveyIndex) => {
                    const values = Object.values(surveyItem)[0]
                    return (
                      <div key={surveyIndex}>
                        <Typography
                          children={`Patient's name: ${values.patientName}`}
                          className={styles['table-wrapper--subtitle']}
                        />
                        {values.patientCompletedSurveys?.map(
                          (completedSurvery, completedSurveryIndex) => {
                            const itemKey = Object.keys(completedSurvery)[0]
                            const vals = Object.values(completedSurvery)[0]

                            const transformedData = vals?.map((item) => {
                              return [
                                {
                                  text: '',
                                  alignment: 'left',
                                  size: 'extra-small',
                                },
                                {
                                  text: item.section,
                                  alignment: 'left',
                                  size: 'extra-large',
                                },
                                {
                                  text: item.duration,
                                  alignment: 'left',
                                  size: 'large',
                                },
                              ]
                            })

                            return (
                              <div key={completedSurveryIndex}>
                                <Typography
                                  children={itemKey}
                                  className={
                                    styles['table-wrapper--subtitle--small']
                                  }
                                />
                                <br />
                                <Table size="small">
                                  <TableHeader data={headers} />
                                  {transformedData.map((item, innerIndex) => (
                                    <TableRow data={item} key={innerIndex} />
                                  ))}
                                </Table>
                              </div>
                            )
                          }
                        )}
                      </div>
                    )
                  })}
                </>
              )}
            </Table>
          </div>
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Total Completed surveys"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children="0"
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Skeletons type="table" />
            <Skeletons type="table" />
          </div>
        </div>
      )}
    </div>
  )
}

CompletedSurveys.defaultProps = {
  languages: [],
}

CompletedSurveys.propTypes = {
  languages: PropTypes.array,
}

export { CompletedSurveys }
