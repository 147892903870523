import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ChevronLeft } from 'react-feather'

import { Typography } from '../typography'
import styles from './BackButton.module.scss'

const BackButton = ({ text, size, onClick }) => {
  const backButtonProps = cx(styles['back-button'], {
    [styles[`back-button--${size}`]]: true,
  })
  return (
    <div className={styles['back-button--wrapper']} onClick={onClick}>
      <ChevronLeft className={styles['back-button--icon']} size={22} />
      <Typography className={backButtonProps}>{text}</Typography>
    </div>
  )
}

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  onClick: PropTypes.func.isRequired,
}

BackButton.defaultProps = {
  size: 'small',
}

export { BackButton }
