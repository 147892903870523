export const FontSize = {
  XExtraSmall: 'xxs',
  MExtraSmall: 'mxs',
  ExtraSmall: 'xs',
  Small: 'sm',
  SMedium: 'smd',
  XMedium: 'xmd',
  Medium: 'md',
  Large: 'lg',
  ExtraLarge: 'xl',
  MegaExtraLarge: 'mxl',
  DoubleExtraLarge: 'xxl',
  MegaDoubleExtraLarge: 'mxxl',
}

export const FontWeight = {
  Thin: 'thin',
  Light: 'light',
  Regular: 'regular',
  SemiBold: 'semi-bold',
  Bold: 'bold',
}
