import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { getItem } from '../../services/session'
import { LS_KEYS, ROUTES } from '../../constants'

const SuperUserCheck = () => {
  const history = useHistory()

  useEffect(() => {
    const user = getItem(LS_KEYS.AUTH_USER)
    if (!user?.isSuperUser) {
      history.push(ROUTES.SIGN_IN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export { SuperUserCheck }
