import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { generateId } from '../../../helpers'
import {
  useSyncUserHospitalsMutation,
  useGetUserHospitalsQuery,
} from '../../../services/redux/features/user/facade'
import { useGetHospitalsQuery } from '../../../services/redux/features/hospital/facade'

import { ROUTES } from '../../../constants'
import { useHospitals, FORM_DATA } from './hook'

import {
  BackButton,
  Banner,
  Button,
  Dropdown,
  Heading,
  Layout,
} from '../../../components'
import { Hospitals } from './hospitals'

import styles from '../../home/Home.module.scss'

const Content = ({ id }) => {
  const history = useHistory()
  const { userHospitals: savedUserHospitals } = useSelector(
    (state) => state.user
  )

  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()
  const [dropdownValue, setDropdownValue] = useState()

  const onChange = (_, value) => {
    setDropdownValue({
      id: generateId(),
      value,
    })
  }

  const userHospitalsRequest = useGetUserHospitalsQuery(id)
  const hospitalsRequest = useGetHospitalsQuery()
  const [syncUserHospitals, result] = useSyncUserHospitalsMutation()
  const { isLoading, isError, isSuccess, error } = result

  const { hospitals } = useHospitals(
    hospitalsRequest.data?.data?.hospitals || []
  )
  const { hospitals: userHospitals } = useHospitals(
    userHospitalsRequest.data?.data?.userHospitals || []
  )

  useEffect(() => {
    if (isError && error) {
      setBanner({
        text: JSON.stringify(error.data.error),
        type: 'error',
      })
    }

    if (isSuccess) {
      setBanner({
        text: 'The user has been hospitals have successfully synchronized',
        type: 'success',
      })
      setForm(FORM_DATA)
    }
  }, [isError, isSuccess, error])

  const onSubmit = async () => {
    setBanner(undefined)
    const body = {
      id,
      hospitalIds: savedUserHospitals,
    }
    await syncUserHospitals(body)
  }

  const disabled = savedUserHospitals.length === 0

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <BackButton
          text="See interviewers"
          onClick={() => {
            history.push(ROUTES.INTERVIEWERS)
          }}
        />
        <Heading text="Synchronize hospitals" noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        {banner?.type !== 'success' && (
          <div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...form.hospital}
                list={hospitals.map((hospital) => hospital.value.toString())}
                onSelect={onChange}
                value={form['hospital'].value}
                displayList={hospitals}
                noValue
              />
            </div>
            {userHospitalsRequest.isSuccess && hospitalsRequest.isSuccess && (
              <Hospitals
                userHospitals={userHospitals}
                hospitals={hospitals}
                dropdownValue={dropdownValue}
              />
            )}
            <br />
            <Button
              text="Sync hospitals"
              onClick={onSubmit}
              loading={isLoading}
              disabled={disabled || isLoading}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

const Attach = () => {
  const params = useParams()
  return <>{params?.id && <Content id={params.id} />}</>
}

Content.propTypes = {
  id: PropTypes.string.isRequired,
}

export { Attach }
