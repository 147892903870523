export const FORM_DATA = {
  name: {
    label: 'Name',
    name: 'name',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  type: {
    label: 'Type',
    name: 'type',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  language: {
    label: 'Language',
    name: 'language',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  status: {
    label: 'Status',
    name: 'status',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  data: {
    label: 'Data',
    name: 'data',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  isDefault: {
    name: 'isDefault',
    value: false,
    helper: {
      text: '',
      error: false,
    },
    required: false,
  },
}

export const useModals = (data) => {
  const items = data.map((item) => {
    return {
      text: item.name,
      value: item.id,
    }
  })
  return {
    items,
  }
}

export const STATUSES = [
  {
    text: 'Active',
    value: 'ACTIVE',
  },
  {
    text: 'Inactive',
    value: 'INACTIVE',
  },
]
