import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from '../typography'
import styles from './Heading.module.scss'

const Heading = ({ text, noMargin, size }) => {
  const headingProps = cx(styles['heading'], {
    [styles['heading--no-margin']]: noMargin,
    [styles[`heading--${size}`]]: true,
  })
  return <Typography className={headingProps}>{text}</Typography>
}

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Heading.defaultProps = {
  size: 'large',
}

export { Heading }
