import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { TextField } from '../textfield'

import styles from './Search.module.scss'

const Search = ({ onSearch, disabled }) => {
  const { t } = useTranslation('common')
  const [searchTerm, setSearchTerm] = useState('')
  return (
    <div className={styles['search']}>
      <TextField
        noBorder
        placeholder={t('search-term')}
        onChange={(_, val) => {
          setSearchTerm(val)
          if (!val) {
            onSearch(undefined)
          }
        }}
        className={styles['search--textfield']}
        name="search_term"
        isSearchField
        onPressEnter={() => {
          if (!disabled) {
            onSearch(searchTerm)
          }
        }}
      />
      <div
        className={styles['search--button']}
        onClick={() => {
          if (!disabled) {
            onSearch(searchTerm)
          }
        }}
      >
        <SearchIcon size={14} />
      </div>
    </div>
  )
}

Search.propTypes = {
  filters: PropTypes.array,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
}

Search.defaultProps = {}

export { Search }
