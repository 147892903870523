import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  patient: {
    firstName: 'john',
    lastName: 'doe',
  },
  personalDetails: undefined,
  otherNumbers: undefined,
  alternativeNumbers: undefined,
  address: undefined,
  otherInformation: undefined,
}

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatient: (state, action) => {
      state.patient = action.payload
    },
    setPersonalDetails: (state, action) => {
      state.personalDetails = action.payload
    },
    setOtherNumbers: (state, action) => {
      state.otherNumbers = action.payload
    },
    setAlternativeNumbers: (state, action) => {
      state.alternativeNumbers = action.payload
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setOtherInformation: (state, action) => {
      state.otherInformation = action.payload
    },
  },
  extraReducers: () => {},
})

export const {
  setPatient,
  setPersonalDetails,
  setOtherNumbers,
  setAlternativeNumbers,
  setOtherInformation,
  setAddress,
} = patientSlice.actions

export const selectPatient = (state) => state.patient

export default patientSlice.reducer
