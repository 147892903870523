const translations = {
  common: {
    'search-term': 'Terme de recherche',
    'error-message': 'Une erreur est survenue, réessayez plutard',
    confirm: 'Confirmer',
    cancel: 'Annuler',
  },
  patients: {
    'patient-management': 'Gestion patients',
    'import-list': 'Importer',
    'export-list': 'Exporter',
    'new-patient': 'Nouveau Patient',
    name: 'Nom',
    'phone-number': 'Numéro de telephone',
    hospital: 'Hopital',
    'delete-the-patient': 'Supprimer le patient',
    'more-details-on--the-patient': "Plus d'infos sur le patient",
    'export-patient-list': 'Exporter la liste',
    export: 'Exporter',
    consented: 'Consentent',
    'not-consented': 'Non Consentent',
    'exporl-all-patients': 'Exporler tous les patients',
    'filter-data-before-exporting': "Filtrer avant d'exporter",
    'from-date-of-discharge': 'A Partir De (Date de sortie)',
    'to-date-of-discharge': "Jusqu'à (Date de sortie)",
    'deletion-message': 'Le patient a été supprimé avec succès',
    'file-download-message': 'Fichier télechargé avec succès',
    'personal-details': 'Détails personels',
    email: 'Addresse email',
    address: 'Addresse',
    town: 'Ville',
    quarter: 'Quartier',
    'nearest-landmark': 'Référence',
    'detailed-description': 'Description détaillée',
    'other-number-1': 'Autre numéro 1',
    'other-number-2': 'Autre numéro 2',
    'alternative-number-1': 'Numéro Alternatif 1',
    'alternative-number-2': 'Numéro Alternatif 2',
    relationship: 'Relation',
    'on-behalf-text': 'A Discuter de la part du patient?',
    'other-information': 'Autre information',
    'date-of-study': "Date d'études",
    'date-of-operation': "Date d'operation",
    'date-of-discharge': 'Date de sortie',
    'preferred-language': 'Langue préféréé',
    'wrong-structure-text': "Ce fichier n'a pas le bon format",
    'error-message': 'Une erreur est survenue, réessayez plutard',
    'upload-message': 'La liste des patients ajoutée avec succès',
    'see-patients': 'Voir les patients',
    'import-patients': 'Importer patients',
    'patient-ctrid': 'CTR Id du Patient',
    'filling-consent-date': 'Date du remplissage du formulaire',
    'ressearch-assistant-name': 'Nom du chercheur ayant rempli le formulaire',
    'participant-name': 'Nom complet du participant',
    'participant-has-mobile-number':
      'Le participant a t-il un numéro de telephone',
    'participant-has-alternate-mobile-number':
      'Le participant a t-il un autre numéro de telephone #1',
    'alternative-name':
      'A qui appartient ce numéro de telephone #1 (Nom complet)',
    'alternative-contact-number': 'Numéro de telephone du participant #1',
    'alternative-relationship':
      'Quelle est la relation avec le détenteur de ce numéro #1',
    'alternative-permission': 'A Discuter de la part du patient? #1',
    'participant-has-alternate-mobile-number-2':
      'Le participant a t-il un numéro de telephone #2',
    'alternative-name-2':
      'A qui appartient ce numéro de telephone #2 (Nom complet)',
    'alternative-contact-number-2':
      'Le participant a t-il un autre numéro de telephone #2',
    'alternative-relationship-2':
      'Quelle est la relation avec le détenteur de ce numéro #2',
    'alternative-permission-2': 'A Discuter de la part du patient? #2',
    'participant-has-other-mobile-numbers':
      "Le participant a t-il d'autres numéros",
    'other-contact-number-1':
      'Numéro de telephone supplementaire #3 (si existant)',
    'other-contact-number-2':
      'Numéro de telephone supplementaire #4 (si existant)',
    'consent-to-mention':
      'Si nous devons vous contactez avec ces numéros, est il possible que nos agents mentionnent les raisons des appels and votre implication? Ceci est facultatif.',
    'how-to-identify-researcher':
      'Comment nos agents doivent nous idenfier? A préciser:',
    'add-a-new-patient': 'Ajouter un patient',
    'edit-patient-details': 'Modifier les données du patient',
    'add-message': 'Le patient a été ajouté avec succes',
    'edit-message': 'Le patient a été modifié avec succes',
    'save-patient-details': 'Enregistrer les donnéés du patient',
    'form-has-erros':
      'Le formulaire contient des erreurs, veillez les corriger et enregistrer',
    'ctr-error-message': 'CTR ID ne doit container que 6 chiffres',
  },
  actions: {
    Edit: 'Modifier',
    Delete: 'Supprimer',
    'View More': 'Voir Plus',
    'Attach Hospitals': 'Attachez les hopitaux',
    Surveys: 'Enquêtes',
    'Reset surveys': 'Réinitialisér les enquêtes',
    'Reset survey': "Réinitialisér l'enquête",
  },
  surveys: {
    'extended-details': 'Détails supplémentaires',
    'captured-by': 'Enregistré par',
    language: 'Langue',
    'interview-date': "Date de l'Interview",
    status: 'Statut',
    duration: 'Durée',
    'patient-call-attempts': "Les tentatives d'appel",
    'message-sent': 'Message envoyé',
    reponse: 'Réponse',
    'interview-results': "Résultats de l'Interview",
    hospital: 'Hopital',
    'patient-surveys': 'Enquêtes sur Patient',
    'patient-name': 'Patient Concerné',
    'back-to-patients': 'Voir Patients',
    'more-details-on-surveys': 'Plus de détails',
    survey: 'Enquête',
    'back-to-surveys': 'Voir Enquêtes',
    'edit-survey': "Modifier l'Enquête",
    cancel: 'Retour',
    'interviews-message': 'Enquête modifiée avec succès',
    new: 'Nouveau',
    worse: 'Severe',
    'confirmation-message':
      "Veuillez noter : Cette enquête sera marquée comme complète une fois que vous aurez enregistré les réponses. Il sera enregistré que vous avez modifié l'enquête.",
    'surveys-reset-message':
      "L'enquête sur le patient réinitialisée avec succès",
  },
}

export default translations
