export const FORM_DATA = {
  code: {
    label: 'Code',
    name: 'code',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  name: {
    label: 'Name',
    name: 'name',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}
