import React from 'react'

// import { LS_KEYS } from '../../constants'
// import { getItem } from '../../services/session'

import { Heading, Layout } from '../../components'
import { Languages } from './languages'
import { SurveysTypes } from './surveysTypes'

import styles from '../home/Home.module.scss'

const Settings = () => {
  // const user = getItem(LS_KEYS.AUTH_USER)
  // console.log('user', user)
  return (
    <div className={styles['home']}>
      <Layout>
        <Heading text="Settings" />
        <Languages />
        <SurveysTypes />
      </Layout>
    </div>
  )
}

export { Settings }
