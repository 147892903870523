import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const usersApi = createApi({
  reducerPath: 'USERS_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: '/users/login',
        method: 'POST',
        body,
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Users'],
    }),
    editUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Users'],
    }),
    getUsers: builder.query({
      query: (page) => ({
        url: `/users?include_hospitals=true${page ? `&page=${page}` : ''}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Users'],
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Users'],
    }),
    getRoles: builder.query({
      query: () => ({
        url: '/users/role',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ id, ...body }) => ({
        url: '/users/password',
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: `/users/forgotpassword/${email}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `/users/forgotpassword`,
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getUserHospitals: builder.query({
      query: (id) => ({
        url: `/users/${id}/hospitals`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['UserHospitals'],
    }),
    syncUserHospitals: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/users/${id}/addhospitals`,
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Users', 'UserHospitals'],
    }),
  }),
})

export const {
  useLoginMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useGetRolesQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetUserHospitalsQuery,
  useSyncUserHospitalsMutation,
} = usersApi
