import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const patientsApi = createApi({
  reducerPath: 'PATIENTS_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createPatient: builder.mutation({
      query: (body) => ({
        url: '/patients',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Patients'],
    }),
    editPatient: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/patients/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Patients'],
    }),
    getPatients: builder.query({
      query: (page) => ({
        url: `/patients?page=${page ? page : 1}&limit=${page ? 10 : 100000}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Patients'],
    }),
    getPatient: builder.query({
      query: (id) => ({
        url: `/patients/${id}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    deletePatient: builder.mutation({
      query: (id) => ({
        url: `/patients/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Patients'],
    }),
    getPatientSurveys: builder.mutation({
      query: ({ id, page = 1, limit = 20 }) => ({
        url: `/interviews/patient/${id}?limit=${limit}&page=${page}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Patients'],
    }),
    importPatients: builder.mutation({
      query: (body) => ({
        url: '/patients/upload-csv',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Patients'],
    }),
    postInterviews: builder.mutation({
      query: ({ interviewId, ...body }) => ({
        url: `/interviews/${interviewId}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    exportPatients: builder.mutation({
      query: ({ startDate, endDate, consented, hospitalId }) => ({
        url: `/patients/export?startDate=${startDate}&endDate=${endDate}&consented=${consented}&hospitalId=${hospitalId}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['SurveysType'],
    }),
    getAndSearchPatients: builder.mutation({
      query: ({ page, searchTerm }) => ({
        url: `/patients?page=${page}&limit=${10}${
          searchTerm ? `&search=${searchTerm}` : ''
        }`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Hospitals'],
    }),
    getSurveyQuestions: builder.mutation({
      query: () => ({
        url: '/surveys/app',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getAssistants: builder.query({
      query: () => ({
        url: '/users/research-assistants',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    resetPatientSurveys: builder.mutation({
      query: (patientId) => ({
        url: `/patients/reset-surveys/${patientId}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Hospitals'],
    }),
  }),
})

export const {
  useCreatePatientMutation,
  useGetPatientsQuery,
  useGetPatientQuery,
  useDeletePatientMutation,
  useEditPatientMutation,
  useGetPatientSurveysMutation,
  useImportPatientsMutation,
  useExportPatientsMutation,
  useGetAndSearchPatientsMutation,
  useGetSurveyQuestionsMutation,
  usePostInterviewsMutation,
  useGetAssistantsQuery,
  useResetPatientSurveysMutation,
} = patientsApi
