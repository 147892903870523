import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { capitalizeText } from '../../../../helpers'
import {
  useCreateSurveysTypeMutation,
  useEditSurveysTypeMutation,
} from '../../../../services/redux/features/survey/facade'
import { surveyPeriods } from '../../../../constants'

import {
  Banner,
  Button,
  Checkbox,
  Heading,
  TextField,
} from '../../../../components'

import styles from '../../../../components/delete/Delete.module.scss'

const SurveysTypeForm = ({
  onCancel,
  id,
  name,
  position: savedPosition,
  time_unit: timeUnit,
}) => {
  const [text, setText] = useState('')
  const [position, setPosition] = useState(0)
  const [placeholder, setPlaceholder] = useState('Surveys type to add')
  const [title, setTitle] = useState('Add a new surveys type')
  const [buttonText, setButtonText] = useState('Add the surveys type')
  const [banner, setBanner] = useState(undefined)
  const [surveyPeriod, setSurveyPeriod] = useState('')
  const [createSurveysType, result] = useCreateSurveysTypeMutation()
  const [editSurveysType, editResult] = useEditSurveysTypeMutation()

  const onChangeSurveyPeriod = (name, _) => {
    const period = surveyPeriods.find(
      (item) => item.text.toLowerCase() === name.toString().toLowerCase()
    )?.value
    setSurveyPeriod(period)
  }

  useEffect(() => {
    if (name) {
      let number = name.replace(/\D/g, '')
      setText(number)
      let period = name.replace(/\d/g, '')
      onChangeSurveyPeriod(period)

      setPlaceholder('Surveys type to edit')
      setTitle('Edit the surveys type')
      setButtonText('Edit the surveys type')
    }

    if (savedPosition) {
      setPosition(savedPosition.toString())
      setSurveyPeriod(timeUnit)
    }
  }, [name, savedPosition, timeUnit])

  useEffect(() => {
    if (result.error || editResult.error) {
      setBanner({
        text: JSON.stringify(result.error),
        type: 'error',
      })
    }

    if (result.isSuccess || (editResult.isSuccess && id)) {
      setBanner({
        text: `The surveys type has been successfully ${
          id ? 'edited' : 'added'
        }`,
        type: 'success',
      })
    }
  }, [result, editResult, id])

  const onSubmit = async () => {
    const period = surveyPeriods.find(
      (item) =>
        item.value.toLowerCase() === surveyPeriod.toString().toLowerCase()
    )

    const body = {
      name: `${text} ${capitalizeText(period.text)}`,
      intervalTime: Number(text),
      intervalUnit: surveyPeriod,
      position: Number(position),
    }

    if (id) {
      await editSurveysType({ ...body, id })
    } else {
      await createSurveysType(body)
    }
  }

  const loading = result.isLoading || editResult.isLoading

  return (
    <div className={styles['delete']}>
      <div className={styles['delete__body']}>
        <Heading text={title} size="small" />
        <br />
        {banner && (
          <Banner
            text={banner.text}
            type={banner.type}
            noMargin={banner.type === 'success'}
          />
        )}
        {banner?.type !== 'success' && (
          <>
            <TextField
              name="name"
              onChange={(_, value) => setText(value)}
              placeholder={placeholder}
              size="large"
              type="number"
              value={text}
              required
              readOnly={loading}
            />
            <br />
            <TextField
              name="position"
              onChange={(_, value) => setPosition(value)}
              placeholder="Position"
              size="large"
              type="number"
              value={savedPosition?.toString() ?? position}
              required
              readOnly={loading}
            />
            <div className={styles['checkbox-list']}>
              {surveyPeriods.map((period, index) => (
                <div className={styles['checkbox-list--item']} key={index}>
                  <Checkbox
                    text={capitalizeText(period.text)}
                    noMargin
                    checked={surveyPeriod === period.value}
                    onChange={onChangeSurveyPeriod}
                    name={period.text}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className={styles['delete__actions']}>
        <div className={styles['delete__actions__item']}>
          <Button
            text={banner?.type !== 'success' ? 'Cancel' : 'Close'}
            onClick={onCancel}
            square
            disabled={loading}
            loading={false}
            type="secondary"
          />
        </div>
        {banner?.type !== 'success' && (
          <div className={styles['delete__actions__item']}>
            <Button
              text={buttonText}
              onClick={onSubmit}
              square
              disabled={!Boolean(text) || loading || !surveyPeriod}
              loading={loading}
            />
          </div>
        )}
      </div>
    </div>
  )
}

SurveysTypeForm.propTypes = {
  onCancel: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.number,
}

SurveysTypeForm.defaultProps = {
  name: '',
}

export { SurveysTypeForm }
