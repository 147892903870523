export const FORM_DATA = {
  firstname: {
    label: 'First name',
    name: 'firstname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  surname: {
    label: 'Surname',
    name: 'surname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  email: {
    label: 'Email address',
    name: 'email',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  contactNumber: {
    label: 'Contact number',
    name: 'contactNumber',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  role: {
    label: 'Role',
    name: 'role',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const useRoles = (data) => {
  const roles = data.map((role) => {
    return {
      text: role.name,
      value: role.id,
    }
  })
  return {
    roles,
  }
}
