import { LS_KEYS } from '../constants'
import { getItem } from '../services/session'

export const canOnlySeePatients = () => {
  const auth = getItem(LS_KEYS.AUTH_USER)
  return auth?.role?.name === 'PATIENT_CAPTURE_ONLY'
}

export const userHasFullAcess = () => {
  const auth = getItem(LS_KEYS.AUTH_USER)
  return auth?.role?.name === 'FULL_ACCESS'
}
