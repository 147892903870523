import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  useCreateLanguageMutation,
  useEditLanguageMutation,
} from '../../../../services/redux/features/language/facade'

import { capitalizeText } from '../../../../helpers'
import { Banner, Button, Heading, TextField } from '../../../../components'

import styles from '../../../../components/delete/Delete.module.scss'

const LanguageForm = ({ onCancel, id, name }) => {
  const [text, setText] = useState('')
  const [placeholder, setPlaceholder] = useState('Language to add')
  const [title, setTitle] = useState('Add a new language')
  const [buttonText, setButtonText] = useState('Add the language')
  const [banner, setBanner] = useState(undefined)

  const [createLanguage, result] = useCreateLanguageMutation()
  const [editLanguage, editResult] = useEditLanguageMutation()

  useEffect(() => {
    if (name) {
      setPlaceholder('Language to edit')
      setTitle('Edit the language')
      setButtonText('Edit the language')
    }
  }, [name])

  useEffect(() => {
    if (result.isError || editResult.isError) {
      setBanner({
        text: 'An error has occurred, please try again',
        type: 'error',
      })
    }

    if (result.isSuccess || (editResult.isSuccess && id)) {
      setBanner({
        text: `The language has been successfully ${id ? 'edited' : 'added'}`,
        type: 'success',
      })
    }
  }, [result, editResult, id])

  const onSubmit = async () => {
    const body = {
      name: capitalizeText(text),
    }
    if (id) {
      await editLanguage({ ...body, id })
    } else {
      await createLanguage(body)
    }
  }

  const loading = result.isLoading || editResult.isLoading

  return (
    <div className={styles['delete']}>
      <div className={styles['delete__body']}>
        <Heading text={title} size="small" />
        <br />
        {banner && (
          <Banner text={banner.text} type={banner.type} noMargin={banner.type === 'success'} />
        )}
        {banner?.type !== 'success' && (
          <TextField
            name="name"
            onChange={(_, value) => setText(value)}
            placeholder={placeholder}
            size="large"
            onPressEnter={onSubmit}
            value={name}
            required
            readOnly={loading}
          />
        )}
      </div>

      <div className={styles['delete__actions']}>
        <div className={styles['delete__actions__item']}>
          <Button
            text={banner?.type !== 'success' ? 'Cancel' : 'Close'}
            onClick={onCancel}
            square
            disabled={loading}
            loading={false}
            type="secondary"
          />
        </div>
        {banner?.type !== 'success' && (
          <div className={styles['delete__actions__item']}>
            <Button
              text={buttonText}
              onClick={onSubmit}
              square
              disabled={!Boolean(text) || loading}
              loading={loading}
            />
          </div>
        )}
      </div>
    </div>
  )
}

LanguageForm.propTypes = {
  onCancel: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.number,
}

LanguageForm.defaultProps = {
  name: '',
}

export { LanguageForm }
