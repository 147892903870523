export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },
  {
    text: 'First name',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Last name',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Phone number',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Email address',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Actions',
    alignment: 'right',
    size: 'small',
  },
]

export const filters = [
  {
    text: 'Any field',
    value: 'any',
  },
  {
    text: 'First name',
    value: 'firstname',
  },
  {
    text: 'Surname',
    value: 'surname',
  },
  {
    text: 'Email address',
    value: 'email',
  },
]

export const useUsers = (users) => {
  const transformedData = users.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.firstname,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.surname,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.contactNumber,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.email,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    users: transformedData,
    headers,
    filters,
  }
}
