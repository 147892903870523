import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { ACTIONS } from '../../../constants'
import { useHospitals } from './hook'
import {
  useGetAndSearchHospitalsMutation,
  useDeleteHospitalMutation,
} from '../../../services/redux/features/hospital/facade'
import { ROUTES } from '../../../constants'

import {
  Button,
  Heading,
  Pagination,
  Layout,
  Search,
  Table,
  TableHeader,
  TableRow,
  Skeletons,
  Modal,
  Delete,
} from '../../../components'

import styles from '../../home/Home.module.scss'
import { attachRouterParams } from '../../../helpers'

const List = () => {
  const [banner, setBanner] = useState()
  const [searchTerm, setSearchTerm] = useState()
  const [selectedHospital, setSelectedHospital] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [getHospitals, result] = useGetAndSearchHospitalsMutation(
    selectedPage,
    searchTerm
  )
  const { isSuccess, data, isLoading, isFetching } = result
  const history = useHistory()

  const loadHospitals = () => {
    getHospitals({
      page: selectedPage,
      searchTerm,
    })
  }

  useEffect(() => {
    loadHospitals()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedPage])

  const [deleteHospital, deleteResult] = useDeleteHospitalMutation()
  const { hospitals, headers, filters } = useHospitals(
    data?.data?.hospitals || []
  )
  const ids = data?.data?.hospitals.map((hospital) => hospital.id) || []

  useEffect(() => {
    if (deleteResult.isError) {
      setBanner({
        text: 'An error has occurred, please try again',
        type: 'error',
      })
    }

    if (deleteResult.isSuccess) {
      setBanner({
        text: 'The hospital has been successfully deleted',
        type: 'success',
      })
    }
  }, [deleteResult])

  const onStartDeletion = async (id) => {
    const items = await data?.data?.hospitals
    const item_to_delete = items.find((hospital) => hospital.id === id)
    setSelectedHospital(item_to_delete)
  }

  const onCancelDeletion = () => {
    setSelectedHospital(undefined)
    setBanner(undefined)
  }

  const onDeleteHospital = async () => {
    if (selectedHospital) {
      await deleteHospital(selectedHospital.id)
    }
  }

  const loading = isLoading || isFetching

  return (
    <Layout>
      <Heading text="Hospital management" />
      <div
        className={cx(
          styles['dashboard--row'],
          styles['dashboard--row--with-margin']
        )}
      >
        <Search
          filters={filters}
          onSearch={(val) => {
            setSelectedPage(1)
            setSearchTerm(val)
          }}
          disabled={loading || deleteResult.isLoading}
        />
        <div
          className={cx(
            styles['dashboard--row'],
            styles['dashboard--row--align-right']
          )}
        >
          <div className={styles['dashboard--button-wrapper']}>
            <Button
              text="New Hospital"
              size="small"
              onClick={() => {
                history.push(ROUTES.CREATE_HOSPITAL)
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['dashboard--row']}>
        <Table size="small">
          <TableHeader data={headers} />
          {loading && <Skeletons type="table" />}
          {isSuccess && hospitals && !loading && (
            <>
              {hospitals.map((data, index) => (
                <TableRow
                  data={data}
                  key={index}
                  onAction={(action) => {
                    if (action === ACTIONS.DELETE) {
                      onStartDeletion(ids[index])
                    } else {
                      history.push(
                        attachRouterParams(ROUTES.EDIT_HOSPITAL, {
                          id: ids[index],
                        })
                      )
                    }
                  }}
                  actions={[ACTIONS.EDIT, ACTIONS.DELETE]}
                />
              ))}
            </>
          )}
        </Table>
      </div>
      <br />
      {isSuccess &&
        !loading &&
        data?.data?.pagination &&
        data?.data?.hospitals.length > 0 && (
          <Pagination
            onClick={(page) => setSelectedPage(page)}
            currentPage={data?.data?.pagination?.currentPage}
            pages={data?.data?.pagination?.totalRecords}
          />
        )}
      {selectedHospital && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeleteHospital}
            title="Delete the hospital"
            onCancel={onCancelDeletion}
            banner={banner}
            loading={deleteResult.isLoading}
          />
        </Modal>
      )}
    </Layout>
  )
}

export { List }
