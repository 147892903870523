import React from 'react'
import { useGetUserDataQuery } from '../../../services/redux/features/dashboard/facade'
import { capitalizeText, intToString } from '../../../helpers'

import { Typography, TableRow } from '../../../components'

import styles from './../Home.module.scss'

const UserData = () => {
  const { data, isLoading, isSuccess } = useGetUserDataQuery()

  const phoneCalls = data?.data?.dashboard?.phoneCalls
  const smses = data?.data?.dashboard?.smses
  const surveys = data?.data?.dashboard?.surveys

  let totalNumberOfPhoneCalls = 0
  if (phoneCalls?.[2]) {
    totalNumberOfPhoneCalls = phoneCalls[2].total?.total
  }

  let totalNumberOfSmses = 0
  if (smses?.[2]) {
    totalNumberOfSmses = smses[2].total?.percentageResponded
  }

  return (
    <div className={styles['table-wrapper']}>
      <br />
      <Typography
        children="Your data"
        className={styles['table-wrapper--title']}
      />
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Number of Calls"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={isLoading ? 0 : intToString(totalNumberOfPhoneCalls)}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            {phoneCalls?.map((phoneCall, index) => {
              const values = Object.values(phoneCall)
              return (
                <div key={index}>
                  <Typography
                    children={capitalizeText(Object.keys(phoneCall)[0])}
                    className={styles['table-wrapper--subtitle']}
                  />
                  {values?.map((value, i) => {
                    const res = [
                      {
                        text: '',
                        alignment: 'left',
                        size: 'extra-small',
                      },
                      {
                        text: `Answered: ${value?.answered}`,
                        alignment: 'left',
                        size: 'large',
                      },
                      {
                        text: `Busy: ${value?.busy}`,
                        alignment: 'left',
                        size: 'large',
                      },
                      {
                        text: `Manually ended: ${value?.manuallyEnded}`,
                        alignment: 'left',
                        size: 'large',
                      },
                      {
                        text: `Total: ${value?.total}`,
                        alignment: 'right',
                        size: 'large',
                      },
                    ]
                    return (
                      <div key={i}>
                        <TableRow data={res} />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Percentage of SMSES"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={isLoading ? 0 : intToString(totalNumberOfSmses)}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            {smses?.map((sms, index) => {
              const values = Object.values(sms)
              return (
                <div key={index}>
                  <Typography
                    children={capitalizeText(Object.keys(sms)[0])}
                    className={styles['table-wrapper--subtitle']}
                  />
                  {values?.map((value, i) => {
                    const res = [
                      {
                        text: '',
                        alignment: 'left',
                        size: 'extra-small',
                      },
                      {
                        text: `Sent: ${value?.sent}`,
                        alignment: 'left',
                        size: 'large',
                      },
                      {
                        text: `Responded: ${value?.responded}`,
                        alignment: 'left',
                        size: 'large',
                      },
                      {
                        text: `Percentage Responded: ${value?.percentageResponded}`,
                        alignment: 'right',
                        size: 'large',
                      },
                    ]
                    return (
                      <div key={i}>
                        <TableRow data={res} />
                        <br />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {isSuccess &&
        surveys?.map((survey, index) => {
          const surveyEntries = Object.entries(survey)[0][1]
          return (
            <div className={styles['table-wrapper--row']} key={index}>
              <div className={styles['table-wrapper--card']}>
                <Typography
                  children="SURVEY TYPE"
                  className={styles['table-wrapper--card--title']}
                  uppercase
                />
                <Typography
                  children={capitalizeText(Object.keys(survey)[0])}
                  className={styles['table-wrapper--card--text--small']}
                  uppercase
                />
              </div>
              <div className={styles['table-wrapper--table']}>
                {surveyEntries?.map((surveyItem, a) => {
                  const values = Object.values(surveyItem)
                  return (
                    <div key={a}>
                      <Typography
                        children={capitalizeText(Object.keys(surveyItem)[0])}
                        className={styles['table-wrapper--subtitle']}
                      />
                      {values?.map((value, i) => {
                        const res = [
                          {
                            text: '',
                            alignment: 'left',
                            size: 'extra-small',
                          },
                          {
                            text: `Answered: ${value?.completed}`,
                            alignment: 'left',
                            size: 'large',
                          },
                          {
                            text: `Busy: ${value?.incomplete}`,
                            alignment: 'left',
                            size: 'large',
                          },
                          {
                            text: `Total: ${value?.total}`,
                            alignment: 'right',
                            size: 'large',
                          },
                        ]
                        return (
                          <div key={i}>
                            <TableRow data={res} />
                            <br />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export { UserData }
