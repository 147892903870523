import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from '../typography'
import styles from './Caption.module.scss'

const Caption = ({ text, size, noMargin }) => {
  const captionProps = cx(styles.caption, {
    [styles[`caption--${size}`]]: true,
    [styles['caption--no-margin']]: noMargin,
  })
  return (
    <Typography className={captionProps} uppercase>
      {text}
    </Typography>
  )
}

Caption.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  noMargin: PropTypes.bool,
}

Caption.defaultProps = {
  size: 'small',
}

export { Caption }
