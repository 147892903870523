import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const hospitalsApi = createApi({
  reducerPath: 'HOSPITALS_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createHospital: builder.mutation({
      query: (body) => ({
        url: '/hospital',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Hospitals'],
    }),
    editHospital: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/hospital/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Hospitals'],
    }),
    getHospitals: builder.query({
      query: (page) => ({
        url: `/hospital?page=${page ? page : 1}&limit=${page ? 10 : 100000}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Hospitals'],
    }),
    getHospital: builder.query({
      query: (id) => ({
        url: `/hospital/${id}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    deleteHospital: builder.mutation({
      query: (id) => ({
        url: `/hospital/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Hospitals'],
    }),
    getAndSearchHospitals: builder.mutation({
      query: ({ page, searchTerm }) => ({
        url: `/hospital?page=${page}&limit=${10}${
          searchTerm ? `&search=${searchTerm}` : ''
        }`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Hospitals'],
    }),
  }),
})

export const {
  useGetHospitalsQuery,
  useGetHospitalQuery,
  useCreateHospitalMutation,
  useEditHospitalMutation,
  useDeleteHospitalMutation,
  useGetAndSearchHospitalsMutation,
} = hospitalsApi
