import momnent from 'moment'

export const generateId = () => {
  return Math.floor(Date.now() / 1000)
}

export const csvToArray = (str, delimiter = ',') => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter)

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter)
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index]
      return object
    }, {})
    return el
  })

  // return the array
  return arr
}

export const iSJSON = (obj) => {
  return obj !== undefined && obj !== null && obj.constructor === Object
}

export const getTimeNow = () => {
  return Math.floor(Date.now() / 1000)
}

export const valueOrNull = (val) => {
  if (val) {
    return val
  } else {
    return null
  }
}

export const setDate = (val) => {
  if (val) {
    return momnent(val).format('YYYY-MM-DD')
  } else {
    return null
  }
}

export const intToString = (value) => {
  let newValue = value
  if (value >= 1000) {
    let suffixes = ['', 'k', 'm', 'b', 't']
    let suffixNum = Math.floor(('' + value).length / 3)
    let shortValue = ''
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      )
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
  }
  return newValue
}

export const scrollToTop = () => {
  window?.scrollTo({ top: 0, behavior: 'smooth' })
}

export const cleanData = (data) => {
  return (
    data?.filter(function (element) {
      return element !== undefined
    }) ?? []
  )
}

export const describeData = (data) => {
  // Check if data is null or undefined
  if (data === null || data === undefined) {
    return '0'
  }

  // Check if data is an empty object
  if (
    typeof data === 'object' &&
    !Array.isArray(data) &&
    Object.keys(data).length === 0
  ) {
    return '0'
  }

  // If data is an object (but not an array)
  if (typeof data === 'object' && !Array.isArray(data)) {
    const key = Object.keys(data)[0]
    return `${key}: ${data[key]}`
  }

  // If data is an array of objects
  if (Array.isArray(data)) {
    return data
      .map((obj) => {
        const key = Object.keys(obj)[0]
        return `${key}: ${obj[key]}`
      })
      .join(' - ')
  }

  return '0'
}
