import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { canOnlySeePatients, userHasFullAcess } from '../../helpers/auth'
import { LS_KEYS } from '../../constants'
import { clearSession, getItem } from '../../services/session'
import { ROUTES } from '../../constants'

import { Typography, Container, Caption, Dropdown } from '../../components'

import styles from './Layout.module.scss'

const LANGUAGES = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Français',
    value: 'fr',
  },
]

const Layout = ({ children }) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (location) {
      const { pathname } = location
      setCurrentPage(pathname)
    }
  }, [location])

  const onLogOut = () => {
    clearSession()
    history.push(ROUTES.SIGN_IN)
  }

  const onNavigate = (route) => {
    setMenuOpen(false)
    setTimeout(() => {
      history.push(route)
    }, 150)
  }

  const sideMenuProps = cx(styles['layout--side-menu'], {
    [styles['layout--side-menu--open']]: menuOpen,
  })

  const user = getItem(LS_KEYS.AUTH_USER)

  return (
    <div className={styles['layout']}>
      <div className={sideMenuProps}>
        <div className={styles['layout--side-menu--logo-wrapper']}>
          <img
            src="/dsine-logo.png"
            alt="logo"
            className={styles['layout--side-menu--logo']}
            onClick={() => onNavigate(ROUTES.HOME)}
          />
        </div>
        <div className={styles['layout--side-menu--list']}>
          <ul>
            <li
              onClick={() => onNavigate(ROUTES.HOME)}
              className={
                styles[`${currentPage === ROUTES.HOME ? 'active' : ''}`]
              }
            >
              <Caption text="Overall" />
              <span>Dashboard</span>
            </li>
            <li
              onClick={() => onNavigate(ROUTES.PATIENTS)}
              className={
                styles[
                  `${currentPage.includes(ROUTES.PATIENTS) ? 'active' : ''}`
                ]
              }
            >
              <Caption text="People care" />
              <span>Patients</span>
            </li>
            {!canOnlySeePatients() && (
              <>
                <li
                  onClick={() => onNavigate(ROUTES.HOSPITALS)}
                  className={
                    styles[
                      `${
                        currentPage.includes(ROUTES.HOSPITALS) ? 'active' : ''
                      }`
                    ]
                  }
                >
                  <Caption text="facilities management" />
                  <span>Hospitals</span>
                </li>
                <li
                  onClick={() => onNavigate(ROUTES.INTERVIEWERS)}
                  className={
                    styles[
                      `${
                        currentPage.includes(ROUTES.INTERVIEWERS)
                          ? 'active'
                          : ''
                      }`
                    ]
                  }
                >
                  <Caption text="User management" />
                  <span>Interviewers</span>
                </li>
                {user?.isSuperUser && (
                  <li
                    onClick={() => onNavigate(ROUTES.SURVEYS)}
                    className={
                      styles[
                        `${
                          currentPage.includes(ROUTES.SURVEYS) &&
                          currentPage !== ROUTES.EXPORT_SURVEYS
                            ? 'active'
                            : ''
                        }`
                      ]
                    }
                  >
                    <Caption text="Administration" />
                    <span>Surveys</span>
                  </li>
                )}
                {(userHasFullAcess() || user?.isSuperUser) && (
                  <li
                    onClick={() => onNavigate(ROUTES.EXPORT_SURVEYS)}
                    className={
                      styles[
                        `${
                          currentPage.includes(ROUTES.EXPORT_SURVEYS)
                            ? 'active'
                            : ''
                        }`
                      ]
                    }
                  >
                    <Caption text="Administration" />
                    <span>Export survey</span>
                  </li>
                )}
                <li
                  onClick={() => onNavigate(ROUTES.SETTINGS)}
                  className={
                    styles[
                      `${currentPage.includes(ROUTES.SETTINGS) ? 'active' : ''}`
                    ]
                  }
                >
                  <Caption text="Configuration" />
                  <span>Settings</span>
                </li>
                <li
                  onClick={() => onNavigate(ROUTES.REPORTS)}
                  className={
                    styles[
                      `${currentPage.includes(ROUTES.REPORTS) ? 'active' : ''}`
                    ]
                  }
                >
                  <Caption text="Analytics" />
                  <span>Reports</span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className={styles['layout--content']}>
        <div className={styles['layout--navbar']}>
          <div className={styles['layout--navbar--menu-trigger']}>
            <div onClick={() => setMenuOpen(!menuOpen)}>
              <Caption
                text={menuOpen ? 'hide menu' : 'Show menu'}
                size="large"
              />
            </div>
          </div>
          <div className={styles['layout--navbar--right-wrapper']}>
            <Dropdown
              list={LANGUAGES.map((lang) => lang.value)}
              onSelect={(_, text) => {
                i18n.changeLanguage(text)
              }}
              displayList={LANGUAGES}
              withBorder
              placeholder="Language"
              value={i18n?.language?.substring(0, 2)}
              name="language"
              languageSelector
            />
            <div
              className={styles['layout--navbar--logout']}
              onClick={onLogOut}
            >
              <Typography
                children="Log out"
                className={styles['layout--navbar--logout--text']}
              />
            </div>
          </div>
        </div>
        <div className={styles['layout--content--wrapper']}>
          <Container>{children}</Container>
        </div>
      </div>
      {menuOpen && (
        <div
          onClick={() => setMenuOpen(false)}
          className={styles['layout--backdrop']}
        />
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {}

export { Layout }
