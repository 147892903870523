import React, { useState } from 'react'
import Collapse from '@kunukn/react-collapse'
import { ChevronDown } from 'react-feather'
import cx from 'classnames'

import { useGetUserTempDataQuery } from '../../../services/redux/features/dashboard/facade'
import { capitalizeText } from '../../../helpers'

import { Typography, TableRow, Skeletons, Dropdown } from '../../../components'

import styles from './../Home.module.scss'

const LANGUAGES = [
  {
    text: 'English',
    value: 'english',
  },
  {
    text: 'French',
    value: 'french',
  },
]

const Users = () => {
  const [language, setLanguage] = useState('english')
  const [showPhoneCells, setShowPhoneCells] = useState('phone-cells')
  const { data, isLoading, isFetching, isSuccess } =
    useGetUserTempDataQuery(language)
  // const dashboardUsersTempsRequest = useGetDashboardUsersTempQuery({
  //   ids,
  //   language,
  // })

  const phoneCalls = data?.data?.dashboard?.phoneCalls
  const smses = data?.data?.dashboard?.smses
  const surveys = data?.data?.dashboard?.surveys

  const returnTableRows = (values) => {
    let arr = [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
    ]

    if (Object.keys(values).length === 0) {
      return arr
    } else if (Object.keys(values).length === 1) {
      const item = Object.entries(values)[0]
      arr.push({
        text: `${item[0]}: ${item[1]}`,
        alignment: 'left',
        size: 'large',
      })
    } else {
      Object.entries(values).forEach((item, index) => {
        arr.push({
          text: `${item[0]}: ${item[1]}`,
          alignment: 'left',
          size: 'large',
        })
      })
    }

    return arr
  }

  const arrowProps = (rotate) =>
    cx(styles['table-wrapper--icon'], {
      [styles['table-wrapper--icon--rotate']]: rotate,
    })

  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-wrapper--row']}>
        <Typography
          children="Users data"
          className={styles['table-wrapper--title']}
        />
      </div>
      <div
        className={cx(
          styles['table-wrapper--row'],
          styles['table-wrapper--row__top-line']
        )}
      >
        <div />
        <div className={styles['table-wrapper--header--dropdown-wrapper']}>
          <Dropdown
            list={LANGUAGES.map((lang) => lang.value)}
            onSelect={(_, text) => {
              if (text !== language) {
                setLanguage(text)
              }
            }}
            displayList={LANGUAGES}
            name="language"
            withBorder
            placeholder="Language"
            value={language}
            label="Language"
          />
        </div>
      </div>
      <br />
      <br />
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="LANGUAGE"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={language}
              className={styles['table-wrapper--card--text--small']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <div
              className={cx(
                styles['table-wrapper--row'],
                styles['table-wrapper--row--clickable'],
                styles[
                  `table-wrapper--row--clickable--${
                    showPhoneCells === 'phone-cells' ? 'open' : ''
                  }`
                ]
              )}
              onClick={() => setShowPhoneCells('phone-cells')}
            >
              <Typography
                children="Phone cells"
                className={styles['table-wrapper--title']}
              />
              <ChevronDown
                size={20}
                className={arrowProps(showPhoneCells === 'phone-cells')}
              />
            </div>
            <Collapse isOpen={showPhoneCells === 'phone-cells'}>
              {phoneCalls?.map((phoneCall, index) => {
                return (
                  <div key={index}>
                    {Object.keys(phoneCall)?.map((item, i) => {
                      const values = Object.values(phoneCall)[i]
                      const res = returnTableRows(values)
                      return (
                        <div key={i}>
                          <Typography
                            children={capitalizeText(item)}
                            className={styles['table-wrapper--subtitle']}
                          />
                          <TableRow data={res} />
                          <br />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Collapse>
            <br />
            <div
              className={cx(
                styles['table-wrapper--row'],
                styles['table-wrapper--row--clickable'],
                styles[
                  `table-wrapper--row--clickable--${
                    showPhoneCells === 'smses' ? 'open' : ''
                  }`
                ]
              )}
              onClick={() => setShowPhoneCells('smses')}
            >
              <Typography
                children="SMSes"
                className={styles['table-wrapper--title']}
              />
              <ChevronDown
                size={20}
                className={arrowProps(showPhoneCells === 'smses')}
              />
            </div>
            <Collapse isOpen={showPhoneCells === 'smses'}>
              {smses?.map((sms, index) => {
                return (
                  <div key={index}>
                    {Object.keys(sms)?.map((item, i) => {
                      const values = Object.values(sms)[i]
                      const res = returnTableRows(values)
                      return (
                        <div key={i}>
                          <Typography
                            children={capitalizeText(item)}
                            className={styles['table-wrapper--subtitle']}
                          />
                          <TableRow data={res} />
                          <br />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Collapse>
            <br />

            <div
              className={cx(
                styles['table-wrapper--row'],
                styles['table-wrapper--row--clickable'],
                styles[
                  `table-wrapper--row--clickable--${
                    showPhoneCells === 'surveys' ? 'oepn' : ''
                  }`
                ]
              )}
              onClick={() => setShowPhoneCells('surveys')}
            >
              <Typography
                children="Surveys"
                className={styles['table-wrapper--title']}
              />
              <ChevronDown
                size={20}
                className={arrowProps(showPhoneCells === 'surveys')}
              />
            </div>
            <Collapse isOpen={showPhoneCells === 'surveys'}>
              {surveys?.map((survey, index) => {
                return (
                  <div key={index}>
                    {Object.keys(survey)?.map((item, i) => {
                      const values = Object.values(survey)[0]
                      return (
                        <div key={i}>
                          <Typography
                            children={capitalizeText(item)}
                            className={styles['table-wrapper--subtitle']}
                          />
                          {values.length > 0 &&
                            values.map((val, v) => {
                              const vals = Object.values(val)[0]
                              const res = returnTableRows(vals)
                              return (
                                <div key={v}>
                                  <Typography
                                    children={capitalizeText(
                                      Object.keys(val)[0]
                                    )}
                                    className={
                                      styles['table-wrapper--subtitle--small']
                                    }
                                  />
                                  <TableRow data={res} />
                                  <br />
                                </div>
                              )
                            })}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Collapse>
          </div>
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="LANGUAGE"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={language}
              className={styles['table-wrapper--card--text--small']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Skeletons type="table" />
            <Skeletons type="table" />
          </div>
        </div>
      )}
    </div>
  )
}

export { Users }
