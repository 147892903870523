import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Banner, Button, Heading, TextField, Typography } from '..'

import styles from './Delete.module.scss'

const DELETION_MESSAGE =
  'To process with the deletion, please confirm the action by typing `Delete` in the text field below'

const Delete = ({ title, onCancel, onDelete, banner, loading }) => {
  const [canDelete, setCanDelete] = useState(false)
  return (
    <div className={styles['delete']}>
      <div className={styles['delete__body']}>
        <Heading text={title} size="small" />
        <br />
        {banner && (
          <Banner text={banner.text} type={banner.type} noMargin={banner.type === 'success'} />
        )}
        {banner?.type !== 'success' && (
          <>
            <Typography children={DELETION_MESSAGE} className={styles['delete__body__message']} />
            <TextField
              name="deletion"
              onChange={(_, value) => setCanDelete(value.toLowerCase() === 'delete')}
              placeholder="Deletion confirmation message"
              size="large"
              onPressEnter={onDelete}
              required
            />
          </>
        )}
      </div>
      <div className={styles['delete__actions']}>
        <div className={styles['delete__actions__item']}>
          <Button
            text={banner?.type !== 'success' ? 'Cancel' : 'Close'}
            onClick={onCancel}
            square
            disabled={loading}
            loading={false}
            type="secondary"
          />
        </div>
        {banner?.type !== 'success' && (
          <div className={styles['delete__actions__item']}>
            <Button
              text="Delete"
              onClick={onDelete}
              square
              disabled={!canDelete || loading}
              loading={loading}
              type="danger"
            />
          </div>
        )}
      </div>
    </div>
  )
}

Delete.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  banner: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

Delete.defaultProps = {}

export { Delete }
