import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  useEditHospitalMutation,
  useGetHospitalQuery,
} from '../../../services/redux/features/hospital/facade'

import { ROUTES } from '../../../constants'
import { capitalizeText } from '../../../helpers'
import { setHospital, EDIT_FORM_DATA } from './helper'

import {
  BackButton,
  Banner,
  Button,
  Heading,
  Layout,
  TextField,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const Update = ({ id }) => {
  const history = useHistory()

  const [hospitalForm, setHospitalForm] = useState()
  const [banner, setBanner] = useState()

  const onChange = (name, value) => {
    const isRequired = !value && hospitalForm[name].required
    const newForm = {
      ...hospitalForm,
      [name]: {
        ...hospitalForm[name],
        value,
        helper: {
          text: isRequired ? `${hospitalForm[name].label} is required` : '',
          error: isRequired,
        },
      },
    }
    setHospitalForm(newForm)
  }

  const hospitalRequest = useGetHospitalQuery(id, {
    skip: false,
  })
  const [editHospital, result] = useEditHospitalMutation()
  const { isLoading, isError, isSuccess, error } = result

  useEffect(() => {
    if (hospitalRequest?.data?.data) {
      const { hospitals } = hospitalRequest.data.data
      const hospitalForm = setHospital(hospitals[0])
      setHospitalForm(hospitalForm)
    }
  }, [hospitalRequest?.data?.data])

  useEffect(() => {
    if (isError) {
      let errorMessage = 'An error has occurred, please try again'
      if (error.data?.error) {
        errorMessage = error.data?.error
      }
      setBanner({
        text: errorMessage,
        type: 'error',
      })
    }

    if (isSuccess) {
      setBanner({
        text: 'The hospital has been successfully edited',
        type: 'success',
      })
      setHospitalForm(EDIT_FORM_DATA)
    }
  }, [isError, isSuccess, error])

  const onSubmit = async () => {
    setBanner(undefined)
    let canProceed = true
    Object.entries(hospitalForm)
      .reverse()
      .forEach((entry) => {
        const name = entry[0]
        const value = entry[1].value
        if (!value) {
          onChange(name, value)
          canProceed = false
        }
      })

    if (canProceed) {
      const body = {
        name: capitalizeText(hospitalForm['name'].value),
        code: capitalizeText(hospitalForm['code'].value),
        id,
      }
      await editHospital(body)
    }
  }

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <BackButton
          text="See hospitals"
          onClick={() => {
            setHospitalForm(undefined)
            history.push(ROUTES.HOSPITALS)
          }}
        />
        <Heading text="Edit the hospital" noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        {banner?.type !== 'success' && hospitalForm && (
          <div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...hospitalForm.name} onChange={onChange} />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...hospitalForm.code} onChange={onChange} />
            </div>
            <br />
            <Button
              text="Update hospital"
              onClick={onSubmit}
              loading={isLoading}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

const Edit = () => {
  const params = useParams()
  return <>{params?.id && <Update id={params.id} />}</>
}

Update.propTypes = {
  id: PropTypes.string.isRequired,
  roles: PropTypes.array,
}

export { Edit }
