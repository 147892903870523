export const PATIENT_FORM_DATA = {
  hospital: {
    label: 'Hospital',
    name: 'hospital',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  ctrId: {
    label: "Patient's CTR ID",
    name: 'ctrId',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  filingConsentDate: {
    label: "Date filing form with patient's consent",
    name: 'filingConsentDate',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  dateOfDischarge: {
    label: 'Date of discharge',
    name: 'dateOfDischarge',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  researchAssistantName: {
    label: 'Name of research assistant who completed paper form',
    name: 'researchAssistantName',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  participantName: {
    label: 'Participant Name (First Name, Family Name)',
    name: 'participantName',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  region: {
    label: 'Region',
    name: 'region',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  town: {
    label: 'Town',
    name: 'town',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  village: {
    label: 'Village',
    name: 'village',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  quarter: {
    label: 'Quarter',
    name: 'quarter',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  nearestLandmark: {
    label: 'Nearest Landmark',
    name: 'nearestLandmark',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  detailedDescription: {
    label: 'Detailed Description',
    name: 'detailedDescription',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  participantHasMobileNumber: {
    label: 'Does participant have mobile number',
    name: 'participantHasMobileNumber',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  contactNumber: {
    label: 'Contact number',
    name: 'contactNumber',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  participantHasAlternateMobilePhoneNumber: {
    label: 'Does participant have alternate mobile number #1',
    name: 'participantHasAlternateMobilePhoneNumber',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeName: {
    label: 'Who does this alternate number #1 belong to (Full name)',
    name: 'alternativeName',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeContactNumber: {
    label: 'Participant alternate mobile phone number #1',
    name: 'alternativeContactNumber',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  alternativeRelationship: {
    label:
      'What is the relationship to owner of alternate mobile phone number #1',
    name: 'alternativeRelationship',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativePermission: {
    label: 'Can we discuss the project with them on your behalf? #1',
    name: 'alternativePermission',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  participantHasAlternateMobilePhoneNumber2: {
    label: 'Does participant have alternate mobile number #2',
    name: 'participantHasAlternateMobilePhoneNumber2',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeName2: {
    label: 'Who does this alternate number #2 belong to (Full name)',
    name: 'alternativeName2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeContactNumber2: {
    label: 'Participant alternate mobile phone number #2',
    name: 'alternativeContactNumber2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  alternativeRelationship2: {
    label:
      'What is the relationship to owner of alternate mobile phone number #2',
    name: 'alternativeRelationship2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativePermission2: {
    label: 'Can we discuss the project with them on your behalf? #2',
    name: 'alternativePermission2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  participantHasOtherMobilePhoneNumbers: {
    label: 'Does participant have other mobile numbers',
    name: 'participantHasOtherMobilePhoneNumbers',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  otherContactNumber1: {
    label: 'Alternate mobile phone numbers #3 (if any)',
    name: 'otherContactNumber1',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  otherContactNumber2: {
    label: 'Alternate mobile phone numbers #4 (if any)',
    name: 'otherContactNumber2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  consentToMention: {
    label:
      "If we need to contact you at these alternate numbers, is it ok if the study staff mentions the reason for the call and your involvement in this study? If not, that's ok.",
    name: 'consentToMention',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  howToIdentifyResearcher: {
    label: 'How should study staff identify you (specify):',
    name: 'howToIdentifyResearcher',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  contactAlternativesOk: {
    label: 'Can we discuss the project with them on your behalf?',
    name: 'contactAlternativesOk',
    value: '0',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  preferredLanguage: {
    label: 'Preferred Language',
    name: 'preferredLanguage',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const PERSONAL_DETAILS_FORM_DATA = {
  ctrlId: {
    label: 'ctrlId',
    name: 'ctrlId',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  firstname: {
    label: 'First name',
    name: 'firstname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  surname: {
    label: 'Surname',
    name: 'surname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  contactNumber: {
    label: 'Contact number',
    name: 'contactNumber',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  email: {
    label: 'Email',
    name: 'email',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const ADDRESS_FORM_DATA = {
  region: {
    label: 'Region',
    name: 'region',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  town: {
    label: 'Town',
    name: 'town',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  village: {
    label: 'Village',
    name: 'village',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  quarter: {
    label: 'Quarter',
    name: 'quarter',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  nearestLandmark: {
    label: 'Nearest Landmark',
    name: 'nearestLandmark',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  detailedDescription: {
    label: 'Detailed Description',
    name: 'detailedDescription',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const OTHER_NUMBERS_FORM_DATA = {
  otherNumberContactNumber: {
    label: 'Contact number',
    name: 'otherNumberContactNumber',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  otherNumberName: {
    label: 'Name',
    name: 'otherNumberName',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  otherNumberRelationship: {
    label: 'Relationship',
    name: 'otherNumberRelationship',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  otherNumberPermission: {
    name: 'otherNumberPermission',
    value: false,
    helper: {
      text: '',
      error: false,
    },
  },

  otherNumberContactNumber2: {
    label: 'Contact number',
    name: 'otherNumberContactNumber2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  otherNumberName2: {
    label: 'Name',
    name: 'otherNumberName2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  otherNumberRelationship2: {
    label: 'Relationship',
    name: 'otherNumberRelationship2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  otherNumberPermission2: {
    name: 'otherNumberPermission2',
    value: false,
    helper: {
      text: '',
      error: false,
    },
  },
}

export const ALTERNATIVE_NUMBERS_FORM_DATA = {
  alternativeContactNumber: {
    label: 'Contact number',
    name: 'alternativeContactNumber',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  alternativeName: {
    label: 'Name',
    name: 'alternativeName',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeRelationship: {
    label: 'Relationship',
    name: 'alternativeRelationship',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativePermission: {
    name: 'alternativePermission',
    value: false,
    helper: {
      text: '',
      error: false,
    },
  },
  alternativeContactNumber2: {
    label: 'Contact number',
    name: 'alternativeContactNumber2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeName2: {
    label: 'Name',
    name: 'alternativeName2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativeRelationship2: {
    label: 'Relationship',
    name: 'alternativeRelationship2',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  alternativePermission2: {
    name: 'alternativePermission2',
    value: false,
    helper: {
      text: '',
      error: false,
    },
    required: false,
  },
  canMentionReasons: {
    name: 'canMentionReasons',
    value: true,
    helper: {
      text: '',
      error: false,
    },
    required: false,
  },
  canNotMentionReasons: {
    name: 'canNotMentionReasons',
    value: false,
    helper: {
      text: '',
      error: false,
    },
    required: false,
  },
  howToIdentifyResearcher: {
    label: 'Specify how they should identify themselves',
    name: 'howToIdentifyResearcher',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  preferredLanguage: {
    label: 'Preferred Language',
    name: 'preferredLanguage',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const OTHER_INFORMATION_FORM_DATA = {
  dateOfDischarge: {
    label: 'Date of discharge',
    name: 'dateOfDischarge',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  dateOfOperation: {
    label: 'Date of operation',
    name: 'dateOfOperation',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  dateOfStudy: {
    label: 'Date of study',
    name: 'dateOfStudy',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  hospital: {
    label: 'Hospital',
    name: 'hospital',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  preferredLanguage: {
    label: 'Preferred Language',
    name: 'preferredLanguage',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  dateOfDeath: {
    label: 'Date of death',
    name: 'dateOfDeath',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  recordId: {
    label: 'Record ID',
    name: 'recordId',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'number',
  },
  filingConsentDate: {
    label: 'Filing content date',
    name: 'filingConsentDate',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  redcapEntryDate: {
    label: 'Redcap entry date',
    name: 'redcapEntryDate',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'date',
  },
  redcapSurveyIdentifier: {
    label: 'redcap survey identifier',
    name: 'redcapSurveyIdentifier',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  researchAssistantInitials: {
    label: 'Research assistant initials',
    name: 'researchAssistantInitials',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  surveyTimestamp: {
    label: 'Survey time and date',
    name: 'surveyTimestamp',
    value: '',
    required: false,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'datetime-local',
  },
  isComplete: {
    name: 'isComplete',
    value: false,
    helper: {
      text: '',
      error: false,
    },
    required: false,
  },
}

export const useHospitals = (data) => {
  const hospitals = data.map((hosp) => {
    return {
      text: `${hosp.name} (${hosp.code})`,
      value: hosp.id.toString(),
      code: hosp.code,
    }
  })
  return {
    hospitals,
  }
}

export const useLanguages = (data) => {
  const languages = data.map((language) => {
    return {
      text: language.name,
      value: language.id.toString(),
    }
  })
  return {
    languages,
  }
}

export const REGIONS = [
  {
    text: 'Far North Region',
    value: 'Far North Region',
  },
  {
    text: 'North Region',
    value: 'North Region',
  },
  {
    text: 'Adamawa Region',
    value: 'Adamawa Region',
  },
  {
    text: 'Centre Region',
    value: 'Centre Region',
  },
  {
    text: 'East Region',
    value: 'East Region',
  },
  {
    text: 'West Region',
    value: 'West Region',
  },
  {
    text: 'South Region',
    value: 'South Region',
  },
  {
    text: 'North West Region',
    value: 'North West Region',
  },
  {
    text: 'South West Region',
    value: 'South West Region',
  },
  {
    text: 'Littoral Region',
    value: 'Littoral Region',
  },
]

export const YES_AND_NO_LIST = [
  {
    text: 'Yes',
    value: '1',
  },
  {
    text: 'No',
    value: '0',
  },
]

export const TOWNS = [
  {
    text: 'Town 1',
    value: 'Town 1',
  },
  {
    text: 'Town 2',
    value: 'Town 2',
  },
  {
    text: 'Town 3',
    value: 'Town 3',
  },
]

export const RELATIONSHIPS = [
  {
    text: 'Spouse',
    value: 'Spouse',
  },
  {
    text: 'Family',
    value: 'Family',
  },
  {
    text: 'Friend',
    value: 'Friend',
  },
]

export const TAB = {
  PERSONAL_DETAILS: 'Personal details',
  OTHER_NUMBERS: 'Other numbers',
  ALTERNATIVE_NUMBERS: 'Alternative numbers',
  OTHER_INFORMATION: 'Other information',
  ADDRESS: 'Address',
}

export const TABS = [
  TAB.PERSONAL_DETAILS,
  TAB.ADDRESS,
  TAB.OTHER_NUMBERS,
  TAB.ALTERNATIVE_NUMBERS,
  TAB.OTHER_INFORMATION,
]
