import { useState, useEffect } from 'react'
import moment from 'moment'
import Collapse from '@kunukn/react-collapse'
import { ChevronDown } from 'react-feather'
import cx from 'classnames'

import { capitalizeText, describeData } from '../../helpers'
import { useHospitals } from '../patients/helper'
import { useGetHospitalsQuery } from '../../services/redux/features/hospital/facade'
import { useGetDashboardReportMutation } from '../../services/redux/features/dashboard/facade'

import {
  Button,
  Heading,
  Layout,
  TextField,
  Dropdown,
  Typography,
  TableRow,
  Skeletons,
} from '../../components'

import styles from '../home/Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const Reports = () => {
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [hospital, setHospital] = useState('')
  const [activeTab, setActiveTab] = useState('patients')

  const [getReports, result] = useGetDashboardReportMutation()
  const hospitalsRequest = useGetHospitalsQuery()

  const onGetReports = () => {
    const payload = {
      startDate: moment(startDate).format(DATE_FORMAT),
      endDate: moment(endDate).format(DATE_FORMAT),
      ...(!!hospital && { suffix: `&hids=${hospital}` }),
    }
    getReports(payload)
  }

  useEffect(() => {
    const today = new Date(Date.now())
    setEndDate(moment(today).format(DATE_FORMAT))

    const oneMonth = today.setDate(today.getDate() - 90)
    setStartDate(moment(new Date(oneMonth)).format(DATE_FORMAT))

    onGetReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { hospitals } = useHospitals(
    hospitalsRequest.data?.data?.hospitals || []
  )

  const hospitalList = [
    {
      text: 'All hospitals',
      value: '',
    },
    ...hospitals,
  ]

  const data = result?.data?.data?.dashboard

  const patientsToCalls = data?.patientsToCalls
  const completedSurveys = data?.completedSurveys
  const incompletedSurveys = data?.incompletedSurveys

  const isSuccess = result.isSuccess
  const isLoading = result.isLoading
  const isFetching = result.isFetching

  const arrowProps = (rotate) =>
    cx(styles['table-wrapper--icon'], {
      [styles['table-wrapper--icon--rotate']]: rotate,
    })

  return (
    <div data-testid="home" className={styles['home']}>
      <Layout>
        <div className={styles['home--content']}>
          <Heading text="Reports" />
          <div className={styles['dashboard--row']}>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div className={styles['table-wrapper--header--input-wrapper']}>
                <TextField
                  onChange={(_, text) => setStartDate(text)}
                  placeholder="Start Date"
                  type="date"
                  required
                  value={startDate}
                  label="Start date"
                />
              </div>
              <div className={styles['table-wrapper--header--input-wrapper']}>
                <TextField
                  onChange={(_, text) => setEndDate(text)}
                  placeholder="End Date"
                  type="date"
                  required
                  value={endDate}
                  label="End date"
                />
              </div>
              <div
                className={styles['table-wrapper--header--dropdown-wrapper']}
              >
                <Dropdown
                  list={hospitalList.map((hsp) => hsp.value)}
                  onSelect={(_, text) => {
                    setHospital(text)
                  }}
                  displayList={hospitalList}
                  name="hospital"
                  withBorder
                  placeholder="Hospital"
                  value={hospital}
                  label="Hospital"
                />
              </div>
            </div>
            <div className={styles['dashboard--button-wrapper']}>
              <Button text="Submit" size="small" onClick={onGetReports} />
            </div>
          </div>
          <br />
          <br />
          <br />
          {(isLoading || isFetching) && (
            <div>
              <Skeletons type="table" />
              <Skeletons type="table" />
            </div>
          )}
          {isSuccess && (
            <div>
              <div
                className={cx(
                  styles['table-wrapper--row'],
                  styles['table-wrapper--row--clickable'],
                  styles[
                    `table-wrapper--row--clickable--${
                      activeTab === 'patients' ? 'open' : ''
                    }`
                  ]
                )}
                onClick={() =>
                  setActiveTab(activeTab === 'patients' ? '' : 'patients')
                }
              >
                <Typography
                  children="Patients to Call"
                  className={styles['table-wrapper--title']}
                />
                <ChevronDown
                  size={20}
                  className={arrowProps(activeTab === 'patients')}
                />
              </div>
              <Collapse isOpen={activeTab === 'patients'}>
                {patientsToCalls?.map((patientsToCall, index) => {
                  const values = Object.values(patientsToCall)
                  return (
                    <div
                      style={{
                        paddingLeft: 32,
                      }}
                      key={index}
                    >
                      <Typography
                        children={`${patientsToCall.name} | ${patientsToCall.code}`}
                        className={styles['table-wrapper--subtitle']}
                      />
                      {values?.map((value, i) => {
                        const objkey = Object.keys(patientsToCall)[i]

                        if (typeof value !== 'object' || value === null) {
                          return <div key={i}></div>
                        }

                        return (
                          <div key={i}>
                            <Typography
                              children={objkey}
                              className={
                                styles['table-wrapper--subtitle--small']
                              }
                              uppercase
                            />
                            {Object.values(value).map((valueItem, y) => {
                              const res = [
                                {
                                  text: '',
                                  alignment: 'left',
                                  size: 'extra-small',
                                },
                                {
                                  text: capitalizeText(Object.keys(value)[y]),
                                  alignment: 'left',
                                  size: 'large',
                                },
                                {
                                  text: describeData(valueItem),
                                  alignment: 'right',
                                  size: 'large',
                                },
                              ]

                              return <TableRow key={y} data={res} />
                            })}
                            <br />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </Collapse>
            </div>
          )}
          {isSuccess && (
            <div>
              <div
                className={cx(
                  styles['table-wrapper--row'],
                  styles['table-wrapper--row--clickable'],
                  styles[
                    `table-wrapper--row--clickable--${
                      activeTab === 'completed-surveys' ? 'open' : ''
                    }`
                  ]
                )}
                onClick={() =>
                  setActiveTab(
                    activeTab === 'completed-surveys' ? '' : 'completed-surveys'
                  )
                }
              >
                <Typography
                  children="Completed Surveys"
                  className={styles['table-wrapper--title']}
                />
                <ChevronDown
                  size={20}
                  className={arrowProps(activeTab === 'completed-surveys')}
                />
              </div>
              <Collapse isOpen={activeTab === 'completed-surveys'}>
                {completedSurveys?.map((completedSurvey, index) => {
                  const values = Object.values(completedSurvey)
                  return (
                    <div
                      style={{
                        paddingLeft: 32,
                      }}
                      key={index}
                    >
                      <Typography
                        children={`${completedSurvey.name} | ${completedSurvey.code}`}
                        className={styles['table-wrapper--subtitle']}
                      />
                      {values?.map((value, i) => {
                        const objkey = Object.keys(completedSurvey)[i]

                        if (typeof value !== 'object' || value === null) {
                          return <div key={i}></div>
                        }

                        return (
                          <div key={i}>
                            <Typography
                              children={objkey}
                              className={
                                styles['table-wrapper--subtitle--small']
                              }
                              uppercase
                            />
                            {Object.values(value).map((valueItem, y) => {
                              const res = [
                                {
                                  text: '',
                                  alignment: 'left',
                                  size: 'extra-small',
                                },
                                {
                                  text: capitalizeText(Object.keys(value)[y]),
                                  alignment: 'left',
                                  size: 'large',
                                },
                                {
                                  text: describeData(valueItem),
                                  alignment: 'right',
                                  size: 'large',
                                },
                              ]

                              return <TableRow key={y} data={res} />
                            })}
                            <br />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </Collapse>
            </div>
          )}
          {isSuccess && (
            <div>
              <div
                className={cx(
                  styles['table-wrapper--row'],
                  styles['table-wrapper--row--clickable'],
                  styles[
                    `table-wrapper--row--clickable--${
                      activeTab === 'incompleted-surveys' ? 'open' : ''
                    }`
                  ]
                )}
                onClick={() =>
                  setActiveTab(
                    activeTab === 'incompleted-surveys'
                      ? ''
                      : 'incompleted-surveys'
                  )
                }
              >
                <Typography
                  children="Incompleted Surveys"
                  className={styles['table-wrapper--title']}
                />
                <ChevronDown
                  size={20}
                  className={arrowProps(activeTab === 'incompleted-surveys')}
                />
              </div>
              <Collapse isOpen={activeTab === 'incompleted-surveys'}>
                {incompletedSurveys?.map((incompletedSurvey, index) => {
                  const values = Object.values(incompletedSurvey)
                  return (
                    <div
                      style={{
                        paddingLeft: 32,
                      }}
                      key={index}
                    >
                      <Typography
                        children={`${incompletedSurvey.name} | ${incompletedSurvey.code}`}
                        className={styles['table-wrapper--subtitle']}
                      />
                      {values?.map((value, i) => {
                        const objkey = Object.keys(incompletedSurvey)[i]

                        if (typeof value !== 'object' || value === null) {
                          return <div key={i}></div>
                        }

                        return (
                          <div key={i}>
                            <Typography
                              children={objkey}
                              className={
                                styles['table-wrapper--subtitle--small']
                              }
                              uppercase
                            />
                            {Object.values(value).map((valueItem, y) => {
                              const res = [
                                {
                                  text: '',
                                  alignment: 'left',
                                  size: 'extra-small',
                                },
                                {
                                  text: capitalizeText(Object.keys(value)[y]),
                                  alignment: 'left',
                                  size: 'large',
                                },
                                {
                                  text: describeData(valueItem),
                                  alignment: 'right',
                                  size: 'large',
                                },
                              ]

                              return <TableRow key={y} data={res} />
                            })}
                            <br />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </Collapse>
            </div>
          )}
        </div>
      </Layout>
    </div>
  )
}

export { Reports }
