export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },
  {
    text: 'Name',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Code',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Actions',
    alignment: 'right',
    size: 'small',
  },
]

export const filters = [
  {
    text: 'Any field',
    value: 'any',
  },
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Code',
    value: 'code',
  },
]

export const useHospitals = (hospitals) => {
  const transformedData = hospitals.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.name,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.code,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    hospitals: transformedData,
    headers,
    filters,
  }
}
