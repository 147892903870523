import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { setUserHospitals } from '../../../../services/redux/features/user/slide'
import { Tag } from '../../../../components'
import { cleanData } from '../../../../helpers'

import styles from '../../../home/Home.module.scss'

const Hospitals = ({ userHospitals, hospitals, dropdownValue }) => {
  const dispatch = useDispatch()
  const [list, setList] = useState(userHospitals)

  useEffect(() => {
    if (dropdownValue) {
      const item = hospitals.find(
        (el) => Number(el.value) === Number(dropdownValue.value)
      )

      let canAddItem = true
      list.forEach((listEl) => {
        if (listEl?.value === item?.value) {
          canAddItem = false
          return
        }
      })

      if (canAddItem) {
        const newList = [...list, item]
        handleList(newList)
        setList(newList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue])

  useEffect(() => {
    handleList(userHospitals)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteItem = (value) => {
    let newList = []
    list.forEach((listEl) => {
      if (listEl?.value !== value) {
        newList.push(listEl)
      }
    })
    handleList(cleanData(newList))
    setList(cleanData(newList))
  }

  const handleList = (items) => {
    const ids = cleanData(items)?.map((item) => item.value) || []
    dispatch(setUserHospitals(ids))
  }

  const cleanList = cleanData(list)

  return (
    <div className={styles['dashboard--tags-wrapper']}>
      {cleanList?.map((item) => (
        <Tag
          text={item.text}
          key={item.value}
          onDelete={() => deleteItem(item.value)}
        />
      ))}
    </div>
  )
}

Hospitals.propTypes = {
  userHospitals: PropTypes.array,
  hospitals: PropTypes.array,
  dropdownValue: PropTypes.object,
}

export { Hospitals }
