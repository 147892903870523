import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { ChevronDown } from 'react-feather'

import { csvToArray } from '../../helpers'

import { Typography } from '../typography'
import { Caption } from '../caption'
import { Actions } from '../actions'

import styles from './TextField.module.scss'

const COUNTRIES = {
  CAMEROON: '(+237) Cameroon',
  SOUTH_AFRICA: '(+27) South Africa',
}

const CODES = [
  {
    value: '+237',
    text: COUNTRIES.CAMEROON,
  },
  {
    value: '+27',
    text: COUNTRIES.SOUTH_AFRICA,
  },
]

const TextField = ({
  label,
  placeholder,
  noBorder,
  value,
  required,
  onChange,
  helper,
  type,
  name,
  onPressEnter,
  className,
  size,
  accept,
  isTextArea,
  isPhoneField,
  readOnly,
  isSearchField,
  prefix: fieldPrefix,
}) => {
  const [focused, setFocused] = useState(false)
  const [show, setShow] = useState(false)
  const [prefix, setPrefix] = useState(CODES[0].value)

  const textfieldProps = cx(
    styles.textfield,
    {
      [styles['textfield--with-error']]: helper?.error,
      [styles['textfield--no-border']]: noBorder,
      [styles['textfield--large']]: size === 'large',
      [styles['textfield--is-phone-field']]: isPhoneField || fieldPrefix,
    },
    className
  )
  const helperProps = cx(styles['textfield--helper'], {
    [styles['textfield--helper--error']]: helper?.error,
    [styles['textfield--helper--with-margin-left']]: noBorder,
  })

  const removeCountryCode = (num) => {
    if (num.substring(0, 3) === '+27') {
      return num.substring(3, num.length)
    } else {
      return num.substring(4, num.length)
    }
  }

  const onFileChange = (e) => {
    const input = e.target.files[0]
    const fileReader = new FileReader()

    fileReader.onload = async (e) => {
      const file = await e.target
      if (accept === '.csv') {
        const text = e.target.result
        const data = csvToArray(text)
        onChange(name, JSON.stringify(data), input)
      } else {
        if (file.result) {
          onChange(name, file.result)
        }
      }
    }

    if (accept === '.csv') {
      fileReader.readAsText(input)
    } else {
      fileReader.readAsDataURL(input)
    }
  }

  const onSelectCountry = (text) => {
    const country = CODES.find((c) => c.text === text)
    setPrefix(country?.value)
    const phone = value?.toString().substring(4, value.length)
    if (phone) {
      onChange(name, `${country?.value}${phone}`)
    }
  }

  const setValue = () => {
    let val
    CODES.forEach((code) => {
      const refactoredValue = value && `+${value.replace('+', '')}`
      if (refactoredValue?.includes(code.value)) {
        val = removeCountryCode(refactoredValue)
      }
    })

    return val
  }

  useEffect(() => {
    if (value && isPhoneField) {
      CODES.forEach((code) => {
        const refactoredValue = value && `+${value.replace('+', '')}`
        if (refactoredValue?.includes(code.value)) {
          setPrefix(code.value)
        }
      })
    }
  }, [value, isPhoneField])

  return (
    <>
      {Boolean(label) && <Caption text={label} />}
      <div className={textfieldProps}>
        {!value && focused && !isSearchField && (
          <Typography
            children={required ? 'required' : 'optional'}
            className={styles['textfield--required']}
          />
        )}
        {isPhoneField && (
          <div
            className={styles['textfield--prefix']}
            onClick={() => {
              if (!readOnly) {
                setShow(!show)
              }
            }}
          >
            <Typography
              children={prefix.toString()}
              className={styles['textfield--prefix--text']}
            />
            <ChevronDown size={14} />
          </div>
        )}
        {fieldPrefix && (
          <div className={styles['textfield--prefix']}>
            <Typography
              children={fieldPrefix.toString()}
              className={styles['textfield--prefix--text']}
            />
          </div>
        )}
        {!isTextArea && (
          <input
            defaultValue={isPhoneField ? setValue() : value}
            placeholder={placeholder}
            onChange={(e) => {
              const num = e.target.value
              if (type === 'file') {
                onFileChange(e)
              } else {
                const val = `${isPhoneField ? prefix : ''}${num}`
                onChange(name, val.toString())
              }
            }}
            type={type}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onPressEnter()
              }
            }}
            accept={accept}
            readOnly={readOnly}
          />
        )}
        {isTextArea && (
          <textarea
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e) => onChange(name, e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onPressEnter()
              }
            }}
          />
        )}
        {Boolean(helper?.text) && (
          <Typography children={helper.text} className={helperProps} />
        )}
        <Actions
          show={show}
          onClose={() => setShow(false)}
          actions={CODES.map((c) => c.text)}
          onClick={onSelectCountry}
        />
      </div>
    </>
  )
}

TextField.defaultProps = {
  value: '',
  placeholder: '',
  noBorder: false,
  required: false,
  readOnly: false,
  onChange: () => {},
  onPressEnter: () => {},
  type: 'text',
  size: 'small',
  isTextArea: false,
  isSearchField: false,
  prefix: '',
}

TextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noBorder: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  helper: PropTypes.shape({
    text: PropTypes.string,
    error: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onPressEnter: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  accept: PropTypes.string,
  isTextArea: PropTypes.bool,
  isPhoneField: PropTypes.bool,
  isSearchField: PropTypes.bool,
  prefix: PropTypes.string,
}

export { TextField }
