import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ChevronDown, Check } from 'react-feather'
import EscapeOutside from 'react-escape-outside'

import { Caption } from '../caption'
import { Typography } from '../typography'

import styles from './Dropdown.module.scss'

const Dropdown = ({
  placeholder,
  list,
  withShadows,
  type,
  width,
  readOnly,
  withBorder,
  name,
  value,
  onSelect,
  size,
  label,
  helper,
  displayList,
  noValue,
  languageSelector,
  multiple,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [text, setText] = useState(false)
  const [selectionList, setSelectionList] = useState([])

  const addOrRemoveStringFromArray = (str) => {
    const index = selectionList.indexOf(str)
    if (index > -1) {
      setSelectionList((prevArray) => prevArray.filter((item) => item !== str))
    } else {
      setSelectionList((prevArray) => [...prevArray, str])
    }
  }

  useEffect(() => {
    if (!value) {
      setText(placeholder)
    } else {
      if (Array.isArray(value)) {
        setSelectionList(value)
        return
      }
      setText(list.includes(value) ? value : placeholder)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, setText, value, placeholder])

  useEffect(() => {
    if (selectionList) {
      let val = ''
      if (selectionList.length > 0) {
        val = selectionList
      }

      onSelect?.(name, !!val ? val : value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionList])

  const dropdownProps = cx(styles.dropdown, {
    [styles['dropdown--with-shadows']]: withShadows,
    [styles[`dropdown--${type}`]]: true,
    [styles[`dropdown--full-width`]]: !width,
    [styles[`dropdown--disabled`]]: readOnly,
    [styles[`dropdown--with-border`]]: withBorder,
    [styles['dropdown--large']]: size === 'large',
    [styles['dropdown--with-error']]: helper?.error,
    [styles['dropdown--language-selector']]: languageSelector,
  })
  const caretProps = cx(styles['dropdown--caret'], {
    [styles['dropdown--caret--open']]: dropdownOpen,
    [styles['dropdown--caret--language-selector']]: languageSelector,
  })

  const textProps = cx(styles['dropdown--text'], {
    [styles['dropdown--text--placeholder']]: !value && text === placeholder,
  })

  const listItemProps = (lastItem) =>
    cx(styles['dropdown--list--item'], {
      [styles['dropdown--list--item--no-border']]: lastItem,
      [styles['dropdown--list--item--language-selector']]: languageSelector,
    })

  const helperProps = cx(styles['dropdown--helper'], {
    [styles['dropdown--helper--error']]: helper?.error,
  })

  const returnValue = (val) => {
    if (val === placeholder) {
      return val
    }
    return displayList
      ? displayList.find((i) => i.value.toString() === val.toString())?.text
      : val
  }

  return (
    <EscapeOutside
      onEscapeOutside={() => {
        setDropdownOpen(false)
      }}
    >
      <>
        {Boolean(label) && <Caption text={label} />}
        <div
          className={dropdownProps}
          style={{ width }}
          onClick={() =>
            readOnly ? undefined : setDropdownOpen(!dropdownOpen)
          }
        >
          {multiple && (
            <Typography
              children="multiple"
              className={styles['dropdown--multiple']}
            />
          )}
          <Typography
            children={noValue ? '' : returnValue(text) || ''}
            className={textProps}
          />
          <ChevronDown width={16} className={caretProps} />
          {dropdownOpen && (
            <div className={styles['dropdown--list']}>
              {list.map((item, index) => (
                <div
                  className={listItemProps(list.length - 1 === index)}
                  key={index}
                  onClick={() => {
                    setText(item)
                    if (multiple) {
                      addOrRemoveStringFromArray(item)
                    } else {
                      onSelect?.(name, item)
                    }
                  }}
                >
                  <Typography
                    children={returnValue(item) || ''}
                    className={styles['dropdown--text']}
                  />
                  {multiple && selectionList.includes(item) && (
                    <Check size={12} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {Boolean(helper?.text) && (
          <Typography children={helper.text} className={helperProps} />
        )}
      </>
    </EscapeOutside>
  )
}

Dropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  withShadows: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  width: PropTypes.number,
  readOnly: PropTypes.bool,
  withBorder: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  label: PropTypes.string,
  helper: PropTypes.shape({
    text: PropTypes.string,
    error: PropTypes.bool,
  }),
  displayList: PropTypes.array,
  noValue: PropTypes.bool,
  languageSelector: PropTypes.bool,
  multiple: PropTypes.bool,
}

Dropdown.defaultProps = {
  withShadows: false,
  type: 'primary',
  size: 'small',
  noValue: false,
  multiple: false,
}

export { Dropdown }
