export const headers = [
  {
    text: '',
    alignment: 'left',
    size: 'extra-small',
  },
  {
    text: 'Survey',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Hospital',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Status',
    alignment: 'left',
    size: 'large',
  },
  {
    text: 'Actions',
    alignment: 'right',
    size: 'small',
  },
]

export const filters = [
  {
    text: 'Any field',
    value: 'any',
  },
  {
    text: 'Status',
    value: 'status',
  },
]

export const usePatientSurveys = (surveys) => {
  const transformedData = surveys.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.survey?.name,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.hospital?.name,
        alignment: 'left',
        size: 'large',
      },
      {
        text: item.status,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    surveys: transformedData,
    headers,
    filters,
    patient: surveys[0]?.patient?.name,
    patientId: surveys[0]?.patient?.id,
  }
}

export const usePatientsMoreDetails = (patient) => {
  const transformedData = [
    {
      text: patient.firstname,
      title: 'First name',
    },
    {
      text: patient.surname,
      title: 'Surname',
    },
    {
      text: patient.hospital.name,
      title: 'Hospital',
    },
    {
      text: patient.dateOfOperation,
      title: 'Date of operation',
    },
    {
      text: patient.dateOfDischarge,
      title: 'Date of discharge',
    },
  ]
  return {
    patientsMoreDetails: transformedData,
  }
}
