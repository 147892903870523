import React from 'react'
import PropTypes from 'prop-types'

import {
  Users,
  Archive,
  ExternalLink,
  Settings,
  User,
  File,
} from 'react-feather'

import { Typography } from '../typography'
import styles from './Card.module.scss'

const Card = ({ text, icon, onClick }) => {
  return (
    <div className={styles['card']} onClick={onClick}>
      <Typography className={styles['card--text']}>{text}</Typography>
      {icon === 'patients' && (
        <Users size={24} className={styles['card--icon']} />
      )}
      {icon === 'hospitals' && (
        <Archive size={24} className={styles['card--icon']} />
      )}
      {icon === 'export' && (
        <ExternalLink size={24} className={styles['card--icon']} />
      )}
      {icon === 'settings' && (
        <Settings size={24} className={styles['card--icon']} />
      )}
      {icon === 'interviewers' && (
        <User size={24} className={styles['card--icon']} />
      )}
      {icon === 'reports' && (
        <File size={24} className={styles['card--icon']} />
      )}
    </div>
  )
}

Card.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

Card.defaultProps = {}

export { Card }
