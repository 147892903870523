import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { canOnlySeePatients, userHasFullAcess } from '../../helpers/auth'
import { ROUTES, LS_KEYS } from '../../constants'
import { getItem } from '../../services/session'
import { useGetLanguagesQuery } from '../../services/redux/features/language/facade'

import { Layout, Card } from '../../components'
import { PatientEnrollment } from './patentEnrollment'
import { UserData } from './userData'
import { Users } from './users'
import { OutcomeCalls } from './outcomeCalls'
import { PatientContent } from './patientConsent'
import { PatientIgnoredFields } from './patientIgnoredFields'
import { CompletedSurveys } from './completedSurveys'
import { PatientCallAttempts } from './patientCallAtempts'

import styles from './Home.module.scss'

const Home = () => {
  const history = useHistory()
  const [languages, setLanguges] = useState([])

  const getLanguagesRequest = useGetLanguagesQuery()

  useEffect(() => {
    if (getLanguagesRequest.isSuccess) {
      const langs = []
      getLanguagesRequest.data?.data?.languages?.forEach((lang) => {
        langs.push({
          text: lang.name,
          value: lang.id.toString(),
        })
      })

      setLanguges(langs)
    }
  }, [getLanguagesRequest])

  const onNavigate = (route) => {
    setTimeout(() => {
      history.push(route)
    }, 150)
  }

  const user = getItem(LS_KEYS.AUTH_USER)

  return (
    <div data-testid="home" className={styles['home']}>
      <Layout>
        <div className={styles['home--content']}>
          <div className={styles['cards-wrapper']}>
            <Card
              text="Patients"
              icon="patients"
              onClick={() => onNavigate(ROUTES.PATIENTS)}
            />
            {!canOnlySeePatients() && (
              <>
                <Card
                  text="Hospitals"
                  icon="hospitals"
                  onClick={() => onNavigate(ROUTES.HOSPITALS)}
                />
                <Card
                  text="Interviewers"
                  icon="interviewers"
                  onClick={() => onNavigate(ROUTES.INTERVIEWERS)}
                />
                {(userHasFullAcess() || user?.isSuperUser) && (
                  <Card
                    text="Export survey"
                    icon="export"
                    onClick={() => onNavigate(ROUTES.EXPORT_SURVEYS)}
                  />
                )}
                <Card
                  text="Settings"
                  icon="settings"
                  onClick={() => onNavigate(ROUTES.SETTINGS)}
                />
                <Card
                  text="Reports"
                  icon="reports"
                  onClick={() => onNavigate(ROUTES.REPORTS)}
                />
              </>
            )}
          </div>
          {!canOnlySeePatients() && languages.length > 0 && (
            <>
              <UserData />
              <br />
              <br />
              <CompletedSurveys languages={languages} />
              <br />
              <br />
              <br />
              <PatientEnrollment />
              <br />
              <br />
              <OutcomeCalls languages={languages} />
              <br />
              <br />
              <PatientContent languages={languages} />
              <br />
              <br />
              <br />
              <PatientIgnoredFields languages={languages} />
              <br />
              <br />
              <PatientCallAttempts languages={languages} />
              <br />
              <Users />
            </>
          )}
        </div>
      </Layout>
    </div>
  )
}

export { Home }
