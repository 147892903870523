import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { ROUTES } from '../../constants'
import { useForgotPasswordMutation } from '../../services/redux/features/user/facade'
import { Banner, Button, Container, Panel, TextField, Typography } from '../../components'

import styles from '../signIn/SignIn.module.scss'

const FORM_DATA = {
  email: {
    name: 'email',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: 'Email address',
  },
}

const ForgotPassword = () => {
  const history = useHistory()
  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    const newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${name} field is required` : '',
          error: isRequired,
        },
      },
    }
    setForm(newForm)
  }

  const [forgotPassword, result] = useForgotPasswordMutation()
  const { isLoading, isError, isSuccess, error } = result

  useEffect(() => {
    if (isError || error) {
      setBanner({
        text: error.data.error,
        type: 'error',
      })
    }

    if (isSuccess && form) {
      setBanner({
        text: `The reset link has been sent to your email ${form['email'].value}`,
        type: 'success',
      })
      setForm(FORM_DATA)
    }
  }, [isError, isSuccess, error, form])

  const onSubmit = async () => {
    setBanner(undefined)
    const body = {
      email: form['email'].value.trim(),
    }
    await forgotPassword(body)
  }

  const onGoToLogIn = () => {
    history.push(ROUTES.SIGN_IN)
  }

  const canSubmitForm = form['email'].value

  return (
    <Container>
      <div className={styles['sign-in']}>
        <div className={cx(styles['sign-in__column'], styles['sign-in__column__large'])}>
          <img src="/dsine-logo.png" alt="logo" className={styles['sign-in--logo']} />
        </div>
        <div className={cx(styles['sign-in__column'], styles['sign-in__column__small'])}>
          <Typography children="Reset your account" className={styles['sign-in--form-title']} />
          {banner && <Banner text={banner?.text} type={banner?.type} />}
          {banner?.type !== 'success' && (
            <Panel noPadding>
              <div
                className={cx(
                  styles['sign-in--textfield-wrapper'],
                  styles['sign-in--textfield-wrapper__with-border']
                )}
              >
                <TextField noBorder {...form.email} onChange={onChange} onPressEnter={onSubmit} />
              </div>
              <Button
                text="Send recovery link"
                onClick={onSubmit}
                square
                disabled={!canSubmitForm}
                loading={isLoading}
              />
            </Panel>
          )}
          {!isLoading && banner?.type !== 'success' && (
            <div className={styles['sign-in--link--wrapper']} onClick={onGoToLogIn}>
              <Typography children="Log in" className={styles['sign-in--link']} />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export { ForgotPassword }
