import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { ACTIONS } from '../../../constants'
import { useUsers } from './hook'
import {
  useGetUsersQuery,
  useDeleteUserMutation,
} from '../../../services/redux/features/user/facade'
import { ROUTES } from '../../../constants'

import {
  Button,
  Heading,
  Layout,
  Pagination,
  Table,
  TableHeader,
  TableRow,
  Skeletons,
  Modal,
  Delete,
  Details,
} from '../../../components'

import styles from '../../home/Home.module.scss'
import { attachRouterParams } from '../../../helpers'

const List = () => {
  const [banner, setBanner] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDetails, setShowDetails] = useState(false)
  const [userDetails, setUserDetails] = useState(undefined)

  const [deleteUser, result] = useDeleteUserMutation()
  const { isSuccess, data, isLoading, isFetching } =
    useGetUsersQuery(selectedPage)

  const history = useHistory()

  useEffect(() => {
    if (result.isError) {
      setBanner({
        text: 'An error has occurred, please try again',
        type: 'error',
      })
    }

    if (result.isSuccess) {
      setBanner({
        text: 'The interviewer has been successfully deleted',
        type: 'success',
      })
    }
  }, [result])

  const { users, headers } = useUsers(data?.data?.users || [])
  const ids = data?.data?.users.map((user) => user.id) || []

  const onStartDeletion = async (id) => {
    const users = await data?.data?.users
    const user_to_delete = users.find((user) => user.id === id)
    setSelectedUser(user_to_delete)
  }

  const onEditUser = async (id) => {
    history.push(attachRouterParams(ROUTES.EDIT_INTERVIEWERS, { id }))
  }

  const onCancelDeletion = () => {
    setSelectedUser(undefined)
    setBanner(undefined)
  }

  const onDeleteUser = async () => {
    if (selectedUser) {
      await deleteUser(selectedUser.id)
    }
  }

  const returnHospitals = (hospitals) => {
    let text = ''

    if (hospitals.length === 0) {
      return text
    }

    hospitals.forEach((element, index) => {
      text = text + `${index !== 0 ? ', ' : ''}${element.name}`
    })

    return text
  }

  const onViewMore = async (id) => {
    const users = await data?.data?.users
    const item = users.find((user) => user.id === id)
    let details = [
      {
        title: 'extended user details',
        data: [
          {
            text: item?.firstname + ' ' + item?.surname,
            title: 'Name',
          },
          {
            text: item?.contactNumber,
            title: 'Phone number',
          },
          {
            text: item?.email,
            title: 'Email address',
          },
          {
            text: returnHospitals(item.hospitals),
            title: 'Hospitals',
          },
        ],
      },
    ]

    setUserDetails(details)
  }

  const loading = isLoading || isFetching

  return (
    <Layout>
      <Heading text="Interviewer management" />
      <div
        className={cx(
          styles['dashboard--row'],
          styles['dashboard--row--with-margin']
        )}
      >
        <div />
        {/* <Search filters={filters} /> */}
        <div
          className={cx(
            styles['dashboard--row'],
            styles['dashboard--row--align-right']
          )}
        >
          <div className={styles['dashboard--button-wrapper']}>
            <Button
              text="New Interviewer"
              size="small"
              onClick={() => {
                history.push(ROUTES.CREATE_INTERVIEWERS)
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['dashboard--row']}>
        <Table>
          <TableHeader data={headers} />
          {loading && <Skeletons type="table" />}
          {isSuccess && users && !loading && (
            <>
              {users.map((data, index) => {
                return (
                  <TableRow
                    data={data}
                    key={index}
                    onAction={(action) => {
                      if (action === ACTIONS.DELETE) {
                        onStartDeletion(ids[index])
                      } else if (action === ACTIONS.VIEW) {
                        onViewMore(ids[index])
                        setShowDetails(true)
                      } else if (action === ACTIONS.ATTACH_HOSPITALS) {
                        history.push(
                          attachRouterParams(ROUTES.ATTACH_HOSPITALS, {
                            id: ids[index],
                          })
                        )
                      } else {
                        onEditUser(ids[index])
                      }
                    }}
                    actions={[
                      ACTIONS.VIEW,
                      ACTIONS.ATTACH_HOSPITALS,
                      ACTIONS.EDIT,
                      ACTIONS.DELETE,
                    ]}
                  />
                )
              })}
            </>
          )}
        </Table>
      </div>
      <br />
      {isSuccess &&
        !loading &&
        data?.data?.pagination &&
        data?.data?.users.length > 0 && (
          <Pagination
            onClick={(page) => setSelectedPage(page)}
            currentPage={data?.data?.pagination?.currentPage}
            pages={data?.data?.pagination?.totalRecords}
          />
        )}
      {selectedUser && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeleteUser}
            title="Delete the interviewer"
            onCancel={onCancelDeletion}
            banner={banner}
            loading={result.isLoading}
          />
        </Modal>
      )}
      {showDetails && (
        <Modal onClose={() => setShowDetails(false)} closeOnClickOutside>
          <Details
            title="More details on the user"
            onCancel={() => setShowDetails(false)}
            list={userDetails}
          />
        </Modal>
      )}
    </Layout>
  )
}

export { List }
