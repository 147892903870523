import { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useGetPatientIgnoredFieldsConsentMutation } from '../../../services/redux/features/dashboard/facade'
import { intToString } from '../../../helpers'

import {
  Typography,
  TableRow,
  Skeletons,
  Dropdown,
  TextField,
} from '../../../components'

import styles from './../Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const PatientIgnoredFields = ({ languages }) => {
  const selectedLanguage = languages?.[0]?.value
  const [language, setLanguage] = useState(selectedLanguage)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)

  const [getData, result] = useGetPatientIgnoredFieldsConsentMutation()

  const { data, isLoading, isFetching, isSuccess } = result

  useEffect(() => {
    if (languages.length) {
      const today = new Date(Date.now())
      setEndDate(moment(today).format(DATE_FORMAT))

      const oneWeek = today.setDate(today.getDate() - 7)
      setStartDate(moment(new Date(oneWeek)).format(DATE_FORMAT))
    }
  }, [languages])

  const loadData = async () => {
    const languageId =
      (await languages.find(
        (lang) => lang.text.toLowerCase() === language.toLowerCase()
      )?.value) ?? language

    if (languageId) {
      getData({
        languageId,
        startDate,
        endDate,
      })
    }
  }

  useEffect(() => {
    if (startDate || endDate || language) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, language])

  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-wrapper--row']}>
        <Typography
          children="Patient ignored fields"
          className={styles['table-wrapper--title']}
        />
        <div className={styles['dashboard--row']}>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setStartDate(text)}
              placeholder="Start Date"
              type="date"
              required
              value={startDate}
              label="Start date"
            />
          </div>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setEndDate(text)}
              placeholder="End Date"
              type="date"
              required
              value={endDate}
              label="End date"
            />
          </div>
          <div className={styles['table-wrapper--header--dropdown-wrapper']}>
            <Dropdown
              list={languages.map((lang) => lang.value)}
              onSelect={(_, text) => {
                setLanguage(text)
              }}
              displayList={languages}
              name="language"
              withBorder
              placeholder="Language"
              value={language}
              label="Language"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="NUMBER OF PATIENTS"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={intToString(
                data?.data?.ignoredFields?.ignoredFields?.length
              )}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            {data?.data?.ignoredFields?.ignoredFields?.map((item, index) => {
              return (
                <div key={index}>
                  <Typography
                    children={`Patient's name: ${item.patientName}`}
                    className={styles['table-wrapper--subtitle']}
                  />
                  {item.interviews?.map((subItem, i) => {
                    const ignoredFields = subItem.ignoredFields

                    const isArrayEmpty =
                      !ignoredFields.length ||
                      (ignoredFields.length &&
                        Object.keys(ignoredFields[0]).length === 0)

                    let fields = [
                      {
                        text: '',
                        alignment: 'left',
                        size: 'extra-small',
                      },
                    ]

                    ignoredFields?.forEach((field) => {
                      fields.push({
                        text: `${Object.values(field)[0]}: ${
                          Object.values(field)[1]
                        }`,
                        alignment: 'left',
                        size: 'large',
                      })
                    })

                    fields.push({
                      text: `Total: ${ignoredFields.length}`,
                      alignment: 'right',
                      size: 'large',
                    })

                    return (
                      <div key={i}>
                        <Typography
                          children={subItem.name}
                          className={styles['table-wrapper--subtitle--small']}
                        />
                        {!isArrayEmpty && (
                          <>
                            <div>
                              <TableRow data={fields} />
                              <br />
                            </div>
                          </>
                        )}
                      </div>
                    )
                  })}
                  <br />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="NUMBER OF PATIENTS"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children="0"
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Skeletons type="table" />
            <Skeletons type="table" />
          </div>
        </div>
      )}
    </div>
  )
}

PatientIgnoredFields.defaultProps = {
  languages: [],
}

PatientIgnoredFields.propTypes = {
  languages: PropTypes.array,
}

export { PatientIgnoredFields }
