import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { MoreHorizontal } from 'react-feather'

import { Actions } from '../../actions'
import { Typography } from '../../typography'

import styles from './TableRow.module.scss'
import tableStyles from '../Table.module.scss'

const TableRowItem = ({
  text,
  alignment,
  size,
  isFirstCell,
  isLastCell,
  onClickMore,
  showMoreIcon,
}) => {
  const tableRowItemProps = cx(tableStyles['table--row--item'], {
    [tableStyles[`table--row--item--${alignment}`]]: true,
    [tableStyles[`table--row--item--${size}`]]: true,
  })
  return (
    <div className={tableRowItemProps}>
      {isFirstCell && <></>}
      {!(isFirstCell && isLastCell) && Boolean(text) && (
        <Typography
          className={tableStyles['table--row--item--text']}
          children={text}
        />
      )}
      {isLastCell && showMoreIcon && (
        <MoreHorizontal
          className={styles['table-row--more-icon']}
          size={22}
          onClick={onClickMore}
        />
      )}
    </div>
  )
}

const TableRow = ({ data, onAction, actions }) => {
  const [showActions, setShowActions] = useState(false)

  return (
    <div className={styles['table-row']}>
      {data?.map((item, index) => {
        const { alignment, text, size } = item
        return (
          <TableRowItem
            key={index}
            text={text}
            alignment={alignment}
            size={size}
            isFirstCell={index === 0}
            isLastCell={data.length - 1 === index}
            showMoreIcon={actions?.length > 0}
            onClickMore={() => setShowActions(true)}
          />
        )
      })}
      {actions?.length > 0 && (
        <div className={styles['table-row__actions-wrapper']}>
          <Actions
            actions={actions}
            show={showActions}
            onClose={() => setShowActions(false)}
            onClick={onAction}
          />
        </div>
      )}
    </div>
  )
}

TableRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      alignment: PropTypes.oneOf(['left', 'center', 'right']),
      size: PropTypes.oneOf([
        'extra-small',
        'small',
        'medium',
        'large',
        'extra-large',
      ]),
    })
  ),
  actions: PropTypes.array,
  onAction: PropTypes.func,
}

TableRowItem.propTypes = {
  text: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'large',
    'extra-large',
  ]),
  isFirstCell: PropTypes.bool,
  isLastCell: PropTypes.bool,
  onClickMore: PropTypes.func.isRequired,
  showMoreIcon: PropTypes.bool,
}

TableRow.defaultProps = {}

export { TableRow }
