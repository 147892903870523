import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { FORM_DATA } from './data'
import { ROUTES, LS_KEYS } from '../../../constants'
import { getItem } from '../../../services/session'

import {
  BackButton,
  Banner,
  Button,
  Heading,
  Layout,
  TextField,
  Typography,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const BASE_URL = process.env.REACT_APP_BASE_URL
let UPLOADED_FILE

const Import = () => {
  const { t, i18n } = useTranslation('patients')
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()
  const [canSubmit, setCanSubmit] = useState(false)

  const onChange = (name, value, csvFile) => {
    setBanner(undefined)
    UPLOADED_FILE = csvFile
    let fileWithWrongFormat = false

    const isRequired = !value && form[name].required
    const passedAllCheck = true

    const newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text:
            !passedAllCheck || fileWithWrongFormat
              ? t('wrong-structure-text')
              : isRequired
              ? `${form[name].label} is required`
              : '',
          error: isRequired || fileWithWrongFormat || !passedAllCheck,
        },
      },
    }

    if (!fileWithWrongFormat && passedAllCheck) {
      setCanSubmit(true)
    }

    setForm(newForm)
  }

  const upload = async () => {
    setBanner(undefined)
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', UPLOADED_FILE)
    await axios({
      method: 'POST',
      url: `${BASE_URL}/patients/upload-csv`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getItem(LS_KEYS.AUTH_TOKEN),
      },
    })
      .then((response) => {
        const { error, success } = response?.data
        if (error && !success) {
          setBanner({
            text: error.errors[0].error ?? t('error-message'),
            type: 'error',
          })
        } else {
          setBanner({
            text: t('upload-message'),
            type: 'success',
          })
        }
      })
      .catch((err) => {
        setBanner({
          text: t('error-message'),
          type: 'error',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = async () => {
    setBanner(undefined)
    let canProceed = true
    Object.entries(form)
      .reverse()
      .forEach((entry) => {
        const name = entry[0]
        const value = entry[1].value
        if (!value) {
          onChange(name, value)
          canProceed = false
        }
      })

    if (canProceed) {
      upload()
    }
  }

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <BackButton
          text={t('see-patients')}
          onClick={() => {
            history.push(ROUTES.PATIENTS)
          }}
        />
        <Heading text={t('import-patients')} noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        <br />
        {banner?.type !== 'success' && (
          <div>
            {i18n.language?.includes('en') ? (
              <Typography className={styles['dashboard--text']}>
                You can download the file template{' '}
                <a
                  href="#file"
                  onClick={() =>
                    window.open('/dsine-patient-upload-template.csv', '_blank')
                  }
                >
                  here
                </a>
                , populate data and reupload the file
              </Typography>
            ) : (
              <Typography className={styles['dashboard--text']}>
                Télecharger le model du ficher{' '}
                <a
                  href="#file"
                  onClick={() =>
                    window.open('/dsine-patient-upload-template.csv', '_blank')
                  }
                >
                  ici
                </a>
                , remplissez les données et re-importer
              </Typography>
            )}

            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...form.file} onChange={onChange} />
            </div>
            <Button
              text={t('import-patients')}
              onClick={onSubmit}
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export { Import }
