import { useEffect, useState, useRef } from 'react'
import cx from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import {
  useGetPatientSurveysMutation,
  useGetSurveyQuestionsMutation,
  usePostInterviewsMutation,
} from '../../../services/redux/features/patient/facade'
import { useResetSurveyMutation } from '../../../services/redux/features/survey/facade'
import { ACTIONS, ROUTES } from '../../../constants'
import { usePatientSurveys } from './hook'
import { capitalizeText } from '../../../helpers'
import { userHasFullAcess, canOnlySeePatients } from '../../../helpers/auth'

import {
  Banner,
  Button,
  Caption,
  Details,
  Heading,
  Layout,
  Pagination,
  Modal,
  Search,
  Skeletons,
  Table,
  TableHeader,
  TableRow,
  Typography,
  TextField,
  Dropdown,
  ConfirmDialog,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const Surveys = () => {
  const { t, i18n } = useTranslation('surveys')
  const history = useHistory()
  const params = useParams()
  const formRef = useRef()

  const HEADERS = [
    {
      text: '',
      alignment: 'left',
      size: 'extra-small',
    },
    {
      text: t('survey'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: t('hospital'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: t('status'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: 'Actions',
      alignment: 'right',
      size: 'small',
    },
  ]

  const [banner, setBanner] = useState()
  const [form, setForm] = useState({})
  const [selectedSurvery, setSelectedSurvey] = useState(undefined)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDetails, setShowDetails] = useState(false)
  const [patientDetails, setPatientDetails] = useState(undefined)
  const [showConfirmDialog, setShowConfirmDialog] = useState(true)

  const [getPatientSurveys, result] = useGetPatientSurveysMutation()
  const [getSurveyQuestions, getSurveyQuestionsResult] =
    useGetSurveyQuestionsMutation()
  const [postInterviews, postInterviewsResult] = usePostInterviewsMutation()
  const [resetSurvey, resetSurveyResult] = useResetSurveyMutation()

  const { isSuccess, data, isLoading, isFetching } = result

  const loadSurveys = async (id) => {
    await getPatientSurveys({ id, page: selectedPage })
    getSurveyQuestions()
  }

  const cleanAnswers = (answers) => {
    if (answers.length > 1) {
      if (answers[0].value === answers[1].value) {
        return [answers[0]]
      }
    }

    return answers
  }

  const formData = getSurveyQuestionsResult?.data?.data?.formData?.find(
    (formDataItem) =>
      formDataItem.language.languageName
        .toUpperCase()
        .includes(i18n.language.toUpperCase())
  )

  const loadForm = async () => {
    let response = undefined
    const results = await selectedSurvery?.results

    let FORM = {}
    formData?.data?.forEach((formItem) => {
      if (results) {
        const answer = results.find((res) => res.id === formItem.id.toString())

        if (answer) {
          if (formItem.meta?.component !== 'input-checkbox') {
            const patientAnswer = cleanAnswers(answer.patientAnswers)?.[0]
            response = patientAnswer?.value
          } else {
            if (answer.patientAnswers.length > 1) {
              response = answer.patientAnswers.map((ans) => ans.value)
            } else {
              const patientAnswer = cleanAnswers(answer.patientAnswers)?.[0]
              response = patientAnswer?.value
            }
          }
        }
      }

      if (formItem.id === 'q6b') {
        const q6aOptions = formData?.data?.find((item) => item.id === 'q6a')
          ?.meta?.options
        q6aOptions.forEach((option) => {
          const name = `6b-${option.value}`

          let res
          results.forEach((result) => {
            result.patientAnswers.forEach((ans) => {
              if (ans.value.includes(option.value)) {
                res = ans.value
              }
            })
          })

          FORM = {
            ...FORM,
            [name]: {
              label: `${formItem.id} - ${option.value}: ${formItem.question}`,
              name,
              value: res ?? '',
              required: false,
              size: 'large',
              visible: false,
              component: 'input-dropdown',
              options: [
                {
                  text: t('new'),
                  value: `${option.value}: ${t('new')}`,
                },
                {
                  text: t('worse'),
                  value: `${option.value}: ${t('worse')}`,
                },
              ],
            },
          }
        })
      } else {
        FORM = {
          ...FORM,
          [formItem.id.toString()]: {
            label: `${formItem.id} -  ${formItem.question}`,
            name: formItem.id.toString(),
            value: response ?? '',
            required: false,
            helper: {
              text: '',
              error: false,
            },
            placeholder: '',
            size: 'large',
            visible: true,
            component: formItem.meta?.component,
            options: formItem.meta?.options?.map((option) => {
              return {
                text: option.display,
                value: option.value,
              }
            }),
          },
        }
      }
    })

    setForm(FORM)
  }

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    let newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${form[name].label} is required` : '',
          error: isRequired,
        },
      },
    }

    let itemToRemove

    // Handle q6a change
    if (name === 'q6a') {
      const oldValue = form['q6a'].value

      if (Array.isArray(value)) {
        const transformValue = value.map((item) => {
          return `6b-${item}`
        })

        // Get old and current values
        if (Array.isArray(oldValue)) {
          // Check in case of a removal and get the removed item
          if (oldValue.length > value.length) {
            itemToRemove = oldValue.filter(
              (element) => !value.includes(element)
            )[0]
          }
        }

        // Add Respective 6b question
        Object.entries(newForm).forEach((entry) => {
          const values = entry[1]
          newForm = {
            ...newForm,
            ...(transformValue.includes(entry[0]) && {
              [entry[0]]: {
                ...values,
                visible: true,
              },
            }),
          }
        })
      }

      // Handle the uncheck of the last item
      if (!value && Array.isArray(oldValue)) {
        itemToRemove = oldValue[0]
      }
    }

    //
    if (itemToRemove) {
      delete newForm[`6b-${itemToRemove}`]
    }

    setForm(newForm)
  }

  useEffect(() => {
    if (params || selectedPage) {
      const { id } = params
      loadSurveys(id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedPage])

  useEffect(() => {
    if (resetSurveyResult.isError) {
      console.log('resetSurveyResult', resetSurveyResult.error?.data?.error)
      setBanner({
        text: JSON.stringify(resetSurveyResult.error?.data?.error),
        type: 'error',
      })
    }

    if (resetSurveyResult.isSuccess) {
      setBanner({
        text: t('surveys-reset-message'),
        type: 'success',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSurveyResult])

  useEffect(() => {
    if (postInterviewsResult.error) {
      let text = ''
      const errors = postInterviewsResult.error?.data?.error
      if (Array.isArray(errors)) {
        text = errors?.[0]
      } else {
        text = JSON.stringify(errors)
      }
      setBanner({
        text,
        type: 'error',
      })

      formRef?.current.scrollIntoView()
    }

    if (postInterviewsResult.isSuccess) {
      setBanner({
        text: t('interviews-message'),
        type: 'success',
      })
    }

    setShowConfirmDialog(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postInterviewsResult])

  useEffect(() => {
    if (getSurveyQuestionsResult.isSuccess && selectedSurvery) {
      loadForm()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSurveyQuestionsResult.isSuccess, selectedSurvery])

  const { surveys, filters, patient } = usePatientSurveys(
    data?.data?.interviews || []
  )
  const ids = data?.data?.interviews?.map((interview) => interview.id) || []
  const loading = isLoading || isFetching

  const onViewMore = async (item) => {
    let details = []

    const questions = []

    item.results?.forEach((q) => {
      q.patientAnswers.forEach((ans) => {
        questions.push({
          title:
            formData?.data?.find((listItem) => listItem.id === q.id)
              ?.question ?? '',
          text: ans.value,
        })
      })
    })

    details.push({
      title: t('extended-details'),
      data: [
        {
          title: t('captured-by'),
          text: item.user?.name,
        },
        {
          title: t('language'),
          text: item.language?.name,
        },
        {
          title: t('interview-date'),
          text: moment(item.interviewDate).format('MMM Do YY'),
        },
        {
          title: t('hospital'),
          text: item.hospital?.name,
        },
        {
          title: t('status'),
          text: item.status,
        },
      ],
    })

    if (item.patientCallAttempts?.length > 0) {
      const calls = item.patientCallAttempts?.map((call) => {
        const { callDateTime, status, callDuration } = call
        return {
          title: moment(callDateTime).format('MMMM Do YYYY, h:mm:ss a'),
          text: `${capitalizeText(status)}${
            callDuration ? ` - ${t('duration')}: ${callDuration}` : ''
          }`,
        }
      })

      details.push({
        title: t('patient-call-attempts'),
        data: calls,
      })
    }

    if (item.smsResponses?.length > 0) {
      const smses = item.smsResponses?.map((sms) => {
        const { messageSent, status, response, smsDate } = sms
        return {
          title: moment(smsDate).format('MMMM Do YYYY, h:mm:ss a'),
          text: `${capitalizeText(status)}${
            messageSent ? ` - ${t('message-sent')}: ${messageSent}` : ''
          }${response ? ` - ${t('response')}: ${response}` : ''}`,
        }
      })

      details.push({
        title: t('patient-call-attempts'),
        data: smses,
      })
    }

    details.push({
      title: t('interview-results'),
      data: questions,
    })

    setPatientDetails(details)
  }

  const onSubmit = async () => {
    const selected = await selectedSurvery

    const results = Object.entries(form).map((entry, index) => {
      let patientAnswers = []

      if (Array.isArray(entry[1].value)) {
        entry[1].value.forEach((ent, i) => {
          patientAnswers.push({
            id: (index + 1) * (i + 2),
            value: ent,
          })
        })
      } else {
        patientAnswers = [
          {
            id: index,
            value: entry[1].value,
          },
        ]
      }

      return {
        id: entry[0],
        patientAnswers,
      }
    })

    // Handle q6b answers
    const subQuestionsPatientAnswers = results
      .filter(
        (resultItem) =>
          resultItem.id.includes('6b') && resultItem.patientAnswers?.[0]?.value
      )
      .map((item, index) => {
        return {
          id: index + 50000,
          value: item.patientAnswers?.[0]?.value,
        }
      })

    let cleanedResults = results.filter(
      (resultItem) =>
        resultItem.patientAnswers?.[0]?.value && !resultItem.id.includes('6b')
    )
    cleanedResults.push({
      id: 'q6b',
      patientAnswers: subQuestionsPatientAnswers,
    })

    if (selected) {
      const payload = {
        interviewId: selected?.id,
        surveyId: selected?.survey?.id,
        position: 0,
        hospitalId: selected?.hospital?.id,
        patientId: selected?.patient?.id,
        userId: selected?.user?.id,
        results: cleanedResults,
        status: 'COMPLETED',
        interviewDate: moment(selected?.interviewDate).format(DATE_FORMAT),
        startDate: moment(selected?.startDate).format(DATE_FORMAT),
        endDate: moment(selected?.endDate).format(DATE_FORMAT),
        languageId: selected?.language?.id,
      }

      postInterviews(payload)
    }
  }

  return (
    <Layout>
      {!selectedSurvery && (
        <>
          <Heading text={t('patient-surveys')} />
          {patient && (
            <div className={styles['dashboard--row']}>
              <div>
                <Caption text={t('patient-name')} />
                <Typography
                  children={patient}
                  className={cx(
                    styles['dashboard--text'],
                    styles['dashboard--text--large']
                  )}
                />
              </div>
              <br />
              <br />
            </div>
          )}

          <div
            className={cx(
              styles['dashboard--row'],
              styles['dashboard--row--with-margin']
            )}
          >
            <Search filters={filters} />
            <div
              className={cx(
                styles['dashboard--row'],
                styles['dashboard--row--align-right']
              )}
            >
              <div className={styles['dashboard--button-wrapper']}>
                <Button
                  text={t('back-to-patients')}
                  size="small"
                  onClick={() => history.push(ROUTES.PATIENTS)}
                  type="secondary"
                />
              </div>
            </div>
          </div>
          {banner && <Banner text={banner?.text} type={banner?.type} />}
          <div className={styles['dashboard--row']}>
            <Table size="small">
              <TableHeader data={HEADERS} />
              {loading && <Skeletons type="table" />}
              {isSuccess && surveys && !loading && (
                <>
                  {surveys.map((survey, index) => {
                    const itemId = ids[index]
                    const item = data?.data?.interviews.find(
                      (i) => i.id === itemId
                    )

                    const actions = []

                    if (canOnlySeePatients() || userHasFullAcess()) {
                      actions.push(ACTIONS.VIEW)
                    }

                    if (userHasFullAcess()) {
                      actions.push(ACTIONS.RESET_SURVEY)
                      actions.push(ACTIONS.EDIT)
                    }

                    return (
                      <TableRow
                        data={survey}
                        key={index}
                        onAction={(action) => {
                          if (action === ACTIONS.VIEW) {
                            setShowDetails(true)
                            onViewMore(item)
                          } else if (action === ACTIONS.RESET_SURVEY) {
                            resetSurvey(ids[index])
                          } else if (action === ACTIONS.EDIT) {
                            setSelectedSurvey({
                              ...item,
                              position: index,
                            })
                          }
                        }}
                        actions={actions}
                      />
                    )
                  })}
                </>
              )}
            </Table>
          </div>
          {isSuccess &&
            !loading &&
            data?.data?.pagination &&
            data?.data?.interviews.length > 0 && (
              <Pagination
                onClick={(page) => setSelectedPage(page)}
                currentPage={data?.data?.pagination?.currentPage}
                pages={data?.data?.pagination?.totalRecords}
              />
            )}
        </>
      )}
      <div ref={formRef}>
        {selectedSurvery && (
          <>
            <Heading text={t('edit-survey')} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Caption text={t('patient-name')} />
                <Typography
                  children={patient}
                  className={cx(
                    styles['dashboard--text'],
                    styles['dashboard--text--large']
                  )}
                />
              </div>
              <div className={styles['dashboard--button-wrapper']}>
                <Button
                  text={t('cancel')}
                  size="small"
                  onClick={() => {
                    setBanner(undefined)
                    setSelectedSurvey(undefined)
                  }}
                  type="secondary"
                />
              </div>
            </div>
            <div className={styles['dashboard--form-wrapper']}>
              {banner?.type !== 'success' && (
                <div>
                  {Object.entries(form)?.map((formElement) => {
                    return (
                      <div
                        key={formElement[0]}
                        className={styles['dashboard--textfield-wrapper']}
                      >
                        {formElement[1].component === 'input-text' && (
                          <TextField {...formElement[1]} onChange={onChange} />
                        )}
                        {formElement[1].component === 'input-date' && (
                          <TextField
                            {...formElement[1]}
                            type="date"
                            onChange={onChange}
                          />
                        )}
                        {formElement[1].component === 'input-checkbox' && (
                          <Dropdown
                            {...formElement[1]}
                            list={formElement[1].options.map(
                              (option) => option.value
                            )}
                            onSelect={(name, value) => {
                              onChange(name, value)
                            }}
                            displayList={formElement[1].options}
                            withBorder
                            multiple
                          />
                        )}
                        {['input-radio', 'input-dropdown'].includes(
                          formElement[1].component
                        ) &&
                          formElement[1].visible && (
                            <Dropdown
                              {...formElement[1]}
                              list={formElement[1].options.map(
                                (option) => option.value
                              )}
                              onSelect={onChange}
                              displayList={formElement[1].options}
                              withBorder
                            />
                          )}
                      </div>
                    )
                  })}
                  <Button
                    text="Submit"
                    onClick={() => setShowConfirmDialog(true)}
                    loading={postInterviewsResult.isLoading}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showDetails && (
        <Modal onClose={() => setShowDetails(false)} closeOnClickOutside>
          <Details
            title={t('more-details-on-surveys')}
            onCancel={() => setShowDetails(false)}
            list={patientDetails}
            large
            asRow
          />
        </Modal>
      )}
      {showConfirmDialog && (
        <Modal onClose={() => setShowConfirmDialog(false)} closeOnClickOutside>
          <ConfirmDialog
            message={t('confirmation-message')}
            onCancel={() => setShowConfirmDialog(false)}
            onConfirm={onSubmit}
            loading={postInterviewsResult.isLoading}
          />
        </Modal>
      )}
    </Layout>
  )
}

export { Surveys }
