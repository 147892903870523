import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Heading, Typography } from '..'

import styles from '../delete/Delete.module.scss'

const ConfirmDialog = ({ onCancel, onConfirm, loading, message }) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles['delete']}>
      <div className={styles['delete__body']}>
        <Heading text="Confirmation" size="small" />
        <br />
        <Typography
          children={message}
          className={styles['delete__body__message']}
        />
      </div>
      <div className={styles['delete__actions']}>
        <div className={styles['delete__actions__item']}>
          <Button
            text={t('cancel')}
            onClick={onCancel}
            square
            disabled={loading}
            loading={false}
            type="secondary"
          />
        </div>
        <div className={styles['delete__actions__item']}>
          <Button
            text={t('confirm')}
            onClick={onConfirm}
            square
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.string,
}

ConfirmDialog.defaultProps = {}

export { ConfirmDialog }
