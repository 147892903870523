import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  useGetSurveysTypesQuery,
  useCreateSurveyMutation,
} from '../../../services/redux/features/survey/facade'
import { useGetLanguagesQuery } from '../../../services/redux/features/language/facade'

import { ROUTES } from '../../../constants'
import { capitalizeText, iSJSON } from '../../../helpers'
import { useModals, FORM_DATA, STATUSES } from './hook'

import {
  BackButton,
  Banner,
  Button,
  Checkbox,
  Dropdown,
  Heading,
  Layout,
  SuperUserCheck,
  TextField,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const Create = () => {
  const history = useHistory()

  const [form, setForm] = useState(FORM_DATA)
  const [banner, setBanner] = useState()

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    let newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${form[name].label} is required` : '',
          error: isRequired,
        },
        required: form[name].required,
      },
    }

    if (name === 'data' && value) {
      try {
        const json = JSON.parse(value)
        if (!iSJSON(json)) {
          newForm.data = {
            ...form['data'],
            helper: {
              text: 'Wrong json format provided',
              error: true,
            },
          }
        }
      } catch (_) {
        newForm.data = {
          ...form['data'],
          helper: {
            text: 'Wrong json format provided',
            error: true,
          },
        }
      }
    }

    setForm(newForm)
  }

  const typesRequest = useGetSurveysTypesQuery()
  const languagesRequest = useGetLanguagesQuery()
  const [createSurvey, result] = useCreateSurveyMutation()
  const { isLoading, error, isSuccess } = result

  const { items } = useModals(typesRequest.data?.data?.surveyTypes || [])
  const { items: languages } = useModals(
    languagesRequest.data?.data?.languages || []
  )

  useEffect(() => {
    if (error) {
      setBanner({
        text: JSON.stringify(error?.data.error),
        type: 'error',
      })
    }

    if (isSuccess) {
      setBanner({
        text: 'The survey has been successfully added',
        type: 'success',
      })
      setForm(FORM_DATA)
    }
  }, [error, isSuccess])

  const onSubmit = async () => {
    setBanner(undefined)
    let canProceed = true
    Object.entries(form)
      .reverse()
      .forEach((entry) => {
        const name = entry[0]
        const value = entry[1].value
        const required = entry[1].required
        if (!value && required) {
          onChange(name, value)
          canProceed = false
        }
      })

    if (canProceed) {
      const body = {
        name: capitalizeText(form['name'].value),
        typeId: Number(form['type'].value),
        data: form['data'].value,
        isDefault: form['isDefault'].value,
        status: form['status'].value,
        languageId: Number(form['language'].value),
      }
      await createSurvey(body)
    }
  }

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <BackButton
          text="See surveys"
          onClick={() => {
            history.push(ROUTES.SURVEYS)
          }}
        />
        <Heading text="Add a survey" noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        {banner?.type !== 'success' && (
          <div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...form.name} onChange={onChange} />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...form.type}
                list={items.map((type) => type.value.toString())}
                onSelect={onChange}
                value={form['type'].value}
                displayList={items}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...form.language}
                list={languages.map((language) => language.value.toString())}
                onSelect={onChange}
                value={form['language'].value}
                displayList={languages}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...form.status}
                list={STATUSES.map((status) => status.value.toString())}
                onSelect={onChange}
                value={form['status'].value}
                displayList={STATUSES}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...form.data} onChange={onChange} isTextArea />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Checkbox
                text="This is the default survey"
                noMargin
                checked={form.isDefault.value}
                onChange={onChange}
                name={form.isDefault.name}
              />
            </div>
            <br />
            <Button text="Save survey" onClick={onSubmit} loading={isLoading} />
          </div>
        )}
      </div>
      <SuperUserCheck />
    </Layout>
  )
}

export { Create }
