const translations = {
  common: {
    'search-term': 'Search term',
    'error-message': 'An error has occurred, please try again',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  patients: {
    'patient-management': 'Patient management',
    'import-list': 'Import List',
    'export-list': 'Export List',
    'new-patient': 'New Patient',
    name: 'Name',
    'phone-number': 'Phone number',
    hospital: 'Hospital',
    'delete-the-patient': 'Delete the patient',
    'more-details-on--the-patient': 'More details on the patient',
    'export-patient-list': 'Export patient list',
    export: 'Export',
    consented: 'Consented',
    'not-consented': 'Not Consented',
    'exporl-all-patients': 'Exporl all patients',
    'filter-data-before-exporting': 'Filter data before exporting',
    'from-date-of-discharge': 'From (Date of discharge)',
    'to-date-of-discharge': 'To (Date of discharge)',
    'deletion-message': 'The patient has been successfully deleted',
    'file-download-message': 'File successfully downloaded',
    'personal-details': 'Personal details',
    email: 'Email address',
    address: 'Address',
    town: 'Town',
    quarter: 'Quarter',
    'nearest-landmark': 'Nearest Landmark',
    'detailed-description': 'Detailed Description',
    'other-number-1': 'Other Number 1',
    'other-number-2': 'Other Number 2',
    'alternative-number-1': 'Alternative Number 1',
    'alternative-number-2': 'Alternative Number 2',
    relationship: 'Relationship',
    'on-behalf-text': "Discuss on the patient's behalf?",
    'other-information': 'Other information',
    'date-of-study': 'Date of Study',
    'date-of-operation': 'Date of Operation',
    'date-of-discharge': 'Date of Discharge',
    'preferred-language': 'Preferred Language',
    'wrong-structure-text': 'The uploaded file has the wrong data structure',
    'error-message': 'An error has occurred, please try again',
    'upload-message': 'Patients records have been successfully uploaded',
    'see-patients': 'See patients',
    'import-patients': 'Import patients',
    'patient-ctrid': "Patient's CTR ID",
    'filling-consent-date': "Date filing form with patient's consent",
    'ressearch-assistant-name':
      'Name of research assistant who completed paper form',
    'participant-name': 'Participant Name (First Name, Family Name)',
    'participant-has-mobile-number': 'Does participant have mobile number',
    'participant-has-alternate-mobile-number':
      'Does participant have alternate mobile number #1',
    'alternative-name':
      'Who does this alternate number #1 belong to (Full name)',
    'alternative-contact-number':
      'Participant alternate mobile phone number #1',
    'alternative-relationship':
      'What is the relationship to owner of alternate mobile phone number #1',
    'alternative-permission':
      'Can we discuss the project with them on your behalf? #1',
    'participant-has-alternate-mobile-number-2':
      'Does participant have alternate mobile number #2',
    'alternative-name-2':
      'Who does this alternate number #2 belong to (Full name)',
    'alternative-contact-number-2':
      'Participant alternate mobile phone number #2',
    'alternative-relationship-2':
      'What is the relationship to owner of alternate mobile phone number #2',
    'alternative-permission-2':
      'Can we discuss the project with them on your behalf? #2',
    'participant-has-other-mobile-numbers':
      'Does participant have other mobile numbers',
    'other-contact-number-1': 'Alternate mobile phone numbers #3 (if any)',
    'other-contact-number-2': 'Alternate mobile phone numbers #4 (if any)',
    'consent-to-mention':
      "If we need to contact you at these alternate numbers, is it ok if the study staff mentions the reason for the call and your involvement in this study? If not, that's ok.",
    'how-to-identify-researcher':
      'How should study staff identify you (specify):',
    'add-a-new-patient': 'Add a new patient',
    'edit-patient-details': "Edit patient's details",
    'add-message': 'The patient has been successfully added',
    'edit-message': 'The patient has been successfully edited',
    'save-patient-details': "Save patient's data",
    'form-has-erros': 'The form has errors. Please fix them and submit again',
    'ctr-error-message': 'The CTR ID should have 6 digits',
  },
  actions: {
    Edit: 'Edit',
    Delete: 'Delete',
    'View More': 'View More',
    'Attach Hospitals': 'Attach Hospitals',
    Surveys: 'Surveys',
    'Reset surveys': 'Reset surveys',
    'Reset survey': 'Reset survey',
  },
  surveys: {
    'extended-details': 'extended details',
    'captured-by': 'Captured by',
    language: 'Language',
    'interview-date': 'Interview Date',
    status: 'Status',
    duration: 'Duration',
    'patient-call-attempts': 'Patient call attempts',
    'message-sent': 'Message sent',
    reponse: 'Response',
    'interview-results': 'Interview results',
    hospital: 'Hospital',
    'patient-surveys': 'Patient surveys',
    'patient-name': "Patient's name",
    'back-to-patients': 'Back to Patients',
    'more-details-on-surveys': 'More details on surveys',
    survey: 'Survey',
    'back-to-surveys': 'Back to Surveys',
    'edit-survey': 'Edit survey',
    cancel: 'Back to surveys',
    'interviews-message': 'Successfully updated survey',
    new: 'New',
    worse: 'Worse',
    'confirmation-message':
      'Please note: This survey will be marked as complete once you save the answers. It will be logged that you editted the survey',
  },
  'surveys-reset-message': 'The patient surveys has been successfully reset.',
}

export default translations
