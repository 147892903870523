export const FORM_DATA = {
    hospital: {
      label: 'Hospital',
      name: 'hospital',
      value: '',
      required: true,
      helper: {
        text: '',
        error: false,
      },
      placeholder: '',
      size: 'large',
    },
  }
  
  export const useHospitals = (data) => {
    const hospitals = data.map((hospital) => {
      return {
        text: hospital.name,
        value: hospital.id,
      }
    })
    return {
      hospitals,
    }
  }
  