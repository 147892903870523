import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const dashboardApi = createApi({
  reducerPath: 'DASHBOARD_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getPatientEnrollment: builder.query({
      query: () => ({
        url: '/dashboard/patientenrollment',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getUserData: builder.query({
      query: () => ({
        url: '/dashboard',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getUserTempData: builder.query({
      query: (lang = 'english') => ({
        url: `/dashboard/temp?language=${lang}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getDashboardUsersTemp: builder.query({
      query: ({ ids, language }) => ({
        url: `/dashboard/users?ids=${ids}&language=${language}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getOutcomeOfCallsTemp: builder.mutation({
      query: ({ languageId, startDate, endDate }) => ({
        url: `/dashboard/outcomeofcalls?languageId=${languageId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getPatientConsent: builder.mutation({
      query: ({ languageId, startDate, endDate }) => ({
        url: `/dashboard/consent?languageId=${languageId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getPatientCallAttemptsConsent: builder.mutation({
      query: ({ languageId, startDate, endDate }) => ({
        url: `/dashboard/callattempts?languageId=${languageId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getPatientIgnoredFieldsConsent: builder.mutation({
      query: ({ languageId, startDate, endDate }) => ({
        url: `/dashboard/ignoredfields?languageId=${languageId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getCompletedSurveys: builder.mutation({
      query: ({ languageId, startDate, endDate }) => ({
        url: `/dashboard/surveycompletion?languageId=${languageId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
    getDashboardReport: builder.mutation({
      query: ({ startDate, endDate, suffix = '' }) => ({
        url: `/dashboard/userstemp2?startDate=${startDate}&endDate=${endDate}${suffix}`,
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
    }),
  }),
})

export const {
  useGetPatientEnrollmentQuery,
  useGetUserDataQuery,
  useGetUserTempDataQuery,
  useGetDashboardUsersTempQuery,
  useGetOutcomeOfCallsTempMutation,
  useGetPatientConsentMutation,
  useGetPatientCallAttemptsConsentMutation,
  useGetPatientIgnoredFieldsConsentMutation,
  useGetCompletedSurveysMutation,
  useGetDashboardReportMutation,
} = dashboardApi
