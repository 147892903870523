export const useRoles = (data) => {
  const roles = data.map((role) => {
    return {
      text: role.name,
      value: role.id,
    }
  })
  return {
    roles,
  }
}
