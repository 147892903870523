import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import EscapeOutside from 'react-escape-outside'
import { useTranslation } from 'react-i18next'

import { ACTIONS } from '../../constants'
import { Typography } from '../typography'

import styles from './Actions.module.scss'

const Actions = ({ actions, show, onClick, onClose }) => {
  const { t } = useTranslation('actions')
  const actionsListProps = cx(styles['actions--list'], {
    [styles['actions--list--show']]: show,
  })

  const actionsListItemProps = (action) =>
    cx(styles['actions--list--item'], {
      [styles['actions--list--item--delete']]: action === ACTIONS.DELETE,
    })

  return (
    <>
      {Boolean(show) && (
        <>
          <div className={styles['actions']}>
            <EscapeOutside
              onEscapeOutside={() => {
                onClose()
              }}
            >
              <div className={actionsListProps}>
                {actions?.map((action, index) => {
                  return (
                    <div
                      key={index}
                      className={actionsListItemProps(action)}
                      onClick={() => {
                        onClick(action)
                        onClose()
                      }}
                    >
                      <Typography
                        className={styles['actions--list--item--text']}
                        children={t(action)}
                      />
                    </div>
                  )
                })}
              </div>
            </EscapeOutside>
          </div>
          <div className={styles['actions__overlay']} />
        </>
      )}
    </>
  )
}

Actions.propTypes = {
  actions: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

Actions.defaultProps = {
  onClick: () => {},
  onClose: () => {},
  show: false,
}

export { Actions }
