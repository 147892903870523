import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import {
  useDeletePatientMutation,
  useGetAndSearchPatientsMutation,
  useResetPatientSurveysMutation,
} from '../../../services/redux/features/patient/facade'
import { useGetHospitalsQuery } from '../../../services/redux/features/hospital/facade'
import { ACTIONS, ROUTES, LS_KEYS } from '../../../constants'
import { getItem } from '../../../services/session'
import { usePatients, EXPORT_FORM_DATA } from './hook'
import { attachRouterParams } from '../../../helpers'
import { useHospitals } from '../helper'
import { userHasFullAcess } from '../../../helpers/auth'

import {
  Button,
  Delete,
  Details,
  Heading,
  Layout,
  Modal,
  Pagination,
  Search,
  Skeletons,
  Table,
  TableHeader,
  TableRow,
  TextField,
  Dropdown,
  Banner,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const fileDownload = require('js-file-download')
const DATE_FORMAT = 'YYYY-MM-DD'
const BASE_URL = process.env.REACT_APP_BASE_URL

const List = () => {
  const { t } = useTranslation('patients')
  const history = useHistory()
  const [form, setForm] = useState(EXPORT_FORM_DATA)
  const [banner, setBanner] = useState()
  const [searchTerm, setSearchTerm] = useState()
  const [selectedPatient, setSelectedPatient] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDetails, setShowDetails] = useState(false)
  const [patientDetails, setPatientDetails] = useState(undefined)
  const [isExporting, setIsExporting] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [showFilterValue, setShouldFilterValue] = useState('No')

  const HEADERS = [
    {
      text: '',
      alignment: 'left',
      size: 'extra-small',
    },

    {
      text: 'CTRID',
      alignment: 'left',
      size: 'small',
    },
    {
      text: t('name'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: t('phone-number'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: t('hospital'),
      alignment: 'left',
      size: 'large',
    },
    {
      text: 'Actions',
      alignment: 'right',
      size: 'small',
    },
  ]

  const YES_AND_NO_LIST = [
    {
      text: t('consented'),
      value: 'Yes',
    },
    {
      text: t('not-consented'),
      value: 'No',
    },
  ]

  const FILTERS_LIST = [
    {
      text: t('exporl-all-patients'),
      value: 'No',
    },
    {
      text: t('filter-data-before-exporting'),
      value: 'Yes',
    },
  ]

  const hospitalsRequest = useGetHospitalsQuery()
  const [getPatients, patientsResult] = useGetAndSearchPatientsMutation(
    selectedPage,
    searchTerm
  )

  const { isSuccess, data, isLoading, isFetching } = patientsResult
  const [deletePatient, result] = useDeletePatientMutation()

  const [resetPatientSurveys, resetPatientSurveysResult] =
    useResetPatientSurveysMutation()

  const loadPatients = () => {
    getPatients({
      page: selectedPage,
      searchTerm,
    })
  }

  useEffect(() => {
    loadPatients()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedPage])

  const { hospitals } = useHospitals(
    hospitalsRequest.data?.data?.hospitals || []
  )
  const { patients, filters } = usePatients(data?.data?.patients || [])
  const ids = data?.data?.patients?.map((patient) => patient.id) || []

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    let newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${form[name].label} is required` : '',
          error: isRequired,
        },
        required: form[name].required,
      },
    }

    setForm(newForm)
  }

  useEffect(() => {
    if (resetPatientSurveysResult.isError) {
      setBanner({
        text: JSON.stringify(resetPatientSurveysResult.error?.data?.error),
        type: 'error',
      })
    }

    if (resetPatientSurveysResult.isSuccess) {
      setBanner({
        text: t('surveys-reset-message'),
        type: 'success',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPatientSurveysResult])

  useEffect(() => {
    if (result.isError) {
      setBanner({
        text: t('error-message'),
        type: 'error',
      })
    }

    if (result.isSuccess) {
      setBanner({
        text: t('deletion-message'),
        type: 'success',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const onStartDeletion = async (id) => {
    const items = await data?.data?.patients
    const item_to_delete = items.find((patient) => patient.id === id)
    setSelectedPatient(item_to_delete)
  }

  const onViewMore = async (id) => {
    const items = await data?.data?.patients
    const item_to_show = items.find((patient) => patient.id === id)
    let details = [
      {
        title: t('personal-details'),
        data: [
          {
            text: item_to_show?.patientCtrId,
            title: 'CTRID',
          },
          {
            text: item_to_show?.patientName,
            title: t('name'),
          },
          {
            text: item_to_show?.patientMobile,
            title: t('phone-number'),
          },
          {
            text: item_to_show.email,
            title: t('email'),
          },
        ],
      },
      {
        title: t('address'),
        data: [
          {
            text: item_to_show?.primaryAddress?.region,
            title: 'Region',
          },
          {
            text: item_to_show?.primaryAddress?.town,
            title: t('town'),
          },
          {
            text: item_to_show?.primaryAddress?.village,
            title: 'Village',
          },
          {
            text: item_to_show?.primaryAddress?.quarter,
            title: t('quarter'),
          },
          {
            text: item_to_show?.primaryAddress?.nearestLandmark,
            title: t('nearest-landmark'),
          },
          {
            text: item_to_show?.primaryAddress?.description,
            title: t('detailed-description'),
          },
        ],
      },
    ]

    const showOtherNumbers = item_to_show.haveOtherNumbers
    const otherNumbers = item_to_show.otherNumbers
    const alternativeNumbers = item_to_show.alternativeNumbers

    if (showOtherNumbers) {
      if (otherNumbers[0]) {
        details.push({
          title: t('other-number-1'),
          data: [
            {
              text: otherNumbers[0].cellphone,
              title: t('phone-number'),
            },
          ],
        })
      }
      if (otherNumbers[1]) {
        details.push({
          title: t('other-number-2'),
          data: [
            {
              text: otherNumbers[0].cellphone,
              title: t('phone-number'),
            },
          ],
        })
      }
    }
    if (alternativeNumbers) {
      if (alternativeNumbers[0] && alternativeNumbers[0].name) {
        details.push({
          title: t('alternative-number-1'),
          data: [
            {
              text: alternativeNumbers[0].relationship,
              title: t('relationship'),
            },
            {
              text: alternativeNumbers[0].name,
              title: t('name'),
            },
            {
              text: alternativeNumbers[0].cellphone,
              title: t('phone-number'),
            },
            {
              text: alternativeNumbers[0].discussOnPatientBehalf ? 'Yes' : 'No',
              title: t('on-behalf-text'),
            },
          ],
        })
      }
      if (alternativeNumbers[1] && alternativeNumbers[1].name) {
        details.push({
          title: t('alternative-number-1'),
          data: [
            {
              text: alternativeNumbers[1].relationship,
              title: t('relationship'),
            },
            {
              text: alternativeNumbers[1].name,
              title: t('name'),
            },
            {
              text: alternativeNumbers[1].cellphone,
              title: t('phone-number'),
            },
            {
              text: alternativeNumbers[1].discussOnPatientBehalf ? 'Yes' : 'No',
              title: t('on-behalf-text'),
            },
          ],
        })
      }
    }

    details.push({
      title: t('other-information'),
      data: [
        {
          title: t('date-of-study'),
          text: item_to_show.dateOfStudy,
        },
        {
          title: t('date-of-operation'),
          text: item_to_show.dateOfOperation,
        },
        {
          title: t('date-of-discharge'),
          text: item_to_show.dateOfDischarge,
        },
        {
          title: t('hospital'),
          text: item_to_show?.hospital?.name,
        },
        {
          title: t('preferred-language'),
          text: item_to_show?.language?.name,
        },
      ],
    })

    setPatientDetails(details)
  }

  const onExport = async () => {
    setBanner(undefined)
    let url = `${BASE_URL}/patients/export`
    if (form['hospital'].value !== undefined) {
      url = `${url}?hospitalId=${Number(form['hospital'].value)}`
    }
    if (form['from'].value) {
      url = `${url}&startDate=${moment(form['from'].value).format(DATE_FORMAT)}`
    }
    if (form['to'].value) {
      url = `${url}&endDate=${moment(form['to'].value).format(DATE_FORMAT)}`
    }
    if (form['consented'].value) {
      url = `${url}&consented=${form['consented'].value}`
    }
    const dateNow = new Date()
    const fileName = `patients_${moment(dateNow).format('YYYY_MM_DD')}.csv`
    setExportLoading(true)
    await axios({
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getItem(LS_KEYS.AUTH_TOKEN),
      },
    })
      .then((response) => {
        fileDownload(response.data, fileName)
        setBanner({
          text: t('file-download-message'),
          type: 'success',
        })
      })
      .catch((err) => {
        setBanner({
          text: t('error-message'),
          type: 'error',
        })
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  const onCancelDeletion = () => {
    setSelectedPatient(undefined)
    setBanner(undefined)
  }

  const onDeletePatient = async () => {
    if (selectedPatient) {
      await deletePatient(selectedPatient?.id)
    }
  }

  const loading = isLoading || isFetching
  const canSubmit =
    showFilterValue === 'Yes'
      ? form['from'].value && form['to'].value && form['hospital'].value
      : true

  return (
    <Layout>
      <Heading text={t('patient-management')} />
      <div
        className={cx(
          styles['dashboard--row'],
          styles['dashboard--row--with-margin']
        )}
      >
        <Search
          filters={filters}
          onSearch={(val) => {
            setSelectedPage(1)
            setSearchTerm(val)
          }}
          disabled={loading || patientsResult.isLoading}
        />
        <div
          className={cx(
            styles['dashboard--row'],
            styles['dashboard--row--align-right']
          )}
        >
          {userHasFullAcess() && (
            <>
              <div className={styles['dashboard--button-wrapper']}>
                <Button
                  text={t('import-list')}
                  size="small"
                  onClick={() => history.push(ROUTES.IMPORT_PATIENTS)}
                  type="secondary"
                />
              </div>
              <div className={styles['dashboard--button-wrapper']}>
                <Button
                  text={t('export-list')}
                  size="small"
                  onClick={() => setIsExporting(true)}
                  type="secondary"
                />
              </div>
            </>
          )}
          <div className={styles['dashboard--button-wrapper']}>
            <Button
              text={t('new-patient')}
              size="small"
              onClick={() => {
                history.push(ROUTES.CREATE_PATIENTS)
              }}
            />
          </div>
        </div>
      </div>
      {banner && <Banner text={banner?.text} type={banner?.type} />}
      <div className={styles['dashboard--row']}>
        <Table>
          <TableHeader data={HEADERS} />
          {loading && <Skeletons type="table" />}
          {isSuccess && patients && !loading && (
            <>
              {patients.map((data, index) => {
                const actions = [ACTIONS.VIEW, ACTIONS.SURVEYS]

                if (userHasFullAcess()) {
                  actions.push(ACTIONS.RESET_SURVEYS)
                  actions.push(ACTIONS.EDIT)
                  actions.push(ACTIONS.DELETE)
                }

                return (
                  <TableRow
                    data={data}
                    key={index}
                    onAction={(action) => {
                      setBanner(undefined)
                      if (action === ACTIONS.DELETE) {
                        onStartDeletion(ids[index])
                      } else if (action === ACTIONS.VIEW) {
                        setShowDetails(true)
                        onViewMore(ids[index])
                      } else if (action === ACTIONS.SURVEYS) {
                        history.push(
                          attachRouterParams(ROUTES.PATIENT_SURVEYS, {
                            id: ids[index],
                          })
                        )
                      } else if (action === ACTIONS.RESET_SURVEYS) {
                        resetPatientSurveys(ids[index])
                      } else {
                        history.push(
                          attachRouterParams(ROUTES.EDIT_PATIENT, {
                            id: ids[index],
                          })
                        )
                      }
                    }}
                    actions={actions}
                  />
                )
              })}
            </>
          )}
        </Table>
      </div>
      <br />
      {isSuccess &&
        !loading &&
        data?.data?.pagination &&
        data?.data?.patients.length > 0 && (
          <Pagination
            onClick={(page) => setSelectedPage(page)}
            currentPage={data?.data?.pagination?.currentPage}
            pages={data?.data?.pagination?.totalRecords}
          />
        )}
      {selectedPatient && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeletePatient}
            title={t('delete-the-patient')}
            onCancel={onCancelDeletion}
            banner={banner}
            loading={result.isLoading}
          />
        </Modal>
      )}
      {showDetails && (
        <Modal onClose={() => setShowDetails(false)} closeOnClickOutside large>
          <Details
            title={t('more-details-on--the-patient')}
            onCancel={() => setShowDetails(false)}
            list={patientDetails}
            large
          />
        </Modal>
      )}
      {isExporting && (
        <Modal
          onClose={() => setIsExporting(false)}
          title={t('export-patient-list')}
          contentWithPadding
        >
          {banner && <Banner text={banner?.text} type={banner?.type} />}
          {banner?.type !== 'success' && (
            <div>
              <div className={styles['dashboard--textfield-wrapper']}>
                <Dropdown
                  list={FILTERS_LIST.map((item) => item.value)}
                  onSelect={(_, val) => {
                    setShouldFilterValue(val)
                  }}
                  value={showFilterValue}
                  displayList={FILTERS_LIST}
                  withBorder
                />
              </div>
              {showFilterValue === 'Yes' && (
                <>
                  <div className={styles['dashboard--textfield-wrapper']}>
                    <Dropdown
                      {...form.hospital}
                      list={hospitals.map((hos) => hos.value)}
                      onSelect={onChange}
                      value={form['hospital'].value}
                      displayList={hospitals}
                      name="hospital"
                      withBorder
                      label={t('hospital')}
                    />
                  </div>
                  <div className={styles['dashboard--textfield-wrapper']}>
                    <TextField
                      {...form.from}
                      onChange={onChange}
                      label={t('from-date-of-discharge')}
                    />
                  </div>
                  <div className={styles['dashboard--textfield-wrapper']}>
                    <TextField
                      {...form.to}
                      onChange={onChange}
                      label={t('to-date-of-discharge')}
                    />
                  </div>
                  <div className={styles['dashboard--textfield-wrapper']}>
                    <Dropdown
                      {...form.consented}
                      list={YES_AND_NO_LIST.map((hos) => hos.value)}
                      onSelect={onChange}
                      value={form['consented'].value}
                      displayList={YES_AND_NO_LIST}
                      name="consented"
                      withBorder
                    />
                  </div>
                </>
              )}
              <br />
              <Button
                text={t('export')}
                onClick={onExport}
                loading={exportLoading}
                disabled={!canSubmit}
              />
            </div>
          )}
        </Modal>
      )}
    </Layout>
  )
}

export { List }
