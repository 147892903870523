import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from '../typography'
import styles from './Checkbox.module.scss'

const Checkbox = ({ text, onChange, noMargin, checked, name }) => {
  const checkboxProps = cx(styles['checkbox'], {
    [styles['checkbox--no-margin']]: noMargin,
  })

  return (
    <div className={checkboxProps} onClick={() => onChange(name, !checked)}>
      <input type="checkbox" checked={checked} readOnly />
      <Typography className={styles['checkbox--text']}>{text}</Typography>
      <div className={styles['checkbox--overlay']} />
    </div>
  )
}

Checkbox.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  noMargin: PropTypes.bool,
  checked: PropTypes.bool,
  name: PropTypes.string,
}

Checkbox.defaultProps = {
  checked: false,
}

export { Checkbox }
