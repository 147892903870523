import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LS_KEYS } from '../../../../constants'
import { getItem } from '../../../session'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const languagesApi = createApi({
  reducerPath: 'LANGUAGES_API',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: () => ({
        url: '/languages',
        method: 'GET',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      providesTags: ['Languages'],
    }),
    createLanguage: builder.mutation({
      query: (body) => ({
        url: '/languages',
        method: 'POST',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Languages'],
    }),
    editLanguage: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/languages/${id}`,
        method: 'PUT',
        body,
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Languages'],
    }),
    deleteLanguage: builder.mutation({
      query: (id) => ({
        url: `/languages/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getItem(LS_KEYS.AUTH_TOKEN),
        },
      }),
      invalidatesTags: ['Languages'],
    }),
  }),
})

export const {
  useGetLanguagesQuery,
  useCreateLanguageMutation,
  useEditLanguageMutation,
  useDeleteLanguageMutation,
} = languagesApi
