import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { decodeToken } from '../../helpers'
import { LS_KEYS, ROUTES } from '../../constants'
import { setItem } from '../../services/session'
import { useLoginMutation } from '../../services/redux/features/user/facade'
import {
  Banner,
  Button,
  Container,
  Panel,
  TextField,
  Typography,
} from '../../components'

import styles from './SignIn.module.scss'

const FORM_DATA = {
  email: {
    name: 'email',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: 'Email address',
  },
  password: {
    name: 'password',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    type: 'password',
    placeholder: 'Password',
  },
}

const SignIn = () => {
  const history = useHistory()
  const [form, setForm] = useState(FORM_DATA)
  const [isUnauthorized, setIsUnauthorized] = useState(false)

  const onChange = (name, value) => {
    const isRequired = !value && form[name].required
    const newForm = {
      ...form,
      [name]: {
        ...form[name],
        value,
        helper: {
          text: isRequired ? `${name} field is required` : '',
          error: isRequired,
        },
      },
    }
    setForm(newForm)
  }

  const [login, result] = useLoginMutation()
  const { isLoading, isError, isSuccess, data } = result

  useEffect(() => {
    if (isSuccess && data) {
      const { user, token } = data.data
      const transformedToken = token.replace('Bearer ', '')
      const decodedTransformedToken = decodeToken(transformedToken)
      const role = decodedTransformedToken?.role
      if (role?.name === 'APP_ONLY') {
        setIsUnauthorized(true)
      } else {
        setItem(LS_KEYS.AUTH_TOKEN, token)
        setItem(LS_KEYS.AUTH_USER, {
          ...user,
          isSuperUser: Number(decodedTransformedToken.superuser) === 1,
        })
        const route = user.changePasswordRequired
          ? ROUTES.CHANGE_PASSWORD
          : ROUTES.HOME
        history.push(route)
      }
    }
  }, [isSuccess, data, history])

  const onSubmit = async () => {
    setIsUnauthorized(false)
    const body = {
      email: form['email'].value.trim(),
      password: form['password'].value.trim(),
    }
    await login(body)
  }

  const canSubmitForm = form['email'].value && form['password'].value

  return (
    <Container>
      <div className={styles['sign-in']}>
        <div
          className={cx(
            styles['sign-in__column'],
            styles['sign-in__column__large']
          )}
        >
          <img
            src="/dsine-logo.png"
            alt="logo"
            className={styles['sign-in--logo']}
          />
        </div>
        <div
          className={cx(
            styles['sign-in__column'],
            styles['sign-in__column__small']
          )}
        >
          <Typography
            children="Log into your account"
            className={styles['sign-in--form-title']}
          />
          {(isError || isUnauthorized) && (
            <Banner
              text="Failed to log you in with provided credentials"
              type="error"
            />
          )}
          <Panel noPadding>
            <div
              className={cx(
                styles['sign-in--textfield-wrapper'],
                styles['sign-in--textfield-wrapper__with-border']
              )}
            >
              <TextField noBorder {...form.email} onChange={onChange} />
            </div>
            <div className={styles['sign-in--textfield-wrapper']}>
              <TextField
                noBorder
                {...form.password}
                onChange={onChange}
                onPressEnter={onSubmit}
              />
            </div>
            <Button
              text="Log in"
              onClick={onSubmit}
              square
              disabled={!canSubmitForm}
              loading={isLoading}
            />
          </Panel>
          {!isLoading && (
            <div
              className={styles['sign-in--link--wrapper']}
              onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}
            >
              <Typography
                children="Forgot password"
                className={styles['sign-in--link']}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export { SignIn }
