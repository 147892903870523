import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Button.module.scss'

const Button = ({ text, type, onClick, square, loading, disabled, size, width, className }) => {
  const buttonProps = cx(
    styles.button,
    {
      [styles[`button--${type}`]]: true,
      [styles[`button--${size}`]]: true,
      [styles['button--square']]: square,
      [styles['button--disabled']]: loading || disabled,
    },
    className
  )

  return (
    <button
      className={buttonProps}
      onClick={() => (loading || disabled ? undefined : onClick())}
      disabled={disabled}
      style={{
        width: width || '100%',
      }}
    >
      {loading ? 'Processing...' : text}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  onClick: PropTypes.func.isRequired,
  square: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  width: PropTypes.number,
  className: PropTypes.string,
}

Button.defaultProps = {
  type: 'primary',
  square: false,
  loading: false,
  disabled: false,
  size: 'large',
}

export { Button }
