export const useModels = (data) => {
  const transformedData = data.map((item) => {
    return [
      {
        text: '',
        alignment: 'left',
        size: 'extra-small',
      },
      {
        text: item.name,
        alignment: 'left',
        size: 'large',
      },
      {
        text: '',
        alignment: 'right',
        size: 'small',
      },
    ]
  })
  return {
    items: transformedData,
  }
}
