export const EDIT_FORM_DATA = {
  code: {
    label: 'Code',
    name: 'code',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  name: {
    label: 'Name',
    name: 'name',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const setHospital = (data) => {
  let hospital = EDIT_FORM_DATA
  hospital['code'] = {
    ...hospital['code'],
    value: data.code,
  }
  hospital['name'] = {
    ...hospital['name'],
    value: data.name,
  }

  return hospital
}
