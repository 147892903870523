import React, { useEffect, useState } from 'react'

import { ACTIONS } from '../../../constants'
import { useModels } from './../hook'
import {
  useGetLanguagesQuery,
  useDeleteLanguageMutation,
} from '../../../services/redux/features/language/facade'

import { Delete, Modal, Table, TableRow, Skeletons } from '../../../components'
import { Section, LanguageForm } from '../sharedComponents'

const Languages = () => {
  const [banner, setBanner] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [editDetails, setEditDetails] = useState()
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false)

  const onHideAddLanguageModal = () => setShowAddLanguageModal(false)

  const [deleteLanguage, result] = useDeleteLanguageMutation()
  const languagesRequest = useGetLanguagesQuery()
  const { items } = useModels(languagesRequest.data?.data?.languages || [])

  useEffect(() => {
    if (result.isError) {
      setBanner({
        text: 'An error has occurred, please try again',
        type: 'error',
      })
    }

    if (result.isSuccess) {
      setBanner({
        text: 'The language has been successfully deleted',
        type: 'success',
      })
    }
  }, [result])

  const onCancelDeletion = () => {
    setSelectedLanguage(undefined)
    setBanner(undefined)
  }

  const onStartDeletion = (item) => {
    setSelectedLanguage(item)
  }

  const onDeleteLanguage = async () => {
    if (selectedLanguage) {
      await deleteLanguage(selectedLanguage?.id)
    }
  }

  return (
    <>
      <Section
        title="Languages"
        buttonText="New Language"
        onButtonClick={() => {
          setEditDetails(undefined)
          setShowAddLanguageModal(true)
        }}
      >
        <Table size="small">
          {languagesRequest.isLoading && <Skeletons type="table" />}
          {languagesRequest.isSuccess && items && !languagesRequest.isLoading && (
            <>
              {items.map((data, index) => (
                <TableRow
                  data={data}
                  key={index}
                  onAction={(action) => {
                    const item = languagesRequest.data?.data.languages[index]
                    if (action === ACTIONS.DELETE) {
                      onStartDeletion(item)
                    } else if (action === ACTIONS.EDIT) {
                      setEditDetails(item)
                      setShowAddLanguageModal(true)
                    }
                  }}
                  actions={[ACTIONS.EDIT, ACTIONS.DELETE]}
                />
              ))}
            </>
          )}
        </Table>
      </Section>
      {showAddLanguageModal && (
        <Modal onClose={onHideAddLanguageModal}>
          <LanguageForm onCancel={onHideAddLanguageModal} {...editDetails} />
        </Modal>
      )}
      {selectedLanguage && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeleteLanguage}
            title="Delete the language"
            onCancel={onCancelDeletion}
            banner={banner}
            loading={result.isLoading}
          />
        </Modal>
      )}
    </>
  )
}

export { Languages }
