import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from '../typography'

import styles from './Banner.module.scss'

const Banner = ({ type, text, noMargin }) => {
  const bannerProps = cx(styles.banner, {
    [styles[`banner--${type}`]]: true,
    [styles['banner--no-margin']]: noMargin,
  })
  return (
    <div className={bannerProps}>
      <Typography children={text} className={styles['banner--typography']} />
    </div>
  )
}

Banner.propTypes = {
  type: PropTypes.oneOf(['success', 'error']),
  text: PropTypes.string,
  noMargin: PropTypes.bool,
}

Banner.defaultProps = {
  text: '',
}

export { Banner }
