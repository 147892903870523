import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Table.module.scss'

const Table = ({ children, size }) => {
  const tableContentProps = cx(styles['table--content'], {
    [styles[`table--content--${size}`]]: true,
  })
  return (
    <div className={styles['table']}>
      <div className={tableContentProps}>{children}</div>
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'large', 'extra-large']),
}

Table.defaultProps = {}

export { Table }
