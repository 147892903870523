import React, { useEffect, useState } from 'react'
import { ACTIONS } from '../../../constants'
import { useModels } from './../hook'
import {
  useGetSurveysTypesQuery,
  useDeleteSurveysTypeMutation,
} from '../../../services/redux/features/survey/facade'

import { Delete, Modal, Table, TableRow, Skeletons } from '../../../components'
import { Section, SurveysTypeForm } from '../sharedComponents'

const SurveysTypes = () => {
  const [banner, setBanner] = useState()
  const [selectedSurveysType, setSelectedSurveysType] = useState()
  const [editDetails, setEditDetails] = useState()
  const [showAddSurveysTypeModal, setShowAddSurveysTypeModal] = useState(false)

  const onHideAddSurveysTypeModal = () => setShowAddSurveysTypeModal(false)

  const [deleteSurveysType, result] = useDeleteSurveysTypeMutation()
  const surveysTypesRequest = useGetSurveysTypesQuery()
  const { items } = useModels(surveysTypesRequest.data?.data?.surveyTypes || [])

  useEffect(() => {
    if (result.isError) {
      setBanner({
        text: 'An error has occurred, please try again',
        type: 'error',
      })
    }

    if (result.isSuccess) {
      setBanner({
        text: 'The surveys type has been successfully deleted',
        type: 'success',
      })
    }
  }, [result])

  const onCancelDeletion = () => {
    setSelectedSurveysType(undefined)
    setBanner(undefined)
  }

  const onStartDeletion = (item) => {
    setSelectedSurveysType(item)
  }

  const onDeleteSurveysType = async () => {
    if (selectedSurveysType) {
      await deleteSurveysType(selectedSurveysType?.id)
    }
  }

  return (
    <>
      <Section
        title="Surveys Types"
        buttonText="New Surveys Type"
        onButtonClick={() => {
          setEditDetails(undefined)
          setShowAddSurveysTypeModal(true)
        }}
        noBorder
      >
        <Table size="small">
          {surveysTypesRequest.isLoading && <Skeletons type="table" />}
          {surveysTypesRequest.isSuccess &&
            items &&
            !surveysTypesRequest.isLoading && (
              <>
                {items.map((data, index) => (
                  <TableRow
                    data={data}
                    key={index}
                    onAction={(action) => {
                      const item =
                        surveysTypesRequest.data?.data.surveyTypes[index]
                      if (action === ACTIONS.DELETE) {
                        onStartDeletion(item)
                      } else if (action === ACTIONS.EDIT) {
                        setEditDetails(item)
                        setShowAddSurveysTypeModal(true)
                      }
                    }}
                    actions={[ACTIONS.EDIT, ACTIONS.DELETE]}
                  />
                ))}
              </>
            )}
        </Table>
      </Section>
      {showAddSurveysTypeModal && (
        <Modal onClose={onHideAddSurveysTypeModal}>
          <SurveysTypeForm
            onCancel={onHideAddSurveysTypeModal}
            {...editDetails}
          />
        </Modal>
      )}
      {selectedSurveysType && (
        <Modal onClose={onCancelDeletion} closeOnClickOutside>
          <Delete
            onDelete={onDeleteSurveysType}
            title="Delete the surveys type"
            onCancel={onCancelDeletion}
            banner={banner}
            loading={result.isLoading}
          />
        </Modal>
      )}
    </>
  )
}

export { SurveysTypes }
