export const EDIT_FORM_DATA = {
  firstname: {
    label: 'First name',
    name: 'firstname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  surname: {
    label: 'Surname',
    name: 'surname',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  email: {
    label: 'Email address',
    name: 'email',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  contactNumber: {
    label: 'Contact number',
    name: 'contactNumber',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
  role: {
    label: 'Role',
    name: 'role',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
  },
}

export const setUser = (data) => {
  let user = EDIT_FORM_DATA
  user['firstname'] = {
    ...user['firstname'],
    value: data.firstname,
  }
  user['surname'] = {
    ...user['surname'],
    value: data.surname,
  }
  user['email'] = {
    ...user['email'],
    value: data.email,
  }
  user['contactNumber'] = {
    ...user['contactNumber'],
    value: data.contactNumber,
  }
  user['role'] = {
    ...user['role'],
    value: data.role.id.toString(),
  }

  return user
}
