import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'

import { ROUTES } from './constants'
import { store } from './services/redux/store'
import i18n from './services/i18n'

import { PrivateRoute, PublicRoute } from './utils'
import {
  Home,
  Patients,
  SignIn,
  CreatePatient,
  ImportPatients,
  Interviewers,
  CreateInterviewers,
  EditInterviewers,
  Hospitals,
  CreateHospital,
  EditHospital,
  EditPatient,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  Settings,
  Surveys,
  EditSurvey,
  CreateSurvey,
  AttachInterviewers,
  PatientSurveys,
  ExportSurvey,
  Reports,
} from './pages'

import './index.scss'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>
          <PublicRoute path={ROUTES.SIGN_IN} exact component={SignIn} />
          <PublicRoute
            path={ROUTES.FORGOT_PASSWORD}
            exact
            component={ForgotPassword}
          />
          <PublicRoute
            path={ROUTES.RESET_PASSWORD}
            exact
            component={ResetPassword}
          />
          <PrivateRoute path={ROUTES.HOME} exact component={Home} />
          <PrivateRoute path={ROUTES.PATIENTS} exact component={Patients} />
          <PrivateRoute
            path={ROUTES.CREATE_PATIENTS}
            exact
            component={CreatePatient}
          />
          <PrivateRoute
            path={ROUTES.IMPORT_PATIENTS}
            exact
            component={ImportPatients}
          />
          <PrivateRoute
            path={ROUTES.INTERVIEWERS}
            exact
            component={Interviewers}
          />
          <PrivateRoute
            path={ROUTES.ATTACH_HOSPITALS}
            exact
            component={AttachInterviewers}
          />
          <PrivateRoute
            path={ROUTES.CREATE_INTERVIEWERS}
            exact
            component={CreateInterviewers}
          />
          <PrivateRoute
            path={ROUTES.EDIT_INTERVIEWERS}
            exact
            component={EditInterviewers}
          />
          <PrivateRoute path={ROUTES.HOSPITALS} exact component={Hospitals} />
          <PrivateRoute
            path={ROUTES.CREATE_HOSPITAL}
            exact
            component={CreateHospital}
          />
          <PrivateRoute
            path={ROUTES.EDIT_HOSPITAL}
            exact
            component={EditHospital}
          />
          <PrivateRoute
            path={ROUTES.EDIT_PATIENT}
            exact
            component={EditPatient}
          />
          <PrivateRoute
            path={ROUTES.CHANGE_PASSWORD}
            exact
            component={ChangePassword}
          />
          <PrivateRoute path={ROUTES.SETTINGS} exact component={Settings} />
          <PrivateRoute path={ROUTES.SURVEYS} exact component={Surveys} />
          <PrivateRoute
            path={ROUTES.EDIT_SURVEYS}
            exact
            component={EditSurvey}
          />
          <PrivateRoute
            path={ROUTES.CREATE_SURVEYS}
            exact
            component={CreateSurvey}
          />
          <PrivateRoute
            path={ROUTES.PATIENT_SURVEYS}
            exact
            component={PatientSurveys}
          />
          <PrivateRoute
            path={ROUTES.EXPORT_SURVEYS}
            exact
            component={ExportSurvey}
          />
          <PrivateRoute path={ROUTES.REPORTS} exact component={Reports} />
        </Switch>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
