export const FILE_FIELDS = [
  'Record ID',
  'Survey Identifier',
  'Survey Timestamp',
  "Patient's CTR ID",
  'Site',
  "Date filing form with patient's consent",
  'Date of discharge',
  'Name of research assistant filling the form',
  '"Participant Name (First Name',
  ' Family Name)"',
  'Region',
  'Town',
  'Village',
  'Quarter',
  'Nearest Landmark',
  'Detailed Description of Address',
  'Does participant have mobile phone number',
  'Participant mobile phone number',
  'Does participant have alternate mobile number #1',
  'Participant alternate mobile phone number #1',
  'Who does this alternate number #1 belong to (Full name)',
  'What is the relationship to owner of alternate mobile phone number #1',
  'Can we discuss the project with them on your behalf? ',
  'Does participant have alternate mobile number #2',
  'Participant alternate mobile phone number #2',
  'Who does this alternate number #2 belong to (Full name)',
  'What is the relationship to owner of alternate mobile phone number #2',
  'Can we discuss the project with them on your behalf? ',
  'Other alternate mobile phone numbers?',
  'Alternate mobile phone numbers #3 (if any)',
  'Alternate mobile phone numbers #4 (if any)',
  'Can we discuss the project with them on your behalf? ',
  '"If we need to contact you at these alternate numbers',
  ' is it ok if the study staff mentions the reason for the call is your involvement in this study? If not',
  ' that\'s ok. "',
  'How should study staff identify you (specify):',
  'Preferred Language of contact:',
  'Research Assistant Initials',
  'Date of entry into redcap',
  'Complete?',
]

export const FORM_DATA = {
  file: {
    label: 'File',
    name: 'file',
    value: '',
    required: true,
    helper: {
      text: '',
      error: false,
    },
    placeholder: '',
    size: 'large',
    type: 'file',
    accept: '.csv',
  },
}
