import { LS_KEYS } from '../../constants'

export const setItem = (key, item) => {
  sessionStorage.setItem(key, JSON.stringify(item))
}

export const getItem = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
}

export const clearSession = () => {
  for (let key of Object.values(LS_KEYS)) {
    sessionStorage.removeItem(key)
  }
}
