import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { FontSize, FontWeight } from '../../constants'

import styles from './Typography.module.scss'

const Typography = ({
  children,
  letterSpacing,
  fontSize,
  fontWeight,
  uppercase,
  className,
  link,
  onLinkClick,
  onClick,
}) => {
  const typographyProps = cx(
    styles.typography,
    {
      [styles[`typography--${fontSize}`]]: true,
      [styles[`typography--${fontWeight}`]]: true,
      [styles['typography--uppercase']]: uppercase,
    },
    className
  )
  return (
    <p
      className={typographyProps}
      style={{ letterSpacing }}
      onClick={() => onClick?.()}
    >
      {children}{' '}
      {Boolean(link) && <span onClick={() => onLinkClick?.()}>{link}</span>}
    </p>
  )
}

Typography.defaultProps = {
  fontSize: FontSize.Medium,
  fontWeight: FontWeight.Regular,
  letterSpacing: 0.3,
}

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  uppercase: PropTypes.bool,
  className: PropTypes.string,
  letterSpacing: PropTypes.number,
  fontSize: PropTypes.oneOf(Object.values(FontSize)),
  fontWeight: PropTypes.oneOf(Object.values(FontWeight)),
  link: PropTypes.string,
  onLinkClick: PropTypes.func,
  onClick: PropTypes.func,
}

export { Typography }
