import { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useGetOutcomeOfCallsTempMutation } from '../../../services/redux/features/dashboard/facade'
import { useList } from './hook'
import { intToString } from '../../../helpers'

import {
  Typography,
  TableRow,
  Skeletons,
  Dropdown,
  TextField,
  Table,
  TableHeader,
} from '../../../components'

import styles from './../Home.module.scss'

const DATE_FORMAT = 'YYYY-MM-DD'

const OutcomeCalls = ({ languages }) => {
  const selectedLanguage = languages?.[0]?.value
  const [language, setLanguage] = useState(selectedLanguage)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [outcomeOfCalls, setOutcomeOfCalls] = useState([])

  const [getOutcomeCalls, result] = useGetOutcomeOfCallsTempMutation()

  const { data, isLoading, isFetching, isSuccess } = result

  useEffect(() => {
    if (languages.length) {
      const today = new Date(Date.now())
      setEndDate(moment(today).format(DATE_FORMAT))

      const oneWeek = today.setDate(today.getDate() - 7)
      setStartDate(moment(new Date(oneWeek)).format(DATE_FORMAT))
    }
  }, [languages])

  const loadData = async () => {
    const languageId =
      (await languages.find(
        (lang) => lang.text.toLowerCase() === language.toLowerCase()
      )?.value) ?? language
    if (languageId && startDate && endDate) {
      getOutcomeCalls({
        languageId,
        startDate,
        endDate,
      })
    }
  }

  useEffect(() => {
    if (startDate || endDate || language) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, language])

  useEffect(() => {
    if (data) {
      const arr = []
      data?.data?.outcomeOfCalls?.forEach((item) => {
        arr.push({
          ...item,
          status: item.status ?? 'Not provided',
        })
      })

      setOutcomeOfCalls(arr)
    }
  }, [data])

  const { list, headers } = useList(outcomeOfCalls || [])

  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-wrapper--row']}>
        <Typography
          children="Outcome of calls"
          className={styles['table-wrapper--title']}
        />
        <div className={styles['dashboard--row']}>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setStartDate(text)}
              placeholder="Start Date"
              type="date"
              required
              value={startDate}
              label="Start date"
            />
          </div>
          <div className={styles['table-wrapper--header--input-wrapper']}>
            <TextField
              onChange={(_, text) => setEndDate(text)}
              placeholder="End Date"
              type="date"
              required
              value={endDate}
              label="End date"
            />
          </div>
          <div className={styles['table-wrapper--header--dropdown-wrapper']}>
            <Dropdown
              list={languages.map((lang) => lang.value)}
              onSelect={(_, text) => {
                setLanguage(text)
              }}
              displayList={languages}
              name="language"
              withBorder
              placeholder="Language"
              value={language}
              label="Language"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      {isSuccess && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Total call attempts"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children={intToString(data?.data?.totalCallAttempts)}
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Table size="small">
              <TableHeader data={headers} />
              {isLoading && <Skeletons type="table" />}
              {isSuccess && list && !isLoading && (
                <>
                  {list.map((item, index) => (
                    <TableRow data={item} key={index} />
                  ))}
                </>
              )}
            </Table>
          </div>
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className={styles['table-wrapper--row']}>
          <div className={styles['table-wrapper--card']}>
            <Typography
              children="Total call attempts"
              className={styles['table-wrapper--card--title']}
              uppercase
            />
            <Typography
              children="0"
              className={styles['table-wrapper--card--text']}
              uppercase
            />
          </div>
          <div className={styles['table-wrapper--table']}>
            <Skeletons type="table" />
            <Skeletons type="table" />
          </div>
        </div>
      )}
    </div>
  )
}

OutcomeCalls.defaultProps = {
  languages: [],
}

OutcomeCalls.propTypes = {
  languages: PropTypes.array,
}

export { OutcomeCalls }
