import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  useGetSurveysTypesQuery,
  useEditSurveyMutation,
  useGetSurveyQuery,
} from '../../../services/redux/features/survey/facade'
import { useGetLanguagesQuery } from '../../../services/redux/features/language/facade'

import { ROUTES } from '../../../constants'
import { capitalizeText, iSJSON } from '../../../helpers'
import { setSurvey } from './helper'
import { useModals, EDIT_FORM_DATA, STATUSES } from './hook'

import {
  BackButton,
  Banner,
  Button,
  Checkbox,
  Dropdown,
  Heading,
  Layout,
  SuperUserCheck,
  TextField,
} from '../../../components'

import styles from '../../home/Home.module.scss'

const Update = ({ id, types }) => {
  const history = useHistory()

  const [editForm, setEditForm] = useState()
  const [banner, setBanner] = useState()

  const onChange = (name, value) => {
    const isRequired = !value && editForm[name].required
    const newForm = {
      ...editForm,
      [name]: {
        ...editForm[name],
        value,
        helper: {
          text: isRequired ? `${editForm[name].label} is required` : '',
          error: isRequired,
        },
      },
    }

    if (name === 'data' && value) {
      try {
        const json = JSON.parse(value)
        if (!iSJSON(json)) {
          newForm.data = {
            ...editForm['data'],
            helper: {
              text: 'Wrong json format provided',
              error: true,
            },
          }
        }
      } catch (_) {
        newForm.data = {
          ...editForm['data'],
          helper: {
            text: 'Wrong json format provided',
            error: true,
          },
        }
      }
    }

    setEditForm(newForm)
  }

  const languagesRequest = useGetLanguagesQuery()

  const surveyRequest = useGetSurveyQuery(id, {
    skip: false,
  })
  const { items: languages } = useModals(
    languagesRequest.data?.data?.languages || []
  )
  const [editSurvey, result] = useEditSurveyMutation()
  const { isLoading, isError, error, isSuccess } = result

  useEffect(() => {
    if (surveyRequest?.data?.data) {
      const { surveys } = surveyRequest.data.data
      const transformedData = {
        ...surveys[0],
        language: surveys[0].languageId,
      }
      const form = setSurvey(transformedData)
      setEditForm(form)
    }
  }, [surveyRequest?.data?.data])

  useEffect(() => {
    if (isError && error) {
      setBanner({
        text: JSON.stringify(error),
        type: 'error',
      })
    }

    if (isSuccess) {
      setBanner({
        text: 'The survey has been successfully edited',
        type: 'success',
      })
      setEditForm(EDIT_FORM_DATA)
    }
  }, [error, isSuccess, isError])

  const onSubmit = async () => {
    setBanner(undefined)
    let canProceed = true
    Object.entries(editForm)
      .reverse()
      .forEach((entry) => {
        const name = entry[0]
        const value = entry[1].value
        const required = entry[1].required
        if (!value && required) {
          onChange(name, value)
          canProceed = false
        }
      })

    if (canProceed) {
      const body = {
        name: capitalizeText(editForm['name'].value),
        typeId: Number(editForm['type'].value),
        data: editForm['data'].value,
        isDefault: editForm['isDefault'].value,
        status: editForm['status'].value,
        languageId: Number(editForm['language'].value),
        id,
      }
      await editSurvey(body)
    }
  }

  return (
    <Layout>
      <div className={styles['dashboard--row']}>
        <BackButton
          text="See surveys"
          onClick={() => {
            setEditForm(undefined)
            history.push(ROUTES.SURVEYS)
          }}
        />
        <Heading text="Edit the survey" noMargin />
      </div>
      <div className={styles['dashboard--form-wrapper']}>
        {banner && <Banner text={banner?.text} type={banner?.type} />}
        {banner?.type !== 'success' && editForm && (
          <div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...editForm.name} onChange={onChange} />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...editForm.type}
                list={types.map((type) => type.value.toString())}
                onSelect={onChange}
                value={editForm['type'].value}
                displayList={types}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...editForm.language}
                list={languages.map((language) => language.value.toString())}
                onSelect={onChange}
                value={editForm['language'].value}
                displayList={languages}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Dropdown
                {...editForm.status}
                list={STATUSES.map((status) => status.value.toString())}
                onSelect={onChange}
                value={editForm['status'].value}
                displayList={STATUSES}
              />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <TextField {...editForm.data} onChange={onChange} isTextArea />
            </div>
            <div className={styles['dashboard--textfield-wrapper']}>
              <Checkbox
                text="This is the default survey"
                noMargin
                checked={editForm.isDefault.value}
                onChange={onChange}
                name={editForm.isDefault.name}
              />
            </div>
            <br />
            <br />
            <Button
              text="Update survey"
              onClick={onSubmit}
              loading={isLoading}
            />
          </div>
        )}
      </div>
      <SuperUserCheck />
    </Layout>
  )
}

const Edit = () => {
  const params = useParams()
  const typesRequest = useGetSurveysTypesQuery()
  const { items } = useModals(typesRequest.data?.data?.surveyTypes || [])

  return <>{params?.id && <Update id={params.id} types={items} />}</>
}

Update.propTypes = {
  id: PropTypes.string.isRequired,
  types: PropTypes.array,
}

export { Edit }
