import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from '../../typography'

import styles from './TableHeader.module.scss'
import tableStyles from '../Table.module.scss'

const TableHeaderItem = ({ text, alignment, size }) => {
  const tableHeaderItemProps = cx(tableStyles['table--row--item'], {
    [tableStyles[`table--row--item--${alignment}`]]: true,
    [tableStyles[`table--row--item--${size}`]]: true,
  })
  return (
    <div className={tableHeaderItemProps}>
      <Typography
        className={cx(
          tableStyles['table--row--item--text'],
          tableStyles['table--row--item--text--bold']
        )}
        children={text}
      />
    </div>
  )
}

const TableHeader = ({ data }) => {
  return (
    <div className={styles['table-header']}>
      {data.map((item, index) => {
        const { alignment, text, size } = item
        return (
          <TableHeaderItem
            key={index}
            text={text}
            alignment={alignment}
            size={size}
          />
        )
      })}
    </div>
  )
}

TableHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      alignment: PropTypes.oneOf(['left', 'center', 'right']),
      size: PropTypes.oneOf([
        'extra-small',
        'small',
        'medium',
        'large',
        'extra-large',
      ]),
    })
  ),
}

TableHeaderItem.propTypes = {
  text: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'large',
    'extra-large',
  ]),
}

TableHeader.defaultProps = {}

export { TableHeader }
