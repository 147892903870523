import React from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import { RectShape } from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'

const TableRow = () => (
  <ReactPlaceholder
    showLoadingAnimation
    ready={false}
    customPlaceholder={
      <RectShape
        color="#efeeee"
        style={{
          width: '100%',
          height: 42,
          marginBottom: 4,
          borderRadius: 6,
        }}
      />
    }
    children={<></>}
  />
)

const Skeletons = ({ type }) => {
  if (type === 'table') {
    return (
      <>
        <TableRow />
        <TableRow />
        <TableRow />
      </>
    )
  }

  return <></>
}

Skeletons.propTypes = {
  typ: PropTypes.oneOf(['table']),
}

Skeletons.defaultProps = {}

export { Skeletons }
