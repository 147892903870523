import { EDIT_FORM_DATA } from './hook'

export const setSurvey = (data) => {
  let form = EDIT_FORM_DATA
  form['name'] = {
    ...form['name'],
    value: data.name,
  }
  form['status'] = {
    ...form['status'],
    value: data.status,
  }
  form['language'] = {
    ...form['language'],
    value: data.language.toString(),
  }
  form['type'] = {
    ...form['type'],
    value: data.typeId.toString(),
  }
  form['data'] = {
    ...form['data'],
    value: JSON.stringify(data.data),
  }
  form['isDefault'] = {
    ...form['isDefault'],
    value: data.isDefault.toString() === '1' ? true : false,
  }

  return form
}
